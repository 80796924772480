import { Component} from '@angular/core';
import { UsersService, AuthenticationService, SubscriptionsService, ToastService, LoaderService } from '@app/_services';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { EmailsService } from '@app/_services/emails.service';
import { YardiService } from '@app/_services/yardi.service';
import { AutomationSettingsService } from '@app/_services/automationSettings.service';
var self;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less']
})
export class AdminComponent {

  loading: Boolean = false;
  userData: any;
  registeredUserData: any;
  accounts: Array<any>;
  selectedAccount: String;
  createAccountStep: any;
  newAccountInfo: any = {};
  selectedPlan: any;
  plans: Array<any>;
  userBeingCreated: any;
  createUserBusy: Boolean = false;
  createUserError: any;
  modalOptions: NgbModalOptions;
  meteredSubs: Array<String> = [
    "price_1I6p5BLNrMLFf61WGr7Dh8Mp"
  ];
  saving: boolean = false;
  hasYardiIntegration: boolean = false;
  automationSettingsCreated: any = {};
  automationCreated: any = {};
  dropdownSettings: any;
  storedSources: any = [];
  sources: any;
  yardiAgents: any;

  constructor(
    private userService: UsersService,
    private authService: AuthenticationService,
    private subscriptionService: SubscriptionsService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private modalService: NgbModal,
    private router: Router,
    private emailsService: EmailsService,
    private yardiService: YardiService,
    private automationSettingsService: AutomationSettingsService
  ) {
      this.registeredUserData = this.authService.currentUserValue;
      this.loadData();
      self = this;
      this.modalOptions = { windowClass : 'custom-modal-styles-new'};
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 10,
        allowSearchFilter: true
      };
  }

  loadData() {
    // Get user settings
    this.loading = true;
    this.loaderService.triggerLoader();
    this.userData = this.authService.currentUserValue;

    // Check if admin, if so fetch all accounts.
    if (this.userData.user.claims && this.userData.user.claims.indexOf('admin') > -1) {
      this.authService.getAllAccounts().subscribe((data) => {
        var filteredArray = data.result.filter(function(v) {
          return v.propertyName && v.propertyName.length > 0;
        })
        this.accounts = filteredArray.sort(function(a, b) {
          return (a.propertyName > b.propertyName) ? 1 : -1;
        });
        this.loading = false;
        this.loaderService.stopLoader();
      }, (e) => {
        console.error('Error getting account list as admin! ', e);
        this.loading = false;
        this.loaderService.stopLoader();
      });
    }
  }

  openCreateAccountModal(content) {
    this.createAccountStep = 1;
    this.newAccountInfo = {};
    this.modalService.open(content).result.then((result) => {
      if (result === 'Success') {
        self.toastService.show('New user created. Don\'t forget to set template defaults!', { classname: 'bg-success text-light', delay: 5000 });
        this.resetUserCreateVars();
      }
    }, () => {
      this.resetUserCreateVars();
    });
  }

  resetUserCreateVars() {
    this.createAccountStep = 0;
    this.newAccountInfo = {};
    this.createUserBusy = false;
    this.plans = [];
    this.userBeingCreated = null;
    this.createUserError = null;
  }

  createAccountNextStep(modal) {
    this.createUserError = null;
    if (this.createAccountStep === 1) {

      // Do we have minimum required fields.
      if (
        !this.newAccountInfo.propertyName ||
        !this.newAccountInfo.legalEntityName ||
        !this.newAccountInfo.emailAddress ||
        !this.newAccountInfo.propertyAddress ||
        !this.newAccountInfo.unitCount ||
        !this.newAccountInfo.timezone
      ) {
        this.createUserError = 'Please populate all needed fields';
      
      // If Yardi integration checked, do we have required fields.
      } else {
        if (this.hasYardiIntegration && (
          !this.newAccountInfo.yardiPropertyId ||
          !this.newAccountInfo.yardiServer ||
          !this.newAccountInfo.yardiDatabase ||
          !this.newAccountInfo.yardiUsername ||
          !this.newAccountInfo.yardiWSDLUrl ||
          !this.newAccountInfo.yardiPassword
        )) {
          this.createUserError = 'Please populate all needed Yardi Integration fields';
        } else {
          this.createUserBusy = true;
    
          // Create User
          this.userService.createNewAccount(this.newAccountInfo).subscribe((returnData) => {
            this.plans = returnData.result.plans.filter(function(v) {
              return v.active;
            });
            this.userBeingCreated = returnData.result.user;
            this.automationSettingsCreated = returnData.result.automationSettings;
            this.automationCreated = returnData.result.automations;

            // Check if we added Yardi integration to account creation.
            if ((this.automationCreated && this.automationCreated._id) && (this.automationSettingsCreated && this.automationSettingsCreated._id)) {

              // Load sources and agent name to choose from.
              this.yardiService.getYardiSourcesAdmin(this.userBeingCreated).subscribe((data) => {
                this.sources = data.result.GetYardiAgentsSourcesResults_LoginResult.Properties.Property.PropertyRequiredFields.Sources.SourceName;
                this.yardiAgents = data.result.GetYardiAgentsSourcesResults_LoginResult.Properties.Property.PropertyRequiredFields.Agents.AgentName;

                // Transition to in-between step.
                this.createAccountStep = this.createAccountStep + 0.5;
                this.createUserBusy = false;
              }, (err) => {
                console.error('There was an error getting yardi sources, please set on automation screen! ', err);
                this.createUserBusy = false;
                this.createAccountStep = this.createAccountStep + 0.5;
                this.toastService.show('There was an error getting yardi settings, please set after account creation.', { classname: 'bg-danger text-light', delay: 5000 });
              });
            } else {

              // Continue to next step 2 (skip yardi specific setup).
              this.createAccountStep++;
              this.createUserBusy = false;
            }
          }, (err) => {
            console.error('There was an error creating the user: ', err);
            this.createUserBusy = false;
            this.createUserError = 'Error creating user';
          });
        }
      }
    }
    if (this.createAccountStep === 1.5) {
      this.createUserBusy = true;
      this.userService.updateUser(this.userBeingCreated._id, {
        yardiAgentName: this.userBeingCreated.yardiAgentName
      }, true).subscribe(() => {
        this.automationSettingsService.updateSources(this.userBeingCreated._id, this.storedSources).subscribe((data) => {
          this.createUserBusy = false;
          this.createAccountStep = this.createAccountStep + 0.5;
        }, (err) => {
          console.error('There was an error updating user yardi sources: ', err);
          this.createUserBusy = false;
          this.toastService.show('There was an error updating yardi TCPA sources, please set after account creation.', { classname: 'bg-danger text-light', delay: 5000 });
          this.createAccountStep = this.createAccountStep + 0.5;
        });
      }, (e) => {
        console.error('There was an error updating user yardi agent name settings: ', e);
        this.createUserBusy = false;
        this.toastService.show('There was an error updating yardi settings, please set after account creation.', { classname: 'bg-danger text-light', delay: 5000 });
        this.createAccountStep = this.createAccountStep + 0.5;
      });
    }
    if (this.createAccountStep === 2) {
      this.createUserBusy = true;
      var subObj:any = {
        userId: this.userBeingCreated._id,
        planId: this.selectedPlan.id,
        stripeCustomerId: this.userBeingCreated.stripeCustomerId
      };
      if (this.meteredSubs.indexOf(this.selectedPlan.id) > -1) {
        subObj.quantity = this.userBeingCreated.units;
      }
      this.subscriptionService.subscribe(subObj).subscribe(() => {
        this.createAccountStep++;
        this.createUserBusy = false;
        modal.close('Success')
      }, (err) => {
        console.error('There was an error creating users subscription: ', err);
        this.createUserBusy = false;
        this.createUserError = 'Error creating subscription';
      });
    }
  }

  mimic(account) {
    this.authService.mimick(account._id).subscribe((data) => {
      localStorage.setItem('mimic', data.result.user.propertyName);
      localStorage.setItem('currentUser', JSON.stringify(data.result));
      this.authService.currentUserSubject.next(data.result);
      this.router.navigate(['/dashboard']);
    }, (e) => {
      console.error('Error retriever user data to mimic! ', e);
    })
  }

  updateNotificationEmails() {
    this.saving = true;
    this.userService.updateUser(this.userData.user._id, {
      notifications: this.userData.user.notifications
    }, true).subscribe(() => {
      this.saving = false;
      this.toastService.show('Settings updated!', { classname: 'bg-success text-light', delay: 5000 });
    }, (e) => {
      console.error('There was an error updating user settings: ', e);
      this.saving = false;
      this.toastService.show('There was an error updating settings', { classname: 'bg-danger text-light', delay: 5000 });
    });
  }

  updateUserEmail() {
    this.saving = true;
      this.userService.updateUser(this.userData.user._id, {
        email: this.userData.user.email
      }, true).subscribe(() => {
        this.saving = false;
        this.toastService.show('Settings updated!', { classname: 'bg-success text-light', delay: 5000 });
      }, (e) => {
        console.error('There was an error updating user settings: ', e);
        this.saving = false;
        this.toastService.show('There was an error updating settings', { classname: 'bg-danger text-light', delay: 5000 });
      });
  }

  openModal(content, onboarding) {
    if (onboarding) {
      this.loaderService.triggerLoader();

      // Fetch customer invoices to ensure we have invoice to send.
      this.subscriptionService.getInvoices().subscribe((data) => {
        var invoices = data.result.data.sort(function(a, b) {
          return +new Date(a.created) - +new Date(b.created);
        });
        if (invoices.length === 0) {
          this.toastService.show('No invoices found', { classname: 'bg-danger text-light', delay: 5000 });
          this.loaderService.stopLoader();
          return;
        } else if (invoices[0].status === 'draft') {
          this.toastService.show('Invoice still generating, please try later.', { classname: 'bg-danger text-light', delay: 5000 });
          this.loaderService.stopLoader();
        } else {
          this.modalService.open(content).result.then(() => {
            //no-op
          }, () => {
            //no-op
          });
          this.loaderService.stopLoader();
        }
      }, (err) => {
        this.toastService.show('There was an error retrieving invoices', { classname: 'bg-danger text-light', delay: 5000 });
        console.error('There was an error loading invoices: ', err);
        this.loaderService.stopLoader();
      });
    } else {
      this.modalService.open(content).result.then(() => {
        //no-op
      }, () => {
        //no-op
      });
      this.loaderService.stopLoader();
    }
  }

  sendOnboardingEmail(modal) {
   this.saving = true;
   var templateData = {
     fromEmail: 'katie@nurtureboss.io',
     fromName: 'Katie Snead',
     subject: 'Welcome To Nurture Boss: Next Steps',
     sendTo: this.userData.user.email,
     templateName: 'initialOnboardingTemplate',
     propertyName: this.userData.user.propertyName,
     userEmail: this.userData.user.email
   };
   this.emailsService.sendEmailTemplate(templateData).subscribe(() => {
    modal.close();
    this.saving = false;
    this.toastService.show('Email sent!', { classname: 'bg-success text-light', delay: 5000 });
   }, (e) => {
     modal.close();
    console.error('There was an error sending onboarding email: ', e);
    this.saving = false;
    this.toastService.show('There was an error sending email', { classname: 'bg-danger text-light', delay: 5000 });
   });
  }

  sendOnboardingFollowUpEmail(modal) {
    this.saving = true;
    var templateData = {
      fromEmail: 'katie@nurtureboss.io',
      fromName: 'Katie Snead',
      subject: 'Welcome To Nurture Boss: Final Tips',
      sendTo: this.userData.user.email,
      templateName: 'followUpOnboardingTemplate'
    };
    this.emailsService.sendEmailTemplate(templateData).subscribe(() => {
     modal.close();
     this.saving = false;
     this.toastService.show('Email sent!', { classname: 'bg-success text-light', delay: 5000 });
    }, (e) => {
      modal.close();
     console.error('There was an error sending onboarding email: ', e);
     this.saving = false;
     this.toastService.show('There was an error sending email', { classname: 'bg-danger text-light', delay: 5000 });
    });
  }
}
