import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class AutomationSettingsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  getAutomationSettings(userId) {
    return this.http.get<any>(this.apiHelper.fillUrl('automationSettings', {userId: userId}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getSingleAutomation(type) {
    return this.http.get<any>(this.apiHelper.fillUrl('singleAutomation', {type: type}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getAllAutomations() {
    return this.http.get<any>(this.apiHelper.fillUrl('automations', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  updateSources(userId, sources) {
    return this.http.put<any>(this.apiHelper.fillUrl('automationSettingSources', {userId: userId}, {}), {sources: sources})
      .pipe(map(data => {
        return data;
      }));
  }

  updateSingleAutomation(payload) {
    return this.http.put<any>(this.apiHelper.fillUrl('singleAutomation', {type: payload.type}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }
}
