import { Injectable } from "@angular/core";
import { webSocket } from 'rxjs/webSocket';
import { BehaviorSubject } from 'rxjs';
import { APIHelperService } from './apiHelper.service';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class WebsocketsService {

  private _incomingMessage = new BehaviorSubject<any>({});
  private _incomingMessage2 = new BehaviorSubject<any>({});
  message$ = this._incomingMessage.asObservable();
  message2$ = this._incomingMessage2.asObservable();
  webSocketConnection;
  webSocketConnection2;
  registeredUserData;

  constructor(
    private apiHelper: APIHelperService,
    private authService: AuthenticationService
  ) {

    // Capture logged in user.
    this.registeredUserData = this.authService.currentUserValue;
  }

  webSocketConnect() {

    // Get current user data
    var token = JSON.parse(localStorage.getItem('currentUser')).token;

    // Connect to websocket
    this.webSocketConnection = webSocket(this.apiHelper.getWebSocketEndpoint().webApp + '?token=' + token);
    this.webSocketConnection2 = webSocket(this.apiHelper.getWebSocketEndpoint().texting + '?token=' + token);

    // Create listener on websocket for new messages.
    this.webSocketConnection.asObservable().subscribe((dataFromServer) => {
      if (!dataFromServer.ping) {

        // Publish to message for anyone listening.
        this._incomingMessage.next(dataFromServer);
      }
    });
    this.webSocketConnection2.asObservable().subscribe((dataFromServer2) => {
      if (!dataFromServer2.ping) {

        // Publish to message for anyone listening.
        this._incomingMessage2.next(dataFromServer2);
      }
    });
  }
}