import { Component} from '@angular/core';
import { AuthenticationService, ToastService, LoaderService, UsersService } from '@app/_services';
import { YardiService } from '@app/_services/yardi.service';
import { AutomationSettingsService } from '@app/_services/automationSettings.service';

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.less']
})
export class ComplianceComponent {

  loadingUserSettings: boolean = false;
  userData: any = {
    claims: []
  };
  localUserData: any;
  dropdownSettings: any;
  hasIntegration: boolean = false;
  sources: any;
  storedSources: any;
  savingSettings: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private yardiService: YardiService,
    private automationSettingsService: AutomationSettingsService,
    private usersService: UsersService
  ) {
      this.localUserData = this.authService.currentUserValue;
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 10,
        allowSearchFilter: true
      };
      this.loadData();
  }

  loadData() {
    this.loaderService.triggerLoader();
    this.usersService.getUserData(this.localUserData.user._id).subscribe((data) => {
      this.userData = data.result;
      this.loadingUserSettings = false;

      if (this.localUserData.user.claims.indexOf('yardi') > -1) {
        this.hasIntegration = true;
        
        // Has integration, fetch source options from Yardi.
        // TODO: Make dynamic and support RealPage.
        this.yardiService.getYardiSources().subscribe((data) => {
          try {
            this.sources = data.result.GetYardiAgentsSourcesResults_LoginResult.Properties.Property.PropertyRequiredFields.Sources.SourceName.filter(function(a) {
              return typeof a === 'string';
            });
            this.automationSettingsService.getAutomationSettings(this.userData._id).subscribe((autoSettings) => {
              this.storedSources = autoSettings.result.sources;
              this.loaderService.stopLoader();
            }, (e) => {
              console.error('Could not get stored sources: ', e);
              this.toastService.show('There was an error loading your Yardi sources', { classname: 'bg-danger text-light', delay: 5000 });
              this.loaderService.stopLoader();
            })
          } catch (e) {
            this.loaderService.stopLoader();
            this.toastService.show('There was an error loading your Yardi sources', { classname: 'bg-danger text-light', delay: 5000 });
            console.error('Error getting Yardi sources: ', e);
          }
        }, (e) => {
          this.loaderService.stopLoader();
          this.toastService.show('There was an error loading your Yardi sources', { classname: 'bg-danger text-light', delay: 5000 });
          console.error('Error getting Yardi sources: ', e);
        });
      } else {
        this.loaderService.stopLoader();
      }
    }, (err) => {
      this.loaderService.stopLoader();
      this.toastService.show('There was an error retrieving your user settings', { classname: 'bg-danger text-light', delay: 5000 });
      console.error('There was an error retrieving your user settings: ', err);
      this.loadingUserSettings = false;
    });
  }

  updateUserSettings() {
    this.loaderService.triggerLoader();
    this.usersService.updateUser(this.userData._id, {
      optIn: this.userData.optIn,
      doubleOptIn: this.userData.doubleOptIn,
      keyword: this.userData.keyword
    }, true).subscribe(() => {
      this.loaderService.stopLoader();
      this.toastService.show('Settings updated!', { classname: 'bg-success text-light', delay: 5000 });
    }, (e) => {
      this.loaderService.stopLoader();
      console.error('There was an error updating user settings: ', e);
      this.toastService.show('There was an error updating settings', { classname: 'bg-danger text-light', delay: 5000 });
    });
  }

  updateSourcesAddAll(arg) {
    this.storedSources = this.sources;
    this.sendSourcesUpdateCall(this.storedSources);
  }

  sendSourcesUpdateCall(sources) {
    this.automationSettingsService.updateSources(this.userData._id, sources).subscribe((data) => {
      this.storedSources = data.result.sources;
      this.toastService.show('Approved sources updated!', { classname: 'bg-success text-light', delay: 5000 });
    }, (err) => {
      console.error('There was an error updating approved sources: ', err);
      this.toastService.show('There was an error updating your approved sources', { classname: 'bg-danger text-light', delay: 5000 });
    });
  }

  updateSources(arg) {
    if (arg.length === 0) {
      this.sendSourcesUpdateCall([]);
    } else {
      this.sendSourcesUpdateCall(this.storedSources);
    }
  }
}
