import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    public loaderActiveSubject: BehaviorSubject<Boolean>;
    public longLoaderActiveSubject: BehaviorSubject<Boolean>;
    public loaderActive: Observable<Boolean>;
    public longLoaderActive: Observable<Boolean>;

    constructor(
      // none
    ) {
        this.loaderActiveSubject = new BehaviorSubject<Boolean>(false);
        this.longLoaderActiveSubject = new BehaviorSubject<Boolean>(false);
        this.loaderActive = this.loaderActiveSubject.asObservable();
        this.longLoaderActive = this.longLoaderActiveSubject.asObservable();
    }

    public get loaderActiveValue(): Boolean {
        return this.loaderActiveSubject.value;
    }

    public get longLoaderActiveValue(): Boolean {
      return this.longLoaderActiveSubject.value;
  }

    triggerLoader(longLoad?) {
      this.loaderActiveSubject.next(true);
      if (longLoad) {
        this.longLoaderActiveSubject.next(true);
      }
    }

    stopLoader() {
      this.loaderActiveSubject.next(false);
      this.longLoaderActiveSubject.next(false);
    }
}
