import { Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { AuthenticationService, ToastService, LoaderService, TextMessagesService, WebsocketsService, ContactsService } from '@app/_services';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

var self;

@Component({
  selector: 'app-two-way',
  templateUrl: './two-way.component.html',
  styleUrls: ['./two-way.component.less']
})
export class TwoWayComponent implements OnDestroy, OnInit {

  @ViewChild('optOutModal') private optOutModal: TemplateRef<any>;

  registeredUserData: any;
  conversations: any;
  activeConversation: any;
  selectedThread: any;
  replyMessage: String;
  sendingText:Boolean = false;
  subscription;
  subscription2;
  optOutMessage: any = {};
  optOutInProgress: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private textMessagesService: TextMessagesService,
    private webSocketsService: WebsocketsService,
    private router: Router,
    private contactService: ContactsService,
    private modalService: NgbModal
  ) {
      this.registeredUserData = this.authService.currentUserValue;
      self = this;
  }

  ngOnInit() {
    this.loadData().
        then(function() {
          self.subscription = self.webSocketsService.message$.subscribe((newMessage) => {
            self.handleIncomingWebhook(newMessage);
          });
          self.subscription2 = self.webSocketsService.message2$.subscribe((newMessage) => {
            self.handleIncomingWebhook(newMessage);
          });
        });
  }

  handleIncomingWebhook(newMessage) {
    if (newMessage && newMessage._id) {

      // Check which conversation message belongs to.
      for (var i = 0; i < self.conversations.length; i++) {
        if (self.conversations[i]._id === newMessage.conversationId) {
          self.conversations[i].read = false;
          self.conversations[i].body = newMessage.body;
          self.conversations[i].mostRecentActivity = newMessage.created;
        }
        self.textMessagesService.updateUnreadCountInternally(newMessage);
      }

      // If this conversation is active then update the UI.
      if (self.selectedThread && self.selectedThread._id === newMessage.conversationId) {
        self.activeConversation.push(newMessage);
        setTimeout(function() {
          var elem = document.getElementById('chat-window');
          elem.scrollTop = elem.scrollHeight;
        });
      }
    }
  }

  ngOnDestroy() {

    // prevent memory leak when component is destroyed
    this.subscription.unsubscribe();
  }

  loadData() {
    return new Promise(function(resolve, reject) {
      self.loaderService.triggerLoader();
      self.textMessagesService.getConversations().subscribe((data) => {
        self.conversations = data.result.sort((a, b) => {
          return +new Date(b.mostRecentActivity) - +new Date(a.mostRecentActivity)
        });
        self.loaderService.stopLoader();
        resolve('');
      }, (e) => {
        console.error('There was an error getting convos ', e);
        self.loaderService.stopLoader();
        self.toastService.show('There was an error retrieving your conversations', { classname: 'bg-danger text-light', delay: 5000 });
        reject();
      });
    })
  }

  getConversation(thread) {
    thread.read = true;
    this.loaderService.triggerLoader();
    this.selectedThread = thread;
    this.textMessagesService.getConversation(thread.contactId).subscribe((data) => {
      this.activeConversation = data.result;
      setTimeout(function() {
        var elem = document.getElementById('chat-window');
        elem.scrollTop = elem.scrollHeight;
      });
      this.loaderService.stopLoader();

      // Mark conversation as read.
      this.textMessagesService.updateConversation(this.selectedThread._id, {read: true}).subscribe();
      this.textMessagesService.updateUnreadCountInternally({conversationId: this.selectedThread._id, read: true});
    }, (e) => {
      console.error('There was an error getting convos ', e);
      this.loaderService.stopLoader();
      this.toastService.show('There was an error retrieving your conversations', { classname: 'bg-danger text-light', delay: 5000 });
    });
  }

  sendText(e) {
    this.sendingText = true;
    e.preventDefault();
    this.textMessagesService.replyToText({
      to: this.selectedThread.contact.phoneNumber,
      message: this.replyMessage,
      contactId: this.selectedThread.contact._id,
      conversationId: this.selectedThread._id
    }).subscribe((data) => {
      this.activeConversation.push(data.result);
      this.sendingText = false;
      setTimeout(function() {
        var elem = document.getElementById('chat-window');
        elem.scrollTop = elem.scrollHeight;
      });
    }, (e) => {
      this.sendingText = false;
      console.error('There was an error sending text! ', e);
      this.toastService.show(e, { classname: 'bg-danger text-light', delay: 5000 });
    });
    this.replyMessage = '';
  }

  goToContact(id) {
    this.router.navigate(['/contact'], { queryParams: { id: id } });
  }

  checkOptInStatus(contactId) {
    this.contactService.checkOptInStatus(contactId).subscribe((data) => {
      if (data.result.optout) {
        this.optOutMessage = 'Opt In';
      } else {
        this.optOutMessage = 'Opt Out';
      }
      this.modalService.open(this.optOutModal).result.then(() => {
        //no-op
      }, () => {
        //no-op
      });
    });
  }

  changeOptInStatus(modal, contactId) {
    this.optOutInProgress = true;
    var optOut = this.optOutMessage === 'Opt Out';
    this.contactService.optOutContact(contactId, {optout: optOut}).subscribe(() => {
      this.optOutInProgress = true;
      this.toastService.show('Opt-out status changed!', {
        classname: 'bg-success text-light',
        delay: 5000
      });
      modal.close();
    }, () => {
      this.toastService.show('There was an error changing opt-out status', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
      modal.dismiss();
    });
  }

  exitModal(e, modal) {
    e.preventDefault();
    modal.dismiss();
  }
}