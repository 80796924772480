<div class="page-title">
  <h2>Defaults</h2>
</div>
<div class="page">

  <!-- TEMPLATE DEFAULTS -->
  <div class="alert alert-warning">Setting your template defaults at the account level will overwrite all existing defaults for all templates</div>
  <div class="template-defaults-title">
    <h6 class="title pull-left">Template Defaults</h6>
    <button class="button pull-right button-light" [disabled]="savingDefaults" (click)="saveDefault()">
      <span *ngIf="savingDefaults" class="spinner-border spinner-border-sm mr-1"></span>
      Save/Reset Defaults
    </button>
  </div>
  <div class="user-table no-border">
    <div class="form-group">
      <label for="bannerImage">Banner Image</label>
      <img class="preview-image" *ngIf="templateDefaults.bannerImage && !loadingImage.bannerImage" [src]="templateDefaults.bannerImage" />
      <p *ngIf="templateDefaults.bannerImage && !loadingImage.bannerImage" (click)="resetImage('bannerImage')" class="remove-default"><i class="fal fa-trash-alt"></i> Remove Image</p>
      <div *ngIf="loadingImage.bannerImage" class="spinner-border spinner-border-sm"></div>
      <input *ngIf="!templateDefaults.bannerImage && !loadingImage.bannerImage" (change)="uploadMedia($event.target.files, 'bannerImage')" type="file" />
      <p *ngIf="!templateDefaults.bannerImage && !loadingImage.bannerImage" class="media-manager-helper-text">or</p>
      <button *ngIf="!templateDefaults.bannerImage && !loadingImage.bannerImage" (click)="openMediaModal($event, 'bannerImage')" class="button button-white"><i class="fal fa-image"></i> Select From Media Manager</button>
    </div>
    <div class="form-group">
      <div class="form-group">
        <label for="bannerImage">Logo</label>
        <img class="preview-image" *ngIf="templateDefaults.leftLogo && !loadingImage.leftLogo" [src]="templateDefaults.leftLogo" />
        <p *ngIf="templateDefaults.leftLogo && !loadingImage.leftLogo" (click)="resetImage('leftLogo')" class="remove-default"><i class="fal fa-trash-alt"></i> Remove Image</p>
        <div *ngIf="loadingImage.leftLogo" class="spinner-border spinner-border-sm"></div>
        <input *ngIf="!templateDefaults.leftLogo && !loadingImage.leftLogo" (change)="uploadMedia($event.target.files, 'leftLogo')" type="file" />
        <p *ngIf="!templateDefaults.leftLogo && !loadingImage.leftLogo" class="media-manager-helper-text">or</p>
        <button *ngIf="!templateDefaults.leftLogo && !loadingImage.leftLogo" (click)="openMediaModal($event, 'leftLogo')" class="button button-white"><i class="fal fa-image"></i> Select From Media Manager</button>
      </div>
    </div>
    <div class="form-group">
      <label>Current Concession</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.concession" [ngModelOptions]="{standalone: true}" id="concession" aria-describedby="concession" placeholder="Enter property concession">
    </div>
    <div class="form-group">
      <label>Property Website URL</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.yourWebsite" [ngModelOptions]="{standalone: true}" id="yourWebsite" aria-describedby="yourWebsite" placeholder="Enter property website">
    </div>
    <div class="form-group">
      <label>Online Application URL</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.onlineApplicationUrl" [ngModelOptions]="{standalone: true}" id="onlineApplicationUrl" aria-describedby="onlineApplicationUrl" placeholder="Enter online application URL">
    </div>
    <div class="form-group">
      <label>Resident Portal URL</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.residentPortalUrl" [ngModelOptions]="{standalone: true}" id="residentPortalUrl" aria-describedby="residentPortalUrl" placeholder="Enter online resident portal URL">
    </div>
    <div class="form-group">
      <label>Property Email Address (Tracking Email Preferred)</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.yourEmailAddress" [ngModelOptions]="{standalone: true}" id="yourEmailAddress" aria-describedby="yourEmailAddress" placeholder="Enter property email address">
    </div>
    <div class="form-group">
      <label>Property Phone Number (Tracking Number Preferred)</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.yourPhoneNumber" [ngModelOptions]="{standalone: true}" id="yourPhoneNumber" aria-describedby="yourPhoneNumber" placeholder="Enter property phone number">
    </div>
    <div class="form-group">
      <label>Leasing Agent/Team Name</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.yourName" [ngModelOptions]="{standalone: true}" id="yourName" aria-describedby="yourName" placeholder="Enter leasing agent/team name">
    </div>
    <div class="form-group">
      <label>Footer Content</label>
      <input type="text" class="form-control" [(ngModel)]="templateDefaults.footerContent" [ngModelOptions]="{standalone: true}" id="footerContent" aria-describedby="footerContent" placeholder="Enter footer content">
    </div>
  </div>
</div>
