<div id="contacts">
    <div class="page-title">
        <!-- <div class="actions left">
            <i class="far fa-bars"></i>
        </div> -->
        <h2>Contacts</h2>
        <!-- <div class="actions right">
            <i class="far fa-search"></i>
        </div> -->
    </div>
    <div class="card mt-4">
        <div class="card-body contacts-table">
            <div class="overflow-auto step-instruction-wrap">
                <button class="button button-dark pull-right" (click)="createNewContacts($event, addContactsModal)">Create Contacts</button>
                <!-- <button class="button button-red pull-right margin-right-12" [disabled]="selectedRows.length === 0" (click)="deleteContacts($event)">Delete Contacts</button> -->
                <button class="button button-white pull-right margin-right-12" [disabled]="selectedRows.length === 0" (click)="addLabelToContacts($event, addLabelModal)">Add Labels</button>
                <button class="button button-red pull-right margin-right-12" [disabled]="selectedRows.length === 0" (click)="bulkDeleteContacts($event)">Delete Contacts</button>
              </div>
            <div class="overflow-auto table-actions">
                <p class="table-row-indicator"><strong>Showing {{pagination.first}} to {{pagination.last}} of {{totalRecords}} contacts</strong></p>
            </div>
            <div class="crm-prospect-table-wrap">
                <p-table #dt [value]="contactDataToShow" [(selection)]="selectedRows" dataKey="_id" styleClass="ui-table-customers" [rowHover]="true"
                    [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,100,250,500,1000]" [loading]="loading"
                    [paginator]="true" [filterDelay]="0" [globalFilterFields]="['clientFullName','created','updated','label']" totalRecords="{{totalRecords}}"
                    (onPage)="paginate($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th></th>
                            <th pSortableColumn="name">Name <p-sortIcon field="clientFullName"></p-sortIcon></th>
                            <th pSortableColumn="status">Label <p-sortIcon field="labels"></p-sortIcon></th>
                            <th pSortableColumn="created">Created Date <p-sortIcon field="created"></p-sortIcon></th>
                            <th pSortableColumn="updated">Updated Date <p-sortIcon field="updated"></p-sortIcon></th>
                            <th pSortableColumn="optIn">Opt In <p-sortIcon field="optIn"></p-sortIcon></th>
                        </tr>
                        <!-- Table Filters -->
                        <tr>
                            <th>
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th>
                            <input pInputText type="text" (input)="dt.filter($event.target.value, 'clientFullName', 'contains')" placeholder="Search by Name" class="ui-column-filter">
                            </th>
                            <th>
                            <p-multiSelect [options]="labelFilterOptions" placeholder="All" (onChange)="onLabelFilterChange($event)" styleClass="ui-column-filter">
                            </p-multiSelect>
                            </th>
                            <th>
                            <p-calendar selectionMode="range" (onSelect)="onCreatedDateSelect($event)" (onClearClick)="dt.filter('', 'created', 'equals')" [showButtonBar]="true" styleClass="ui-column-filter" placeholder="Created Date" [readonlyInput]="true" dateFormat="mm-dd-yy"></p-calendar>
                            </th>
                            <th>
                            <p-calendar selectionMode="range" (onSelect)="onUpdatedDateSelect($event)" (onClearClick)="dt.filter('', 'updated', 'equals')" [showButtonBar]="true" styleClass="ui-column-filter" placeholder="Updated Date" [readonlyInput]="true" dateFormat="mm-dd-yy"></p-calendar>
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-prospect let-rowData>
                    <tr class="ui-selectable-row">
                        <td>
                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                        </td>
                        <td>
                            <a class="clickable" (click)="viewContact(prospect._id, $event)">{{prospect.clientFullName}}</a>
                        </td>
                        <td>
                            <span *ngFor="let label of prospect.labels" class="contact-label">{{label}}</span>
                        </td>
                        <td>
                            {{prospect.created | date}}
                        </td>
                        <td>
                            {{prospect.updated | date}}
                        </td>
                        <td>
                            {{prospect.optIn}}
                        </td>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                        <td colspan="8" style="text-align:left">No contacts found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>

<!-- Add Label Modal -->
<ng-template #addLabelModal let-addLabelModal>
    <div class="modal-header">
        <h5 class="modal-title">Add Contact Labels</h5>
        <div class="float-right">
            <button type="button" class="button button-white button-small" (click)="exitModal($event, addLabelModal)"><i class="far fa-times" aria-hidden="true"></i></button>
        </div>
    </div>
    <div class="schedule-text-modal modal-body">
        <p class="alert alert-danger">This label will be added to <strong>{{selectedRows.length}} contacts.</strong></p>
        <div class="row">
            <div class="col-md-12">
                <div class="input-group mb-3">
                    <p-chips [(ngModel)]="newLabelData" placeholder="Type New Label Here"></p-chips>
                </div>
            </div>
        </div>
        <button [disabled]="!newLabelData || labelSaveInProgress" (click)="saveNewLabel($event, addLabelModal)" class="button button-dark">
            <span *ngIf="labelSaveInProgress" class="spinner-border spinner-border-sm mr-1"></span>
            Add Label
        </button>
    </div>
</ng-template>

<!-- Add Contacts Modal -->
<ng-template #addContactsModal let-addContactsModal>
    <div class="modal-header">
        <h5 class="modal-title">Add New Contacts</h5>
        <div class="float-right">
            <button type="button" class="button button-white button-small" (click)="exitModal($event, addContactsModal)"><i class="far fa-times" aria-hidden="true"></i></button>
        </div>
    </div>
    <div class="schedule-text-modal modal-body">
        <div *ngIf="activeStep === 1">
            <p class="step-instructions">Upload your spreadsheet to get started!</p>
            <br />
            <div>
                <label class="button button-white" for="data-upload"><i class="fal fa-file-search"></i> Select file</label>
                <input type="file" class="bulk-upload-input" id="data-upload" value="" accept="text/csv" />
                <span *ngIf="fileUploaded"><i (click)="refresh()" class="fal fa-trash-alt"></i> {{fileUploaded.name}}</span><button class="button button-dark pull-right" *ngIf="stepSuccess" (click)="nextStep($event)"> Continue</button>
            </div>
            <div>
                <a href="https://app.customnurturepages.com/api/docs/contacts-template" target="_blank">Download Template</a>
            </div>
            <div class="alert alert-danger" *ngIf="bulkUploadError">
                {{bulkUploadError}}
            </div>
            <button class="button button-dark pull-right" (click)="processUploadedFile($event)" [disabled]="!fileUploaded">Upload File</button>
        </div>
        <div *ngIf="activeStep === 2">
            <p class="step-instructions">Sample data. Does everything look like it's in the right spot?</p>
            <table>
                <thead>
                    <th>Full Name</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Labels</th>
                    <th>Phone Number</th>
                    <th>Email Address</th>
                    <th>Opt In</th>
                </thead>
                <tbody>
                    <tr>
                        <td>{{sampleData.firstName}} {{sampleData.lastName}}</td>
                        <td>{{sampleData.firstName}}</td>
                        <td>{{sampleData.lastName}}</td>
                        <td>{{sampleData.labels}}</td>
                        <td>{{sampleData.phoneNumber}}</td>
                        <td>{{sampleData.emailAddress}}</td>
                        <td>{{sampleData.optIn}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="alert alert-danger" *ngIf="contactUploadError">
                There was an error uploading some of your contacts.
            </div>
            <button class="button button-dark pull-right"  *ngIf="!contactUploadError" (click)="uploadContacts($event, addContactsModal)">Create Contacts</button>
            <button class="button button-white pull-right"  *ngIf="contactUploadError" (click)="exitModal($event, addContactsModal)">Okay</button>
        </div>
    </div>
</ng-template>