import { Component} from '@angular/core';
import { UsersService, AuthenticationService, ToastService, LoaderService } from '@app/_services';
var self;

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.less']
})
export class AccountComponent {

  userData: any;
  settings: any = {
    claims: []
  };
  savingSettings: boolean = false;

  constructor(
    private userService: UsersService,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private loaderService: LoaderService
  ) {
      this.userData = this.authService.currentUserValue;
      this.loadData();
      self = this;
  }

  loadData() {

    // Get user settings
    this.loaderService.triggerLoader();
    this.userService.getUserData(this.userData.user._id).subscribe((data) => {
      this.settings = data.result;
      this.loaderService.stopLoader();
    }, (err) => {
      this.toastService.show('There was an error retrieving your user settings', { classname: 'bg-danger text-light', delay: 5000 });
      console.error('There was an error retrieving your user settings: ', err);
      this.loaderService.stopLoader();
    });
  }

  saveTimezone() {
    this.savingSettings = true;
    this.userService.updateUser(this.userData.user._id, {
      timezone: this.settings.timezone
    }, true).subscribe(() => {
      this.savingSettings = false;
      this.toastService.show('Timezone updated!', { classname: 'bg-success text-light', delay: 5000 });
    }, (e) => {
      this.savingSettings = false;
      console.error('There was an error updating user settings: ', e);
      this.toastService.show('There was an error updating timezone', { classname: 'bg-danger text-light', delay: 5000 });
    });
  }
}
