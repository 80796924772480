import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

var self;

@Injectable({ providedIn: 'root' })
export class MediaService {

  constructor(
      private http: HttpClient,
      private apiHelper: APIHelperService
  ) {
    self = this;
  }

  getSignedRequest(file) {
    var queryParams = {
      'file-name': file.name,
      'file-type': file.type,
      'file-size': file.size
    };
    return this.http.get<any>(this.apiHelper.fillUrl('s3SignRequest', {}, queryParams)).
        pipe(map(data => {
          return data;
        }));
  }

  getAllMedia() {
    return this.http.get<any>(this.apiHelper.fillUrl('media', {}, {})).
        pipe(map(data => {
          return data;
        }));
  }

  deleteMedia(id) {
    return this.http.delete<any>(this.apiHelper.fillUrl('singleMedia', {id: id}, {})).
        pipe(map(data => {
          return data;
        }));
  }

  uploadFile(file, signedRequest, url, formModel, property) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', signedRequest);
      xhr.onreadystatechange = () => {
          if(xhr.readyState === 4){
              if(xhr.status === 200){
                if (formModel) {
                  formModel.value[property] = url;
                }
              } else {
                  alert('Could not upload file.');
              }
          }
      };
      xhr.send(file);
      resolve(url);
    })
  }

  getSignedRequestHelper(fileItem, formModel, property) {
    return new Promise((resolve, reject) => {
      if (fileItem) {
          self.getSignedRequest(fileItem[0]).subscribe((data) => {
              self.uploadFile(fileItem[0], data.result.signedRequest, data.result.url, formModel, property).
                  then((data) => {
                    resolve(data);
                  }).
                  catch((err) => {
                    console.error('Error uploading image: ', err);
                    reject(err);
                  })
          });
      } else {
        resolve();
      }
    })
  };
}