import { Component} from '@angular/core';
import { AuthenticationService, SubscriptionsService, ToastService, LoaderService, PaymentMethodsService } from '@app/_services';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
declare var stripe: any;
declare var elements: any;
var cardElement: any;
var self;

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.less']
})
export class BillingComponent {

  subscriptionLoading: Boolean = false;
  loadingInvoices: Boolean = false;
  loadingPaymentMethods: Boolean = false;
  savingCardLoaded: Boolean = false;
  userData: any;
  subscriptionData: any;
  invoiceData: Array<any>;
  statusMapper = {
    incomplete: 'red',
    incomplete_expired: 'red',
    trialing: 'red',
    canceled: 'red',
    unpaid: 'red',
    active: 'green',
    past_due: 'yellow'
  };
  paymentMethodsData: Array<any>;
  modalOptions: NgbModalOptions;

  constructor(
    private authService: AuthenticationService,
    private subscriptionService: SubscriptionsService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private paymentMethods: PaymentMethodsService,
    private modalService: NgbModal
  ) {
      this.userData = this.authService.currentUserValue;
      this.loadData();
      self = this;
      this.modalOptions = { windowClass : 'custom-modal-styles-new'};
  }

  loadData() {

    // Get user settings
    this.loaderService.triggerLoader();
    this.subscriptionLoading = true;
    this.loadingInvoices = true;
    this.loadingPaymentMethods = true;

    this.subscriptionService.getSubscription().subscribe((data) => {
      this.subscriptionLoading = false;
      this.subscriptionData = data.result;
      this.subscriptionData.color = this.statusMapper[this.subscriptionData.status];
      this.subscriptionService.getInvoices().subscribe((data) => {
        this.invoiceData = data.result.data;
        this.loadingInvoices = false;
        this.paymentMethods.getPaymentMethods().subscribe((data) => {
          this.paymentMethodsData = data.result.data;
          this.loadingPaymentMethods = false;
          this.loaderService.stopLoader();
        }, (err) => {
          this.toastService.show('There was an error retrieving your payment methods', { classname: 'bg-danger text-light', delay: 5000 });
          console.error('There was an error loading payment methods: ', err);
          this.loadingPaymentMethods = false;
          this.loaderService.stopLoader();
        });
      }, (err) => {
        this.toastService.show('There was an error retrieving your invoices', { classname: 'bg-danger text-light', delay: 5000 });
        console.error('There was an error loading invoices: ', err);
        this.loadingInvoices = false;
        this.loaderService.stopLoader();
      });
    }, (err) => {
      this.toastService.show('There was an error retrieving your subscription data', { classname: 'bg-danger text-light', delay: 5000 });
      console.error('There was an error retrieving subscription data: ', err);
      this.subscriptionLoading = false;
      this.loaderService.stopLoader();
    });
  }

  removeCard(data) {

    // TODO: Hook up card delete.
    if (this.paymentMethodsData.length === 1) {
      this.toastService.show('Cannot delete card if it is the only one', { classname: 'bg-danger text-light', delay: 5000 });
      return;
    } else {
      // no-op
    }
  }

  addNewPaymentMethod(content) {
    this.modalService.open(content).result.then((result) => {
      if (result === 'Success') {

        // Kill stripe form element.
        if (cardElement) {
          cardElement.destroy()
        }
      }
    }, (reason) => {
      if (reason === 'Success') {

        // Kill stripe form element.
        if (cardElement) {
          cardElement.destroy()
        }
      }
    });
    setTimeout(() => {

      // Set up Stripe.js and Elements to use in checkout form
      var style = {
        base: {
          color: "#495057",
          fontFamily: '"Roboto", sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
              color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };
      
      cardElement = elements.create("card", { style: style });
      cardElement.mount("#card-element");
  
      cardElement.addEventListener('change', ({error}) => {
        if (error && error.message) {
            this.setPaymentFormError(error.message);
        }
      });
    });
  }

  setPaymentFormError(error) {
    const displayError = document.getElementById('card-errors');
    if (error) {
      displayError.textContent = error;
    } else {
      displayError.textContent = '';
    }
  }

  paymentSubmit(modal) {
    self.savingCardLoaded = true;
    stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
          email: self.userData.user.email,
      },
    }).
        then(function(result) {
          self.paymentMethods.attachToCustomer(result.paymentMethod.id).subscribe(() => {
                self.paymentMethodsData.push(result.paymentMethod);
                self.toastService.show('Payment method added', { classname: 'bg-success text-light', delay: 5000 });
                self.savingCardLoaded = false;
                modal.close();
              }, (err) => {
                self.savingCardLoaded = false;
                self.toastService.show('There was an error creating your payment method', { classname: 'bg-danger text-light', delay: 5000 });
                console.error('There was an error creating payment method. ', err);
              });
        }).
        catch(function(err){
          self.savingCardLoaded = false;
          self.toastService.show('There was an error creating your payment method', { classname: 'bg-danger text-light', delay: 5000 });
          console.error('There was an error creating payment method. ', err);
        });
  }

  ngOnDestroy() {

    // Kill stripe form element.
    if (cardElement) {
      cardElement.destroy()
    }
  }
}
