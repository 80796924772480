import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class APIHelperService {

  endpoints: any;
  baseUrl: string;
  eventsUrl: String;
  textsUrl: String;
  emailsUrl: String;

  constructor() {

    // For production.
    this.baseUrl = 'https://app.customnurturepages.com/';
    this.eventsUrl = 'https://events.customnurturepages.com/';
    this.textsUrl = 'https://texts.nurtureboss.io/';
    this.emailsUrl = 'https://emails.nurtureboss.io/';

    // For development.
    // this.baseUrl = 'http://localhost:5000/';
    // this.eventsUrl = 'http://localhost:5000/';
    // this.textsUrl = 'http://localhost:5000/'
    // this.emailsUrl = 'http://localhost:5000/'

    this.endpoints = {
      'login': this.baseUrl + 'api/login',    
      'register': this.baseUrl + 'api/register',
      'pages': this.baseUrl + 'api/pages',
      'singlePage': this.baseUrl + 'api/pages/:id',
      'pageClicks': this.baseUrl + 'api/pages/clicks',
      'media': this.baseUrl + 'api/media',
      'singleMedia': this.baseUrl + 'api/media/:id',
      's3SignRequest': this.baseUrl + 'api/media/sign',
      'subscribes': this.baseUrl + 'api/subscriptions',
      'subscribe': this.baseUrl + 'api/subscriptions/:id',
      'subscribeCancel': this.baseUrl + 'api/subscriptions/cancel',
      'users': this.baseUrl + 'api/users',
      'user': this.baseUrl + 'api/users/:id',
      'invoices': this.baseUrl + 'api/subscriptions/invoices',
      'paymentMethods': this.baseUrl + 'api/paymentMethods',
      'paymentAttach': this.baseUrl + 'api/paymentMethods/attach',
      'getTextMessages': this.baseUrl + 'api/textMessages',
      'textMessages': this.textsUrl + 'api/textMessages',
      'textMessagesBulk': this.textsUrl + 'api/textMessages/bulk',
      'labels': this.baseUrl + 'api/labels',
      'label': this.baseUrl + 'api/labels/:id',
      'templateDefault': this.baseUrl + 'api/templateDefaults/:templateName',
      'templateDefaults': this.baseUrl + 'api/templateDefaults',
      'textTemplates': this.baseUrl + 'api/textTemplates',
      'textTemplate': this.baseUrl + 'api/textTemplates/:id',
      'resetPasswordLink': this.baseUrl + 'api/resetPassword',
      'updatePassword': this.baseUrl + 'api/resetPassword/update',
      'analytics': this.eventsUrl + 'api/analytics',
      'realPageProspects': this.baseUrl + 'api/realPage/prospects',
      'realPageWorkers': this.baseUrl + 'api/realPage/workers/:id',
      'terms': this.baseUrl + 'api/terms',
      'schedules': this.baseUrl + 'api/schedules',
      'scheduleEmail': this.baseUrl + 'api/schedules/email',
      'contacts': this.baseUrl + 'api/contacts',
      'contactsWorker': this.baseUrl + 'api/contacts/workers/:id',
      'contact': this.baseUrl + 'api/contacts/:id',
      'contactsPages': this.baseUrl + 'api/contacts/:id/pages',
      'contactsTexts': this.baseUrl + 'api/contacts/:id/texts',
      'conversations': this.baseUrl + 'api/conversations',
      'conversation': this.baseUrl + 'api/conversations/:id',
      'reply': this.textsUrl + 'api/conversations/:id/reply',
      'unread': this.baseUrl + 'api/conversations/unread',
      'adminAccounts': this.baseUrl + 'api/admin/accounts',
      'mimicAccount': this.baseUrl + 'api/admin/mimic/:id',
      'yardiProspects': this.baseUrl + 'api/yardi/prospects',
      'yardiConfiguration': this.baseUrl + 'api/yardi/configuration',
      'yardiILSConfiguration': this.baseUrl + 'api/yardi/ILS-configuration',
      'yardiWorkers': this.baseUrl + 'api/yardi/workers/:id',
      'defaults': this.baseUrl + 'api/defaults',
      'usages': this.baseUrl + 'api/admin/usages',
      'yardiSources': this.baseUrl + 'api/yardi/sources',
      'yardiSource': this.baseUrl + 'api/yardi/sources/:id',
      'automations': this.baseUrl + 'api/automations',
      'singleAutomation': this.baseUrl + 'api/automations/:type',
      'automationSettings': this.baseUrl + 'api/automationSettings/:userId',
      'automationSettingSources': this.baseUrl + 'api/automationSettings/:userId/sources',
      'emails': this.emailsUrl + 'api/emails',
      'sendEmail': this.emailsUrl + 'api/emails/send',
      'sendEmailTemplate': this.emailsUrl + 'api/emails/template',
      'sendBulkEmails': this.emailsUrl + 'api/emails/bulk',
      'optout': this.baseUrl + 'api/contacts/:id/optout',
      'contactLabels': this.baseUrl + 'api/contacts/labels'
    }
  }

  fillUrl(urlFormat:string, pathParams:any, queryParams:any) {
    var url = this.endpoints[urlFormat];
    for (let name in pathParams) {
      if (typeof(pathParams[name]) === 'undefined' || pathParams[name] === null || pathParams[name] === '') {
        url = url.replace(new RegExp('/:' + name, 'g'), '');
      } else {
        url = url.replace(new RegExp(':' + name, 'g'), pathParams[name]);
      }
    }
    if (Object.keys(queryParams).length > 0) {
      var formattedQueryParams = [];
      for (let param in queryParams) {
        formattedQueryParams.push('' + param + '=' + queryParams[param]);
      }
      url += '?' + formattedQueryParams.join('&');
    }
    return url;
  }

  getWebSocketEndpoint(): any {
    return {
      webApp: this.baseUrl.replace('http', 'ws'),
      texting: this.textsUrl.replace('http', 'ws')
    };
  }
}