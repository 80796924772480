import { Routes, RouterModule } from '@angular/router';
import { TemplatesComponent, TemplatePickerComponent, TemplateCreateComponent, TemplateCreateNonMultifamilyComponent } from './templates';
import { LoginComponent } from './login';
import { AuthGuard } from './_helpers';
import { SettingsComponent } from './settings/settings.component';
import { PagesComponent } from './pages/pages.component';
import { TrackingComponent } from './tracking/tracking.component';
import { ForgotPasswordComponent } from './forgot-password';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { DashboardComponent } from './dashboard';
import { ContactComponent } from './contact';
import { TwoWayComponent } from './two-way';
import { AutomationComponent, SingleAutomationComponent } from './automation';
import { AdminComponent } from './admin';
import { ContactsComponent } from './contacts';
import { BillingComponent } from './settings/billing/billing.component';
import { AccountComponent, IntegrationsComponent, MediaComponent } from './settings';
import { DefaultsComponent } from './settings/defaults/defaults.component';
import { ComplianceComponent } from './settings/compliance/compliance.component';

const routes: Routes = [
    { 
        path: 'templates',
        component: TemplatesComponent,
        canActivate: [AuthGuard],
        children: [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: 'select'
        },
        {
            path: 'select',
            component: TemplatePickerComponent
        },
        {
            path: 'new',
            component: TemplateCreateComponent
        },
        {
            path: 'new-general',
            component: TemplateCreateNonMultifamilyComponent
        }
    ] },
    { 
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
        children: [
        {
            path: '',
            pathMatch: 'full',
            redirectTo: 'billing'
        },
        {
            path: 'billing',
            component: BillingComponent
        },
        {
            path: 'account',
            component: AccountComponent
        },
        {
            path: 'defaults',
            component: DefaultsComponent
        },
        {
            path: 'compliance',
            component: ComplianceComponent
        },
        {
            path: 'integrations',
            component: IntegrationsComponent
        },
        {
            path: 'media',
            component: MediaComponent
        }
    ] },
    { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
    { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
    { path: 'automation', component: AutomationComponent, canActivate: [AuthGuard] },
    { path: 'automation/configure', component: SingleAutomationComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'pages', component: PagesComponent, canActivate: [AuthGuard]  },
    { path: 'tracking', component: TrackingComponent, canActivate: [AuthGuard]  },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'password-reset', component: PasswordResetComponent },
    { path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuard]  },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]  },
    { path: 'contacts', component: ContactsComponent, canActivate: [AuthGuard]  },
    { path: 'contact', component: ContactComponent, canActivate: [AuthGuard]  },
    { path: 'messages', component: TwoWayComponent, canActivate: [AuthGuard]  },

    // otherwise redirect to home
    { path: '**', redirectTo: 'dashboard' }
];

export const appRoutingModule = RouterModule.forRoot(routes, {useHash: true});