import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService, ToastService } from '@app/_services';

@Component({
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private toastService: ToastService
    ) { }

    ngOnInit() {
        this.forgotPasswordForm = this.formBuilder.group({
            email: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.forgotPasswordForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.error = null;

        // stop here if form is invalid
        if (this.forgotPasswordForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.getPasswordResetLink(this.f.email.value)
            .subscribe(() => {
                this.loading = false;
                this.toastService.show('If the entered email is associated with an account an email with reset instructions was sent.', {
                    classname: 'bg-success text-light',
                    delay: 5000
                });
              setTimeout(() => {
                this.router.navigate(['/login']);
                this.forgotPasswordForm.controls.email.setValue('');
              }, 2000)
            },
            () => {
              this.error = 'There was an error sending reset link, please try again.';
              this.loading = false;
            });
    }
}
