<div class="page-title">
  <h2>Compliance</h2>
</div>
<div class="page">

  <!-- TCPA Enabled Sources-->
  <div *ngIf="hasIntegration" class="margin-bottom-48">
    <h6 class="title">Opted-In Sources</h6>
    <div>
      <div *ngIf="!loading">
        <ng-multiselect-dropdown
          [placeholder]="'Select compliant sources'"
          [settings]="dropdownSettings"
          [data]="sources"
          [(ngModel)]="storedSources"
          (onSelect)="updateSources($event)"
          (onSelectAll)="updateSourcesAddAll($event)"
          (onDeSelect)="updateSources($event)"
          (onDeSelectAll)="updateSources($event)"
        >
        </ng-multiselect-dropdown>
      </div>
    </div>
  </div>

  <!-- TCPA SETTINGS -->
  <div>
    <div class="overflow-auto margin-bottom-24">
      <h6 class="pull-left title">Opt-In Settings</h6>
      <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="updateUserSettings()">
        <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
        Save Settings
      </button>
    </div>
    <div *ngIf="loadingUserSettings" class="spinner-border spinner-border-sm"></div>
    <div *ngIf="!loadingUserSettings" class="user-table no-border">
      <div>
        <h6>Single Opt-In</h6>
        <label class="switch">
        <input type="checkbox" [(ngModel)]="userData.optIn" (change)="updateUserSettings()">
        <span class="slider round"></span>
        </label>
      </div>
      <div class="form-group">
        <label>Opt-In Keyword</label>
        <input type="text" [readonly]="!userData.optIn" class="form-control" [(ngModel)]="userData.keyword" [ngModelOptions]="{standalone: true}" id="optinKeyword" aria-describedby="optinKeyword" placeholder="Enter opt-in keyword">
      </div>
      <!-- <div>
        <h6>Double Opt-In</h6>
        <label class="switch">
        <input type="checkbox" [(ngModel)]="userData.doubleOptIn" (change)="updateUserSettings()">
        <span class="slider round"></span>
        </label>
      </div>
      <div>
        <h6><em>Text Opt-In Contacts Only (coming soon)</em></h6>
        <label class="switch">
        <input disabled type="checkbox" [(ngModel)]="userData.optInContactsOnly">
        <span class="slider round"></span>
        </label>
      </div> -->
    </div>
  </div>
</div>
