import { Component, Input } from '@angular/core';

@Component({
  selector: 'guestcardemailpreview',
  templateUrl: 'guest-card-email-preview.component.html',
  styleUrls: ['guest-card-email-preview.component.less']
})
export class GuestCardEmailPreviewComponent {

  @Input() emailData: any;

  constructor() {
    //no-op
  }
}
  