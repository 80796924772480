<div class="page-title">
  <h2>Integrations</h2>
</div>
<div class="page" *ngIf="!hasIntegration">
  <div class="row">
    <p class="text-center full-width margin-top-48">
      <img src="/assets/no-messaging.png" />
    </p>
    <h3 class="text-center full-width margin-top-48">You do not have your integration enabled. Please contact <a href="mailto:customersuccess@nurtureboss.io">customersuccess@nurtureboss.io</a> to enable this for your account!</h3>
  </div>
</div>
<div class="page" *ngIf="hasIntegration">

  <!-- YARDI AGENT NAME OF NB EVENTS -->
  <div *ngIf="yardiAgents.length">
    <div class="col-md-4 no-margin no-padding">
      <h6 class="title">Select Agent To Represent Nurture Boss Activity:</h6>
      <div class="form-group">
        <select class="form-control" [(ngModel)]="userData.yardiAgentName" [ngModelOptions]="{standalone: true}">
          <option *ngFor="let agent of yardiAgents" [ngValue]="agent" >{{agent}}</option>
        </select>
      </div>
      <button [disabled]="savingAgent" class="button button-dark margin-bottom-24 margin-top-12" (click)="updateAgentOfRecord()">
        <span *ngIf="savingAgent" class="spinner-border spinner-border-sm mr-1"></span>
        Update Agent of Record
      </button>
    </div>
  </div>

  <!-- YARDI PROPERTY CONFIGURATIONS -->
  <div class="margin-top-48">
    <h6 class="title">Yardi Property Configurations</h6>
    <div *ngIf="loadingYardiConfigurations" class="spinner-border spinner-border-sm"></div>
    <div *ngIf="!loadingYardiConfigurations" class="yardi-config">
      <div *ngIf="yardiConfigurations.length">
        <div *ngFor="let config of yardiConfigurations">
          <h6>Property Code:</h6>
          {{config.Code}}
          <h6>Property Address:</h6>
          {{config.AddressLine1}} {{config.AddressLine2}} {{config.City}} {{config.State}} {{config.PostalCode}}
          <h6>Property Marketing Name:</h6>
          {{config.MarketingName}}
        </div>
      </div>
      <div *ngIf="!yardiConfigurations.length">
        <div>
          <h6>Property Code:</h6>
          {{yardiConfigurations.Code}}
          <h6>Property Address:</h6>
          {{yardiConfigurations.AddressLine1}} {{yardiConfigurations.AddressLine2}} {{yardiConfigurations.City}} {{yardiConfigurations.State}} {{yardiConfigurations.PostalCode}}
          <h6>Property Marketing Name:</h6>
          {{yardiConfigurations.MarketingName}}
        </div>
      </div>
    </div>
  </div>

  <!-- YARDI ILS CONFIGURATIONS -->
  <div>
    <h6 class="title">Yardi ILS Configurations</h6>
    <button *ngIf="!loadingYardiConfigurations" class="button button-white margin-bottom-24" (click)="triggerShowILSConfiguration()">Show/Hide ILS Configuration</button>
    <div *ngIf="loadingYardiConfigurations" class="spinner-border spinner-border-sm"></div>
    <div *ngIf="!loadingYardiConfigurations && showILSConfiguration" class="yardi-config">
      <pre>{{yardiILSConfigurations}}</pre>
    </div>
  </div>

  <!-- WIDGET CODE REALPAGE -->
  <!-- <div *ngIf="settings.claims.indexOf('realpage') > -1 && (settings.optIn || settings.doubleOptIn)">
    <h6 class="title">Website Widget Code</h6>
    <div class="code-snippet">
      <span>Copy and paste the following code directly above the closing body tag (<code>&lt;/body&gt;</code>) of your property website.</span>
      <pre>&lt;!-- Nurture Boss Chat Widget --&gt;
&lt;div id="nurture-boss-chat"&gt;&lt;/div&gt;
&lt;script src="https://nurtureboss.io/nb-embed-min.js" key="{{userData.token}}"
&gt;&lt;/script&gt;</pre>
    </div>
  </div> -->

  <!-- WIDGET CODE YARDI -->
  <!-- <div *ngIf="(settings.claims.indexOf('yardi') > -1) && (settings.optIn || settings.doubleOptIn)">
    <h6 class="title">Website Widget Code</h6>
    <div class="code-snippet">
      <span>Copy and paste the following code directly above the closing body tag (<code>&lt;/body&gt;</code>) of your property website.</span>
      <pre>&lt;!-- Nurture Boss Chat Widget --&gt;
&lt;div id="nurture-boss-chat"&gt;&lt;/div&gt;
&lt;script src="https://nurtureboss.io/nb-yardi-embed-min.js" key="{{userData.token}}"
&gt;&lt;/script&gt;</pre>
    </div>
  </div> -->

</div>
