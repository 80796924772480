import { Component} from '@angular/core';
import { AuthenticationService } from '@app/_services';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent {

  userData: any;

  constructor(
    private authService: AuthenticationService
  ) {
    this.userData = this.authService.currentUserValue.user;
  }
}
