<div id="contact">
  <div class="page-title">
    <!-- <div class="actions left">
      <i class="far fa-bars"></i>
      </div> -->
    <h2><a (click)="backToContacts($event)" href="">&lt; Back To Contacts</a></h2><br />
    <h2>Contact: {{activeContact.contactIdentifier}}</h2>
    <div class="button button-dark pull-right" (click)="updateContact()">Update Contact</div>
    <!-- <div class="actions right">
      <i class="far fa-search"></i>
      </div> -->
  </div>
  <div class="card mt-4">
    <div class="card-body">
      <div class="row margin-left-12">
        <div class="col-md-12">
          <h4 class="card-header">Contact Information</h4>
          <div class="row">
            <div class="col-md-6">
              <p>First Name: <input class="form-control" [(ngModel)]="activeContact.firstName" [ngModelOptions]="{standalone: true}" [readonly]="integrationActive" /></p>
              <p>Last Name: <input class="form-control" [(ngModel)]="activeContact.lastName" [ngModelOptions]="{standalone: true}" [readonly]="integrationActive" /></p>
              <p>Phone Number: <input class="form-control" [(ngModel)]="activeContact.phoneNumber" [ngModelOptions]="{standalone: true}" [readonly]="integrationActive" /></p>
              <p>Email Address: <input class="form-control" [(ngModel)]="activeContact.emailAddress" [ngModelOptions]="{standalone: true}" [readonly]="integrationActive" /></p>
              <p>Identifier: <input class="form-control" [(ngModel)]="activeContact.contactIdentifier" [ngModelOptions]="{standalone: true}" [readonly]="integrationActive" /></p>
              <p>Labels: <p-chips class="contact-label" [(ngModel)]="activeContact.labels"></p-chips></p>
              <p class="pull-left margin-right-12">Opt In:</p>
              <div>
                <label class="switch">
                <input [readonly]="integrationActive"  type="checkbox" [(ngModel)]="activeContact.optIn" (change)="updateContact()">
                <span class="slider round"></span>
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <strong>Engagement Score:</strong> {{engagementScore}}
              <div class="alert alert-success" *ngIf="engagementScore > 3">
                {{activeContact.contactIdentifier}} is showing a high level of engagement! We recommend calling them immediately to close them on a new lease!
              </div>
              <div class="alert alert-warning" *ngIf="engagementScore > 1 && engagementScore <= 3">
                {{activeContact.contactIdentifier}} has shown some engagement. Try sending them another text, or reach out to them over the phone!
              </div>
              <div class="alert alert-danger" *ngIf="engagementScore <= 1">
                {{activeContact.contactIdentifier}} is not showing much engagement. Try sending them another text, or reach out to them over the phone!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row table-header">
        <div class="col-sm-3">Sent To</div>
        <div class="col-sm-3">Date Sent</div>
        <div class="col-sm-3">Delivery Status</div>
        <div class="col-sm-3">Link to Page Sent</div>
      </div>
      <div class="row table-data" *ngFor="let text of contactTexts">
        <div class="col-sm-3">
          {{text.to | phone}}
        </div>
        <div class="col-sm-3">
          {{text.created | date }}
        </div>
        <div class="col-sm-3">
          <div *ngIf="text.status === 'delivered'" class="status-success"></div>
          <div *ngIf="text.status === 'sent'" class="status-warn"></div>
          <div *ngIf="text.status === 'undelivered'" class="status-error"></div>
          {{text.status }}
        </div>
        <div class="col-sm-3">
          <span *ngIf="text.pageId === 'NONE'">No Page Sent</span>
          <a *ngIf="text.pageId !== 'NONE'" [href]="'https://sites.customnurturepages.com/?id=' + text.pageId + '&track=false'" target="_blank">Open/View Page</a>
        </div>
      </div>
    </div>
  </div>
</div>