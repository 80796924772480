<div>
  <div class="row">
    <div class="col-sm-3">
      <ul class="innder-side-nav">
        <li>
          <i class="far fa-credit-card"></i> <a routerLink="/settings/billing">Billing</a>
        </li>
        <li>
          <i class="far fa-user-circle"></i> <a routerLink="/settings/account">Account</a>
        </li>
        <li *ngIf="userData.claims.indexOf('real estate') === -1 || userData.claims.indexOf('admin') > -1">
          <i class="far fa-check-square"></i> <a routerLink="/settings/defaults">Defaults</a>
        </li>
        <li>
          <i class="far fa-file-contract"></i> <a routerLink="/settings/compliance">Compliance</a>
        </li>
        <li>
          <i class="far fa-sync-alt"></i> <a routerLink="/settings/integrations">Integrations</a>
        </li>
        <li>
          <i class="far fa-images"></i> <a routerLink="/settings/media">Media</a>
        </li>
      </ul>
    </div>
    <div class="col-sm-9">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
