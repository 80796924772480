import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelcase'
})
export class CamelCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    } else if (typeof value !== 'string') {
      return value;
    }
    var words = value.split(/(?=[A-Z])/);
    for (var i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(' ');
  }
}
