import { Component, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaService } from '@app/_services';

@Component({ 
    selector: 'app-media-modal', 
    templateUrl: 'media-modal.component.html', 
    styleUrls: ['media-modal.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class MediaModalComponent {

    media:Array<any> = [];
    loading:boolean = false;
    selectedImage: any;

    constructor(
      private activeModal: NgbActiveModal,
      private mediaService: MediaService
    ) {
      this.loading = true;
      this.mediaService.getAllMedia().subscribe((data) => {
        this.loading = false;
        this.media = data.result;
      });
    }

    // close modal
    exit(event): void {
      event.stopPropagation();
      this.activeModal.dismiss('Exit')
    }

    selectImage(image:any): void {
      this.selectedImage = image;
    }

    submitSelection(event): void {
      event.stopPropagation();
      this.activeModal.close(this.selectedImage)
    }
}