<div>
  <div class="modal-header">
    <h5 class="modal-title">Select From Media Manager</h5>
    <div class="float-right">
      <button [disabled]="!selectedImage" (click)="submitSelection($event)" class="button button-dark margin-right-12">
        Select Media
      </button>
      <button type="button" class="button button-white button-small" (click)="exit($event)"><i class="far fa-times"></i></button>
    </div>
  </div>
  <div class="modal-body">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    <div class="row" *ngIf="!media || media.length === 0">
      <h3 class="empty-media-holder">You don't have any media yet!<i class="far fa-folder-open"></i></h3>
    </div>
    <div class="row" *ngIf="!loading && media && media.length > 0">
      <div class="col-md-2 margin-bottom-24" *ngFor="let image of media; let i = index">
        <div [class.selected]="selectedImage && (selectedImage._id === image._id)" class="image-wrapper">
          <img (click)="selectImage(image)" class="image-preview" [src]="image.url" />
        </div>
        <h5 class="file-name truncate">{{image.fileName}}</h5>
        <span class="file-size">{{image.fileSize / 1000 | number:'1.0-0'}} KB</span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="!selectedImage" (click)="submitSelection($event)" class="button button-dark margin-right-12">
      Select Media
    </button>
    <button type="button" class="button button-white button-small" (click)="exit($event)"><i class="far fa-times"></i></button>
  </div>
</div>