import { Component, OnInit } from '@angular/core';
import { ToastService, TextMessagesService, LoaderService } from '@app/_services';
import * as _ from 'lodash';

@Component({
  templateUrl: 'tracking.component.html',
  styleUrls: ['tracking.component.less'],
  
})
export class TrackingComponent implements OnInit {
  searchText: string = '';
  showPageData: boolean = false;
  pages: any[] = [];
  messages: any[] = [];
  totalClicks: number = 0;
  totalDelivered: number = 0;
  totalRecords: any;
  labelSearchText: string = '';
  pageTypeSearchText: string = '';
  statusSearchText: string = '';
  debouncedSearch: any;
  activeQuery: any = {
    query: '',
    property: ''
  };
  pagination: any = {
    first: 1,
    last: 25
  };

  constructor(
    private toastService: ToastService,
    private textMessagesService: TextMessagesService,
    private loaderService: LoaderService
  ) {
    this.debouncedSearch = _.debounce(this.searchFunc, 300);
  }

  searchFunc(query, property) {
    this.activeQuery = {
      query: query,
      property: property
    }
    this.getTextMessageData(query, property);
  }

  ngOnInit() {
    this.getTextMessageData(null, null, true);
  }

  getTextMessageData(query?, property?, load?) {
    this.loaderService.triggerLoader();
    this.textMessagesService.getAll(0, 25, query, property).subscribe(messages => {
      this.messages = messages.result.data;
      this.totalRecords = messages.result.totalRecords;
      this.loaderService.stopLoader();
    }, (error) => {
      console.error('There was an error retrieving text message data: ', error);
      this.loaderService.stopLoader();
      this.toastService.show('There was an error loading your text message data', { classname: 'bg-danger text-light', delay: 5000 });
    });
  }

  paginate(event) {
    this.loaderService.triggerLoader();
    this.pagination.first = event.first;
    this.pagination.last = event.first + event.rows;
    this.textMessagesService.getAll(event.first, event.rows, this.activeQuery.query, this.activeQuery.property).subscribe(messages => {
      this.messages = messages.result.data;
      this.totalRecords = messages.result.totalRecords;
      this.loaderService.stopLoader();
    }, (error) => {
      console.error('There was an error retrieving your text message data: ', error);
      this.loaderService.stopLoader();
      this.toastService.show('There was an error loading your text message data', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
    });
  }
}
