import { Component } from '@angular/core';
import { Router } from '@angular/router';
  
@Component({
  templateUrl: 'template-picker.component.html',
  styleUrls: ['template-picker.component.less']
})
export class TemplatePickerComponent {
  currentUser: any;
  activeState = 'nurturepages';
  nonMultiPages: Array<string> = [
    'Master Nurture Template',
    'Digital Business Card',
    'Real Estate General Communication'
  ];

  constructor(
    private router: Router
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  createNewPage(pageType) {
    if (this.nonMultiPages.indexOf(pageType) > -1) {
      this.router.navigate(['/templates/new-general'], { queryParams: { pageType: pageType } });
    } else {
      this.router.navigate(['/templates/new'], { queryParams: { pageType: pageType } });
    }
  }

  bulkUploadNewPage(pageType) {
    if (this.nonMultiPages.indexOf(pageType) > -1) {
      this.router.navigate(['/templates/new-general'], { queryParams: { pageType: pageType, bulk: true } });
    } else {
      this.router.navigate(['/templates/new'], { queryParams: { pageType: pageType, bulk: true } });
    }
  }

  createNewEmail(pageType) {
    this.router.navigate(['/templates/new'], { queryParams: { pageType: pageType, email: true } });
  }

  bulkUploadNewEmail(pageType) {
    this.router.navigate(['/templates/new'], { queryParams: { pageType: pageType, email: true, bulk: true } });
  }
}
