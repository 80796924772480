import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class SubscriptionsService {

    constructor(
        private http: HttpClient,
        private apiHelper: APIHelperService
    ) {
        //no-op
    }

    subscribe(payload) {
        return this.http.post<any>(this.apiHelper.fillUrl('subscribes', {}, {}), payload)
            .pipe(map(subscription => {
                return subscription;
            }));
    }

    // MARKED FOR DELETION
    // cancelSubscription() {
    //     try {
    //         return this.http.put<any>(this.apiHelper.fillUrl('subscribeCancel', {}, {}), {})
    //             .pipe(map(subscription => {
    //                 return subscription;
    //             }));
    //     } catch (e) {
    //         console.error('Error running delte: ', e)
    //     }
    // }

    getSubscription() {
        return this.http.get<any>(this.apiHelper.fillUrl('subscribes', {}, {}))
            .pipe(map(subscription => {
                return subscription;
            }));
    }
    
    getInvoices() {
        return this.http.get<any>(this.apiHelper.fillUrl('invoices', {}, {}))
            .pipe(map(subscription => {
                return subscription;
            }));
    }
}