<div id="analytics">
  <div class="page-title">
      <!-- <div class="actions left">
          <i class="far fa-bars"></i>
      </div> -->
      <h2>Analytics</h2>
      <!-- <div class="actions right">
          <i class="far fa-search"></i>
      </div> -->
  </div>
  <form class="form-inline date-range-selection">
    <div class="form-group hide">
      <div class="input-group">
        <input name="datepicker"
               class="form-control"
               ngbDatepicker
               #datepicker="ngbDatepicker"
               [autoClose]="false"
               (dateSelect)="onDateSelection($event)"
               [displayMonths]="2"
               [dayTemplate]="t"
               [footerTemplate]="f"
               outsideDays="hidden"
               [startDate]="fromDate!">
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
        <ng-template #f>
            <button [disabled]="!fromDate || !toDate" (click)="triggerUpdate($event)" class="update-search button button-light">Update Search</button>
            <button (click)="close()" class="cancel-search button button-white">Cancel</button>
          </ng-template>
      </div>
    </div>
    <div class="form-group">
      <div class="input-group">
        <input #dpFromDate
               class="form-control" placeholder="mm-dd-yyyy"
               name="dpFromDate"
               [value]="formatter.format(fromDate)"
               (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button"><i class="fal fa-calendar-alt"></i></button>
        </div>
      </div>
    </div>
    <div class="form-group ml-2">
      <div class="input-group">
        <input #dpToDate
               class="form-control" placeholder="mm-dd-yyyy"
               name="dpToDate"
               [value]="formatter.format(toDate)"
               (input)="toDate = validateInput(toDate, dpToDate.value)">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button"><i class="fal fa-calendar-alt"></i></button>
        </div>
      </div>
    </div>
</form>
  <div [style.display]="loading" class="card mt-4">
    <button (click)="downloadReport()" class="download-report-button button button-dark">Export Report</button>
    <div class="card-body">
      <h4 class="card-header">Events Broken Down by Prospect</h4>
      <div class="chart-wrapper">
        <div class="chart-container" style="position: relative; width:100%; max-width: 100%">
          <canvas id="eventDetailsByProspect" #chartCanvas></canvas>
        </div>
      </div>
    </div>
  </div>
  <div [style.display]="loading" class="card mt-4">
    <div class="row card-body">
      <div class="col-sm-6">
        <h4 class="card-header">Total Events</h4>
        <div class="chart-wrapper">
          <div class="chart-container" style="position: relative; width:100%; max-width: 100%; min-height: 300px;">
            <canvas id="totalEventsCanvas" #chartCanvas></canvas>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <h4 class="card-header">Events by Top 10 Prospects</h4>
        <div class="chart-wrapper">
          <div class="chart-container" style="position: relative; width:100%; max-width: 100%">
            <canvas id="topTenProspectCanvas" #chartCanvas></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [style.display]="loading" class="card mt-4">
    <div class="row card-body">
      <div class="col-sm-12">
        <h4 class="card-header">Events by All Prospects</h4>
        <div class="card-body">
          <div class="chart-wrapper">
            <div class="chart-container" style="position: relative; width:100%; max-width: 100%">
              <canvas id="prospectCanvas" #chartCanvas></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>