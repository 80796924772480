import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { AuthenticationService, LoaderService, ToastService, WebsocketsService, TextMessagesService, UsersService } from './_services';
import {SwPush, SwUpdate} from '@angular/service-worker';

var self;

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
    currentUser: any;
    loaderActive: Boolean;
    longLoaderActive: Boolean;
    showTosUpdate: Boolean = false;
    noSideBarStates: Array<string> = [
        '/login',
        '/forgot-password',
        '/password-reset'
    ];
    unreadMessages: Number = 0;
		subscription;
		subscription2;
		unreadDictionary:any = {};
		listenersSet: boolean = false;
		favicon1: any;
		favicon2: any;
		favicon3: any;
		showMimicWarning: String;
		showUpdateUsageModal: Boolean = false;
		newUsageAmount: any = null;
		claimsData: any = [];

    constructor(
        public router: Router,
        private authenticationService: AuthenticationService,
        private loaderService: LoaderService,
        private toastService: ToastService,
        private websocketsService: WebsocketsService,
				private textMessagesService: TextMessagesService,
				private swPush: SwPush,
				private usersService: UsersService,
				private updates: SwUpdate
    ) {
			self = this;
			this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
			this.loaderService.loaderActive.subscribe((y) => {
				setTimeout(() => {
					this.loaderActive = y
				});
			});
			this.loaderService.longLoaderActive.subscribe(z => this.longLoaderActive = z);
			
			this.updates.available.subscribe(() => {
				this.updates.activateUpdate().then(() => {
					console.info('New version of app available, updating...');
					document.location.reload()
				});
			});

			// Connect to websocket if now connected.
			if (localStorage.getItem('currentUser')) {
				this.websocketsService.webSocketConnect();
			}

			// Check terms of service is agreed to.
			this.router.events.subscribe((e: RouterEvent) => {
				if (e instanceof NavigationEnd) {
						var user;
						if (localStorage.getItem('currentUser')) {
							user = JSON.parse(localStorage.getItem('currentUser')).user;
						}
						if (user) {
							self.claimsData = user.claims;
	
							// If two way messaging is enabled setup websocket.
							if (user.twilioAccountId && !this.listenersSet) {
								self.setUpMessageListeners(user._id)
							}

							// Should we show ToS to user?
							if (self.claimsData.indexOf('admin') === -1 && (!user.claimsAgreed || new Date(user.claimsAgreed) < new Date('Aug 6 2020'))) {
									self.showTosUpdate = true;
							} else if (self.claimsData.indexOf('admin') > -1) {
									self.showTosUpdate = false;
							}
							if (localStorage.getItem('mimic')) {
								self.showMimicWarning = localStorage.getItem('mimic');
							} else {
								self.showMimicWarning = false;
							}
						} else {
							self.showMimicWarning = false;
							self.claimsData = [];
							self.showTosUpdate = false;
							self.subscription.unsubscribe();
							self.subscription2.unsubscribe();
						}
				}
			});
		}

		showChangeUsage() {
			this.showUpdateUsageModal = !this.showUpdateUsageModal;
			this.newUsageAmount = null;
		}

		updateUsage() {
			this.usersService.updateUsage({
				amount: this.newUsageAmount
			}).subscribe(() => {
				this.showChangeUsage();
				this.newUsageAmount = null;
				this.toastService.show('Available usage updated!', {
					classname: 'bg-success text-light',
					delay: 5000
				});
			}, (err) => {
				console.error('Error updating available usage: ', err);
				this.showChangeUsage();
				this.newUsageAmount = null;
				this.toastService.show('There was an error updating available usage', { classname: 'bg-danger text-light', delay: 5000 });
			})
		}
		
		setUpMessageListeners(userId) {
			this.favicon1 = document.getElementById('favicon-small');
			this.favicon2 = document.getElementById('favicon-large');
			this.favicon3 = document.getElementById('apple-icon');
			if (!this.listenersSet) {
				this.listenersSet = true;

				// Get unread message cout.
				this.textMessagesService.getUnreadCount().subscribe((data) => {
	
					// Normalize Unread messages
					var unreadMessages = 0;
					for (var i = 0; i < data.result.length; i++) {
						this.unreadDictionary[data.result[i]._id] = data.result[i].read;
						if (!data.result[i].read) {
							unreadMessages++;
						}
					}
					this.unreadMessages = unreadMessages;

					// Dynamically set favicon based on unreadcount
					// TODO: Make DRY, used twice.
					if (unreadMessages > 0) {
						this.favicon1.href = "/assets/favicon-16x16-notify.png";
						this.favicon2.href = "/assets/favicon-32x32-notify.png";
						this.favicon3.href = "/assets/apple-touch-icon-notify.png";
					} else {
						this.favicon1.href = "/assets/favicon-16x16.png";
						this.favicon2.href = "/assets/favicon-32x32.png";
						this.favicon3.href = "/assets/apple-touch-icon.png";
					}
				}, (err) => {
					console.error('Could not receive unread message count! ', err);
				});
	
				// Listen for updates to unread count.
				self.textMessagesService.unreadMessageCount$.subscribe((message) => {
						if (message && message.conversationId) {
							this.unreadDictionary[message.conversationId] = !!message.read;
						}
	
						// TODO: This is duplicated, we need to optimize, make DRY.
						var unreadMessages = 0;
						for (var i in this.unreadDictionary) {
							if (!this.unreadDictionary[i]) {
								unreadMessages++;
							}
						}
						this.unreadMessages = unreadMessages;

						// Dynamically set favicon based on unreadcount
						if (unreadMessages > 0) {
							this.favicon1.href = "/assets/favicon-16x16-notify.png";
							this.favicon2.href = "/assets/favicon-32x32-notify.png";
							this.favicon3.href = "/assets/apple-touch-icon-notify.png";
						} else {
							this.favicon1.href = "/assets/favicon-16x16.png";
							this.favicon2.href = "/assets/favicon-32x32.png";
							this.favicon3.href = "/assets/apple-touch-icon.png";
						}
				});

				// Subscribe to push notifications
				this.swPush.requestSubscription({
					serverPublicKey: 'BN5fR-d_RIaFgnO2jzR0-uzaQerECUhiPdv1qOy8sgYseWYtAXgI_kR1JKTtTrn9w8n6ONc67UT-NAvmTtdAZ3g'
				}).
						then((sub) => {
							this.usersService.updateUser(userId, {browserPushData: sub}).subscribe();
						}).
						catch((err) => {
							console.error("Could not subscribe to notifications", err)
						});
			}
		}

    regexIndexOf(array, string) {
        for (var i = 0; i < array.length; i++) {
            var indexOf = string.search(new RegExp(array[i]));
            if (indexOf >= 0) {
                return indexOf;
            }
        }
        return -1;
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);

        //TODO: Kill websocket connection.
    }

    agreeToTerms() {
        this.authenticationService.agreeToTerms().subscribe((data) => {
            var userObj = JSON.parse(localStorage.getItem('currentUser'));
            userObj.user.claimsAgreed = data.result;
            localStorage.setItem('currentUser', JSON.stringify(userObj));
            this.showTosUpdate = false;
        }, ()=> {
            console.error('There was an error, please contact support.');
            this.toastService.show('There was an error, please contact support@nurtureboss.io', { classname: 'bg-danger text-light', delay: 5000 })
        });
    }

    ngOnInit() {
			this.subscription = this.websocketsService.message$.subscribe((newMessage) => {

				// If we are on messages screen don't do anything, messages component will handle.
				if (this.router && this.router.url.indexOf('messages') === -1) {
					if (newMessage && newMessage._id) {
						this.textMessagesService.updateUnreadCountInternally(newMessage);
					}
				}
			});
			this.subscription2 = this.websocketsService.message2$.subscribe((newMessage2) => {

				// If we are on messages screen don't do anything, messages component will handle.
				if (this.router && this.router.url.indexOf('messages') === -1) {
					if (newMessage2 && newMessage2._id) {
						this.textMessagesService.updateUnreadCountInternally(newMessage2);
					}
				}
			});
    }

    ngOnDestroy() {

			// prevent memory leak when component is destroyed
			this.subscription.unsubscribe();
			this.subscription2.unsubscribe();
    }
}