<div>
  <div>
    <p *ngIf="!activeStep" class="step-instructions">Upload your spreadsheet to get started!</p>
    <label  *ngIf="!activeStep" class="button button-white" for="file"><i class="fal fa-file-search"></i> Select file</label>
    <input  *ngIf="!activeStep" type="file" class="bulk-upload-input" id="file" value="" accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
    <span *ngIf="activeFileName"><i (click)="refresh()" class="fal fa-trash-alt"></i> {{activeFileName}}</span>
    <button class="button button-light pull-right margin-left-12" *ngIf="stepOptional" (click)="nextStep($event, 'skip')"> Skip</button>
    <button class="button button-dark pull-right" *ngIf="stepSuccess" (click)="nextStep($event)"> Continue</button>
    <div *ngIf="working">Working...<div class="spinner-border spinner-border-sm"></div></div>
    <div class="margin-top-24 instructions-alert" [class.hidden]="!activeFileName">
      <p *ngIf="activeStep === 0" class="step-instructions"><strong>Step 1:</strong> Which sheet from your workbook would you like to use?</p>
      <div id="buttons" [hidden]="activeStep !== 0"></div>
      <p *ngIf="activeStep === 1" class="step-instructions"><strong>Step 2:</strong> Select the <strong>first row</strong> where your data begins...</p>
      <p *ngIf="activeStep === 2" class="step-instructions"><strong>Step 3:</strong> Select the <strong>last row</strong> where your data ends...</p>
      <p *ngIf="activeStep > 2" class="step-instructions"><strong>Step {{activeStep}}:</strong> Select the <strong>column</strong> for <strong class="purple">{{activeFieldName | camelcase}}...
        <span *ngIf="requiredFields[activeIndex] === 'pageName'">&nbsp;We recommend using Full Name, Email, Phone Number, or Last Name so you can identify who the page belongs to!</span></strong>
      </p>
      <p *ngIf="activeStep === 1 && selectedFirstRow" class="alert alert-success-light step-instructions">Great! You picked <strong>row {{selectedFirstRow}}</strong></p>
      <p *ngIf="activeStep === 2 && selectedLastRow" class="alert alert-success-light step-instructions">Great! You picked <strong>row {{selectedLastRow}}</strong></p>
      <p *ngIf="activeStep > 2 && selectedColumn" class="alert alert-success-light step-instructions">Great! You picked <strong>column {{selectedColumn}}</strong> <span *ngIf="splitOnSpace">&nbsp;and we will <strong>split this cell on a "space"</strong></span></p>
    </div>
    <div class="alert alert-danger" *ngIf="stepError">{{stepError}}</div>
    <div [class.hidden]="!activeFileName" id="grid"></div>
  </div>
</div>