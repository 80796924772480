import { Component} from '@angular/core';
import { AuthenticationService, ToastService, LoaderService, ContactsService } from '@app/_services';
import { ActivatedRoute, Router } from '@angular/router';

var self;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less']
})
export class ContactComponent {

  loading: Boolean = false;
  userData: any;
  activeContact: any = {
    phoneNumber: 0
  };
  activeContactId: String;
  contactsPages: any;
  contactTexts: any;
  engagementScore: any;
  backTo: any;
  integrationActive: Boolean = false;

  constructor(
    private authService: AuthenticationService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private contactsService: ContactsService
  ) {
      this.userData = this.authService.currentUserValue.user;
      this.integrationActive = (
        this.userData.claims.indexOf('realpage') > -1 ||
        this.userData.claims.indexOf('yardi') > -1 ||
        this.userData.claims.indexOf('ilm') > -1
      );
      self = this;

      this.activatedRoute.queryParams.subscribe(params => {
        this.activeContactId = params['id'];
        this.engagementScore = params['engagementScore'];
        this.backTo = params['backTo'];
        if (!this.activeContactId) {
          this.router.navigate(['dashboard']);
        } else {
          this.loadData(this.activeContactId);
        }
      });

      // Get contacts pages.
      this.contactsService.getContactsPages(this.activeContactId).subscribe(data => {
        this.contactsPages = data.result.sort((a, b) => {
          return +new Date(b.created) - +new Date(a.created);
        });
      });

      // Get contacts texts.
      this.contactsService.getContactsTexts(this.activeContactId).subscribe(data => {
        this.contactTexts = data.result.sort((a, b) => {
          return +new Date(b.created) - +new Date(a.created);
        });
      });
  }

  loadData(id) {
    this.loading = true;
    this.loaderService.triggerLoader();
    this.contactsService.getContact(id).subscribe((contact) => {
      this.activeContact = contact.result;
      this.loaderService.stopLoader();
    }, (err) => {
      console.error('There was an error loading contact data! ', err);
      this.loaderService.stopLoader();
      this.toastService.show('There was an error loading contact data', { classname: 'bg-danger text-light', delay: 5000 });
    });
  }

  backToContacts(e) {
    e.preventDefault();
    if (this.backTo) {
      this.router.navigate(['/contacts']);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  updateContact() {
    this.contactsService.updateContact(this.activeContact._id, this.activeContact).subscribe(() => {
      this.toastService.show('Contact data updated!', { classname: 'bg-success text-light', delay: 5000 });
    }, (err) => {
      this.toastService.show('There was an error updating contact data', { classname: 'bg-danger text-light', delay: 5000 });
    })
  }
}