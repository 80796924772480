<div *ngIf="userData.user.claims && userData.user.claims.indexOf('admin') > -1">
  <div class="page-title">
    <h2>Admin</h2>
  </div>
  
  <div class="page">
    <div class="margin-bottom-24 row">
      <div class="col-md-6">
        <h6 class="title">Create New Account</h6>
        <div>
          <div class="row no-margin">
            <div class="col-md3">
              <div class="button button-dark margin-bottom-24 margin-top-12" (click)="openCreateAccountModal(newAccountModal)">Create Account</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <h6 class="title">Onboarding Emails</h6>
        <div>
          <div class="row no-margin">
            <div class="col-md3">
              <div class="button button-light margin-bottom-24 margin-top-12" (click)="openModal(onboardingEmailConfirm, true)">Onboarding Invite</div>
            </div>
          </div>
          <div class="row no-margin">
            <div class="col-md3">
              <div class="button button-light margin-bottom-24 margin-top-12" (click)="openModal(onboardingFollowUpEmailConfirm, false)">Onboarding Follow Up</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="page">
    <div class="margin-bottom-24">
      <h6 class="title">Mimic Accounts</h6>
      <div>
        <div>
          <h6>Select Account:</h6>
          <div class="form-group">
            <select class="form-control" [(ngModel)]="selectedAccount" [ngModelOptions]="{standalone: true}" id="exampleFormControlSelect1">
              <option *ngFor="let account of accounts" [ngValue]="account" >{{account.propertyName}}</option>
            </select>
          </div>
          <div class="button button-danger margin-bottom-24 margin-top-12" (click)="mimic(selectedAccount)" *ngIf="selectedAccount">Mimic Account</div>
        </div>
      </div>
    </div>
  </div>

  <div class="page">
    <div class="margin-bottom-24">
      <h6 class="title">Current Users Emails</h6>
      <div>
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Email Address" [(ngModel)]="userData.user.email">
        </div>
        <button class="button button-light margin-top-24" [disabled]="saving" (click)="updateUserEmail()">Update User Email</button>
      </div>
    </div>
  </div>

  <div class="page">
    <div class="margin-bottom-24">
      <h6 class="title">Current Users Notification Emails</h6>
      <div>
        <p-chips [(ngModel)]="userData.user.notifications"></p-chips>
        <button class="button button-light margin-top-24" [disabled]="saving" (click)="updateNotificationEmails()">Update Notification Emails</button>
      </div>
    </div>
  </div>

  
  <ng-template #newAccountModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Create A New Account</h5>
    </div>
    <div class="modal-body">
      <div *ngIf="createAccountStep === 1">
        <div class="form-group">
          <label>Property Name</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.propertyName" [ngModelOptions]="{standalone: true}" id="propertyName" aria-describedby="propertyName" placeholder="Enter property name">
        </div>
        <div class="form-group">
          <label>Legal Entity Name</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.legalEntityName" [ngModelOptions]="{standalone: true}" id="legalEntityName" aria-describedby="legalEntityName" placeholder="Enter legal entity name">
        </div>
        <div class="form-group">
          <label>Email Address</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.emailAddress" [ngModelOptions]="{standalone: true}" id="emailAddress" aria-describedby="emailAddress" placeholder="Enter email address">
        </div>
        <div class="form-group">
          <label>Property Address</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.propertyAddress" [ngModelOptions]="{standalone: true}" id="propertyAddress" aria-describedby="propertyAddress" placeholder="Enter property address">
        </div>
        <div class="form-group">
          <label>Timezone</label>
          <select class="form-control" [(ngModel)]="newAccountInfo.timezone" [ngModelOptions]="{standalone: true}">
            <option value="America/Los_Angeles">America/Los_Angeles (Pacific)</option>
            <option value="America/Phoenix">America/Phoenix</option>
            <option value="America/Denver">America/Denver (Mountain)</option>
            <option value="America/Chicago">America/Chicago (Central)</option>
            <option value="America/New_York">America/New_York (Eastern)</option>
          </select>
        </div>
        <div class="form-group">
          <label>Unit Count</label>
          <input type="text" class="form-control" [(ngModel)]="newAccountInfo.unitCount" [ngModelOptions]="{standalone: true}" id="unitCount" aria-describedby="unitCount" placeholder="Enter unit count">
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" [(ngModel)]="hasYardiIntegration" [ngModelOptions]="{standalone: true}" id="yardiIntegration">
          <label class="form-check-label" for="flexCheckDefault">
            Yardi Integration
          </label>
        </div>
        <div *ngIf="hasYardiIntegration">
          <div class="form-group">
            <label>Property ID</label>
            <input type="text" class="form-control" [(ngModel)]="newAccountInfo.yardiPropertyId" [ngModelOptions]="{standalone: true}" id="yardiPropertyId" aria-describedby="yardiPropertyId" placeholder="Enter property ID">
          </div>
          <div class="form-group">
            <label>Yardi Server</label>
            <input type="text" class="form-control" [(ngModel)]="newAccountInfo.yardiServer" [ngModelOptions]="{standalone: true}" id="yardiServer" aria-describedby="yardiServer" placeholder="Enter yardi server">
          </div>
          <div class="form-group">
            <label>Yardi Database</label>
            <input type="text" class="form-control" [(ngModel)]="newAccountInfo.yardiDatabase" [ngModelOptions]="{standalone: true}" id="yardiDatabase" aria-describedby="yardiDatabase" placeholder="Enter yardi database">
          </div>
          <div class="form-group">
            <label>Yardi Username</label>
            <input type="text" class="form-control" [(ngModel)]="newAccountInfo.yardiUsername" [ngModelOptions]="{standalone: true}" id="yardiUsername" aria-describedby="yardiUsername" placeholder="Enter yardi username">
          </div>
          <div class="form-group">
            <label>Yardi Password</label>
            <input type="text" class="form-control" [(ngModel)]="newAccountInfo.yardiPassword" [ngModelOptions]="{standalone: true}" id="yardiPassword" aria-describedby="yardiPassword" placeholder="Enter yardi password">
          </div>
          <div class="form-group">
            <label>Yardi URL</label>
            <input type="text" class="form-control" [(ngModel)]="newAccountInfo.yardiWSDLUrl" [ngModelOptions]="{standalone: true}" id="yardiWSDLUrl" aria-describedby="yardiWSDLUrl" placeholder="Do not include /webservices/itfilsguestcard.asmx?wsdl">
          </div>
        </div>
      </div>
      <div *ngIf="createAccountStep === 1.5">
        <div>
          <h6 class="title">TCPA Compliant Sources</h6>
          <div>
            <div *ngIf="!loading">
              <ng-multiselect-dropdown
                [placeholder]="'Select compliant sources'"
                [settings]="dropdownSettings"
                [data]="sources"
                [(ngModel)]="storedSources"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
        <div>
          <div class="margin-top-24">
            <h6 class="title">Select Agent To Represent Nurture Boss Activity:</h6>
            <div class="form-group">
              <select class="form-control" [(ngModel)]="userBeingCreated.yardiAgentName" [ngModelOptions]="{standalone: true}">
                <option *ngFor="let agent of yardiAgents" [ngValue]="agent" >{{agent}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="createAccountStep === 2">
        <div class="form-group">
          <label>Select A Plan</label>
          <select class="form-control" [(ngModel)]="selectedPlan" [ngModelOptions]="{standalone: true}" id="selectedPlan">
            <option *ngFor="let plan of plans" [ngValue]="plan" >{{plan.nickname}}</option>
          </select>
        </div>
      </div>
      <div *ngIf="createAccountStep === 3">
        <div class="form-group">
          <label>Setup Defaults</label>
          <div class="alert alert-success">
            User and subscription created. Set up template defaults.
          </div>
        </div>
      </div>
      <div *ngIf="createUserError" class="alert alert-danger">{{createUserError}}</div>
      <button (click)="createAccountNextStep(modal)" [disabled]="createUserBusy" class="margin-top-48 button button-light">
          <span *ngIf="createUserBusy" class="spinner-border spinner-border-sm mr-1"></span>
          Next
      </button>
      <button (click)="modal.dismiss()" [disabled]="createUserBusy" class="button button-white margin-top-12 margin-left-12">
        Exit
      </button>
    </div>
  </ng-template>

  <ng-template #onboardingEmailConfirm let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Send Onboarding Email</h5>
    </div>
    <div class="modal-body">
      <p>You are about to send an onboarding email to <strong>{{userData.user.email}}</strong>!</p>
      <button (click)="sendOnboardingEmail(modal)" [disabled]="saving" class="margin-top-48 button button-dark">
          <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
          Send
      </button>
      <button (click)="modal.dismiss()" [disabled]="saving" class="button button-white margin-top-12 margin-left-12">
        Cancel
      </button>
    </div>
  </ng-template>

  <ng-template #onboardingFollowUpEmailConfirm let-modal>
    <div class="modal-header">
        <h5 class="modal-title">Send Onboarding Follow Up Email</h5>
    </div>
    <div class="modal-body">
      <p>You are about to send a follow up onboarding email to <strong>{{userData.user.email}}</strong>!</p>
      <button (click)="sendOnboardingFollowUpEmail(modal)" [disabled]="saving" class="margin-top-48 button button-dark">
          <span *ngIf="saving" class="spinner-border spinner-border-sm mr-1"></span>
          Send
      </button>
      <button (click)="modal.dismiss()" [disabled]="saving" class="button button-white margin-top-12 margin-left-12">
        Cancel
      </button>
    </div>
  </ng-template>
</div>