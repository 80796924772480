import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PagesService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  createPage(pageData) {
    return this.http.post<any>(this.apiHelper.fillUrl('pages', {}, {}), pageData)
      .pipe(map(data => {
        return data;
      }), catchError(e => {
        return of({error: e})
      })
    );
  }

  getAll(skip, take, query?, property?, showAllPages?) {

    // Note: Assume Show All Pages is always passed.
    var payload:any = {
      skip: skip,
      take: take,
      showAllPages: showAllPages
    };
    if (query && property) {
      payload.query = query;
      payload.property = property;
    }
    return this.http.get<any>(this.apiHelper.fillUrl('pages', {}, payload))
      .pipe(map(data => {
        return data;
      }));
  }

  sendBulkText(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('textMessagesBulk', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  getPage(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('singlePage', { id }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  updatePage(id, data) {
    return this.http.put<any>(this.apiHelper.fillUrl('singlePage', { id }, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  deletePage(id) {
    return this.http.delete<any>(this.apiHelper.fillUrl('singlePage', { id }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  retrieveSchedules() {
    return this.http.get<any>(this.apiHelper.fillUrl('schedules', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }
}
