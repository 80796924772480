import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService, ToastService } from '@app/_services';

@Component({
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.less']
})
export class PasswordResetComponent implements OnInit {
  passwordResetForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  token: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toastService: ToastService
  ) {
    // no-op
  }

  ngOnInit() {
    this.passwordResetForm = this.formBuilder.group({
      password: ['', Validators.required],
      passwordMatch: ['', Validators.required]
    });
    this.route.queryParamMap.subscribe(queryParams => {
      this.token = queryParams.get("token")
      if (!this.token) {
        this.toastService.show('Invalid reset link, please try again.', {
          classname: 'bg-danger text-light',
          delay: 5000
        });
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.passwordResetForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.error = null;
    // stop here if form is invalid
    if (this.f.password.value !== this.f.passwordMatch.value) {
      this.f.passwordMatch.setErrors({ 'noMatch': true });
      console.error('Please enter matching passwords');
      return;
    }
    if (this.passwordResetForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.updatePassword(this.f.password.value, this.token)
    .subscribe(() => {
      this.loading = false;
      this.toastService.show('Password reset! Proceeding to login.', {
        classname: 'bg-success text-light',
        delay: 5000
      });
      setTimeout(() => {
        this.router.navigate(['/login']);
      }, 2000)
    },
    () => {
      this.error = 'Error resetting password, please try again.';
      this.loading = false;
    });
  }
}
