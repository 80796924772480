import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class TemplatesService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  getTemplateDefaults(templateName) {
    return this.http.get<any>(this.apiHelper.fillUrl('templateDefault', { templateName }, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  updateTemplateDefaults(templateName, data) {
    return this.http.put<any>(this.apiHelper.fillUrl('templateDefault', { templateName }, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  createTemplateDefaults(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('templateDefaults', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  newTextTemplate(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('textTemplates', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  getTextTemplates() {
    return this.http.get<any>(this.apiHelper.fillUrl('textTemplates', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  deleteTextTemplate(id) {
    return this.http.delete<any>(this.apiHelper.fillUrl('textTemplate', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }
}