import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EmailsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  createEmail(emailData) {
    return this.http.post<any>(this.apiHelper.fillUrl('emails', {}, {}), emailData)
      .pipe(map(data => {
        return data;
      }), catchError(e => {
        return of({error: e})
      })
    );
  }

  sendEmail(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('sendEmail', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  sendEmailTemplate(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('sendEmailTemplate', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  sendBulkEmails(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('sendBulkEmails', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  scheduleEmailSend(payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('scheduleEmail', {}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }
}
