import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TemplatesService, PageTypesService, ToastService, UsersService, AuthenticationService, LabelsService, MediaService, PagesService, LoaderService, RealPageService, SubscriptionsService, ContactsService } from '@app/_services';
import { MediaModalComponent } from '@app/modals';
import { NgbCalendar, NgbDate, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgWizardConfig, THEME, NgWizardService } from 'ng-wizard';
import { forkJoin, Observable } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { EmailsService } from '@app/_services/emails.service';
import { Table } from 'primeng/table';
import {FilterUtils} from 'primeng/utils';

var self;

@Component({
  templateUrl: 'template-create.component.html',
  styleUrls: ['template-create.component.less']
})
export class TemplateCreateComponent implements OnInit {

  @ViewChild('auto') auto;
  @ViewChild("content") modalContent: TemplateRef<any>;
  @ViewChild('sendEmailModal') private sendEmailModal: TemplateRef<any>;
  @ViewChild('dt') table: Table;

  observableOperations$: Array < Observable < any >> = [];
  changeDetection: any;
  newPageForm: FormGroup;
  userDefaults: any = {};
  dynamicModal: any;
  defaultUpdated: any = {};
  defaultAdded: any = {};
  defaultRemoved: any = {};
  userData: any;
  labelData: Array<any>;
  createPageLoading: boolean = false;
  createPageError: string = '';
  loadingImage: any = {};
  currentLabelSearch: string;
  loading: boolean = true;
  pageType: string;
  modalOptions: NgbModalOptions;
  errorModalOptions: NgbModalOptions;
  emailModalOptions: NgbModalOptions;
  reader: FileReader;
  activeTypeRequiredFields: Array < string > = [];
  uploadDisabled: boolean = true;
  bulkUpload: boolean = false;
  apartmentTourRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'onlineApplicationUrl',
    'clientFirstName',
    'yourName',
    'yourWebsite',
    'yourEmailAddress',
    'yourPhoneNumber'
  ];
  guestCardRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'onlineApplicationUrl',
    'clientFirstName',
    'yourName',
    'yourEmailAddress',
    'yourPhoneNumber',
    'yourWebsite'
  ];
  apartmentConcessionRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'onlineApplicationUrl',
    'clientFirstName',
    'concession',
    'yourWebsite',
    'yourEmailAddress',
    'yourPhoneNumber'
  ];
  apartmentLeaseRenewalRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'yourWebsite',
    'yourName',
    'yourEmailAddress',
    'yourPhoneNumber'
  ];
  paseoRenewalRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'yourWebsite',
    'yourName',
    'yourEmailAddress',
    'yourPhoneNumber',
    'residentPortalUrl'
  ];
  apartmentMaintenanceFollowUpRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'yourWebsite',
    'yourName',
    'yourEmailAddress',
    'yourPhoneNumber'
  ];
  moveInChecklistRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'yourName',
    'yourWebsite',
    'yourEmailAddress',
    'yourPhoneNumber',
    'moveInDate',
    'apartmentNumber'
  ];
  eventReminderRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'yourName',
    'yourWebsite',
    'yourEmailAddress',
    'yourPhoneNumber',
    'eventName',
    'eventDate',
    'eventTime',
    'eventLocation'
  ];
  theRoyceRentReminderRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'yourName',
    'yourWebsite',
    'yourEmailAddress',
    'yourPhoneNumber'
  ];
  rentReminderRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'yourName',
    'yourWebsite',
    'yourEmailAddress',
    'yourPhoneNumber',
    'residentPortalUrl'
  ];
  apartmentAppointmentReminderRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'yourName',
    'yourWebsite',
    'yourEmailAddress',
    'yourPhoneNumber',
    'appointmentDate',
    'appointmentTime'
  ];
  apartmentInterestListRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'mainText',
    'yourName',
    'yourEmailAddress',
    'yourPhoneNumber',
    'yourWebsite'
  ];
  apartmentCovidScreeningRequiredFields: Array<String> = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'yourName',
    'yourEmailAddress',
    'yourPhoneNumber',
    'yourWebsite'
  ];
  harperSelfGuidedTourRequiredFields: Array<String> = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'yourName',
    'yourEmailAddress',
    'yourPhoneNumber',
    'yourWebsite'
  ];
  apartmentAcceptingApplicationsRequiredFields: Array<String> = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'onlineApplicationUrl',
    'clientFirstName',
    'yourName',
    'yourEmailAddress',
    'yourPhoneNumber',
    'yourWebsite'
  ];
  harperMoveInChecklistRequiredFields: Array < String > = [
    'pageName',
    'bannerImage',
    'leftLogo',
    'clientFirstName',
    'yourName',
    'yourWebsite',
    'yourEmailAddress',
    'yourPhoneNumber',
    'moveInDate',
    'apartmentNumber'
  ];
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.dots,
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    },
    anchorSettings: {
      anchorClickable: false
    }
  };
  pageTypeToTemplate: any = {
    'Apartment Tour': 'apartment-tour-template',
    'Guest Card': 'guest-card-template',
    'Apartment Concession': 'apartment-concession-template',
    'SFS Federal': 'sfs-federal-template',
    'Apartment Renewal': 'apartment-lease-renewal-template',
    'Paseo Renewal': 'paseo-renewal-template',
    'Apartment Maintenance Followup': 'apartment-maintenance-followup-template',
    'Master Nurture Template': 'master-nurture-template',
    'Move In Checklist': 'move-in-checklist-template',
    'Apartment Event Reminder': 'event-reminder-template',
    'The Royce Rent Reminder': 'the-royce-rent-reminder-template',
    'Bella Posta Remodel Relocation': 'bella-posta-remodel-relocation-template',
    'Rent Reminder': 'rent-reminder-template',
    'Revere Rent Reminder': 'rent-reminder-template',
    'Apartment Appointment Reminder': 'apartment-appointment-reminder-template',
    'Apartment Interest List': 'apartment-interest-list-template',
    'Apartment Covid Screening': 'covid-screening-template',
    'Harper Self Guided Tour': 'harper-self-guided-tour-template',
    'Harper Move In Checklist': 'harper-move-in-checklist-template',
    'Apartment Accepting Applications': 'apartment-accepting-applications-template'
  };
  uploadSelection: any = null;
  fileUploaded: any;
  parserConfig: any;
  isUploading: boolean = false;
  bulkUploadError: any = false;
  requiredDefaults: any;
  requiredFields: any;
  dataForUpload: any = [];
  sampleData: any;
  disableAll: boolean = false;
  pmsIntegrationData: Array<any>;
  pmsIntegrationDataToShow: Array<any>;
  propertyStatusMapForRealPage:any = {
    S000000001: 'new',
    S000000002: 'active',
    S000000003: 'inactive',
    S000000004: 'lost',
    S000000005: 'leased',
    S000000006: 'future',
    S000000007: 'unqualified'
  };
  realPageActive: boolean = false;
  ilmActive: boolean = false;
  loopingInterval: any;
  activeJob: any;
  intervalCheckCount: number = 0;
  hasPmsIntegrationDataRequest: boolean = false;
  optionalFields: any;
  skippedPages: number;
  pagesRemaining: number;
  pageCreateError: string;
  yardiActive: boolean = false;
  isEmailTemplate: boolean = false;
  sendEmailForm: FormGroup;
  errorMessage: any = null;
  sendingEmail: boolean = false;
  emailsCreated: any;
  bulkEmail: boolean = false;
  sendDate: any;
  sendTime: any;
  schedSaveInProgress: boolean = false;
  bulkEmailsToSend: any;
  sampleEmailData: any;
  statusFilter: any;
  sourcesFilter: any = {};
  optInFilter: any = {};
  duplicatesRemoved = 0;
  selectedRows: any = [];
  cols: any = [];
  sourceFilterOptions:any = [];
  optInFilterOptions: any = [];
  statusFilterOptions: any = [];
  stageFilterOptions = [{
    value: 'Pre Tour',
    label: 'Pre Tour',

    },
    {
      value: 'Pre Application',
      label: 'Pre Application'
    },
    {
      value: 'Pre Move In',
      label: 'Pre Move In'
    },
    {
      value: 'Leasing/Leased',
      label: 'Leasing/Leased'
    }
  ];
  rangeDates: any = [];
  createRangeDates: any = [];
  pagination: any = {
    first: 1,
    last: 25
  };
  totalRecords = 0;
  selectedProspects: any = [];
  remainingCharacterCount = function() {
    return 60;
  };
  objectKeys = Object.keys;
  defaultMessageDict: any = {
    'Guest Card': '[apartmentName] has new availability and rates!',
    'Apartment Concession': '[apartmentName] is offering [concession]!',
    'Apartment Interest List': 'Join the [apartmentName] VIP list!'
  };
  tokenSamples: any;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  marketingTemplates = [
    'Apartment Interest List',
    'Apartment Tour',
    'Guest Card',
    'Apartment Accepting Applications',
    'Apartment Concession',
    'Apartment Renewal'
  ];
  isAdmin: boolean = false;

  constructor(
    private toastService: ToastService,
    private templatesService: TemplatesService,
    private activatedRoute: ActivatedRoute,
    private pageTypesService: PageTypesService,
    private usersService: UsersService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    private labelsService: LabelsService,
    public mediaService: MediaService,
    private modalService: NgbModal,
    private pagesService: PagesService,
    private loaderService: LoaderService,
    private router: Router,
    private ngWizardService: NgWizardService,
    private papa: Papa,
    private realPageService: RealPageService,
    private subscriptionService: SubscriptionsService,
    private contactsService: ContactsService,
    private emailsService: EmailsService,
    private calendar: NgbCalendar,
  ) {
    
    // Bulk Upload Logic
    // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    self = this;
    this.changeDetection = _.debounce(this.debouncedChangeDetector, 200);
    this.modalOptions = { windowClass : 'custom-modal-styles-new'};
    this.emailModalOptions = { windowClass : 'custom-modal-styles-new email-modal'};
    this.errorModalOptions = { windowClass: 'custom-modal-styles-new error-modal'};
    this.fromDate = this.calendar.getPrev(calendar.getToday(), 'd', 30);
    this.toDate = this.calendar.getToday();
  }

  // Capture the page type we are creating!
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
        this.pageType = params['pageType'];
        this.isEmailTemplate = params['email'];
        if (!this.pageType) {
          this.router.navigate(['templates']);
        } else  {
          this.dynamicModal = this.pageTypesService.loadModalData(this.pageType, this.isEmailTemplate);
          this.loadData();
          this.loadLabels();
        }
        if (params['bulk']) {
          this.bulkUpload = true;
          this.reader = new FileReader();
        }
    });
    this.subscriptionService.getSubscription().subscribe((data) => {
      this.pagesRemaining = data.result.remainingUsage;
    }, (err) => {
      console.error('There was an error retrieving subscription data: ', err);
    });

    // Custom filter for p-table date ranges.
    FilterUtils['dateRangeFilter'] = (value, filter): boolean => {
      if (filter.length === 2) { 
        var startDate = new Date(filter[0]).getTime();
        var endDate = new Date(filter[1]).getTime();
        return (new Date(value).getTime() < endDate && new Date(value).getTime() > startDate);
      } else {
        return false;
      }
    }
  } 

  debouncedChangeDetector(e, name) {    
    if (this.newPageForm.controls[name].value.length === 0) {
      this.userDefaults[name] = null;
      this.templatesService.updateTemplateDefaults(this.dynamicModal.pageType, this.userDefaults).subscribe((defaults) => {
        this.userDefaults = defaults.result;
        this.showHideDefaultUpdated(name, 'defaultRemoved');
      }, (err) => {
        console.error('There was an error saving template defaults: ', err);
        this.toastService.show('There was an error saving template defaults', { classname: 'bg-danger text-light', delay: 5000 });
      });
    } else if (this.newPageForm.controls[name].value.length > 1 && this.userDefaults[name]) {
      this.userDefaults[name] = this.newPageForm.controls[name].value;
      this.templatesService.updateTemplateDefaults(this.dynamicModal.pageType, this.userDefaults).subscribe((defaults) => {
        this.userDefaults = defaults.result;
        this.showHideDefaultUpdated(name, 'defaultUpdated');
      }, (err) => {
        console.error('There was an error saving template defaults: ', err);
        this.toastService.show('There was an error saving template defaults', { classname: 'bg-danger text-light', delay: 5000 });
      });
    }
  }

  showHideDefaultUpdated(name, type) {

    // Set all messages to false first.
    this.defaultRemoved = {};
    this.defaultAdded = {};
    this.defaultUpdated = {};
    this[type][name] = true;
    setTimeout(() => {
      this[type][name] = false;
    }, 3000);
  }

  loadData() {
    this.loaderService.triggerLoader();

    // Local user data
    var localUserData = this.authService.currentUserValue.user;

    // Load user data for apartment name.
    this.usersService.getUserData(this.authService.currentUserValue.user._id).subscribe((user) => {
      this.userData = user.result;
      this.realPageActive = this.userData.claims.indexOf('realpage') > -1;
      this.yardiActive = this.userData.claims.indexOf('yardi') > -1;
      this.ilmActive = this.userData.claims.indexOf('ilm') > -1;
      this.isAdmin = localUserData.claims.indexOf('admin') > -1;
      this.templatesService.getTemplateDefaults(this.dynamicModal.pageType).subscribe((response) => {
  
        // If no defaults exists, create them.
        if (response.result.length === 0) {
          this.userDefaults = {};
          this.userDefaults.templateName = this.dynamicModal.pageType;
          this.templatesService.createTemplateDefaults(this.userDefaults).subscribe((defaults) => {
            this.userDefaults = defaults.result;
          }, (err) => {
            console.error('There was an error saving template defaults: ', err);
            this.loaderService.stopLoader();
            this.toastService.show('There was an error saving template defaults', { classname: 'bg-danger text-light', delay: 5000 });
          });
        } else {
          this.userDefaults = response.result;
        }
        this.initiateForm();
      }, (e) => {
        this.loaderService.stopLoader();
        console.error('Error retrieving template defalts ', e);
        this.loading = false;
      });
    }, (e) => {
      this.loaderService.stopLoader();
      console.error('Error retrieving user data ', e);
      this.loading = false;
    });
  }

  initiateForm() {

    // Default value all pages require.
    var formGroup:any = {
      label: [''],
      pageName: ['', Validators.required],
      bannerImage: [this.userDefaults.bannerImage || '', Validators.required],
      leftLogo: [this.userDefaults.leftLogo || '', Validators.required],
      apartmentName: [this.userData.propertyName, Validators.required],
      yourWebsite: [this.userDefaults.yourWebsite || '', Validators.required],
      yourPhoneNumber: [this.userDefaults.yourPhoneNumber || '', Validators.required],
      yourEmailAddress: [this.userDefaults.yourEmailAddress || '', Validators.required],
      yourName: [this.userDefaults.yourName || '', Validators.required],
      footerContent: [this.userDefaults.footerContent || ''],
      type: ['', Validators.required],
    };

    // Special logic for concession offering
    if (this.dynamicModal.pageType === 'Apartment Concession') {
      formGroup.concession = [this.userDefaults.concession || '', Validators.required];
    }

    // Special logic for interest list
    if (this.dynamicModal.pageType === 'Apartment Interest List') {
      formGroup.mainText = [this.userDefaults.mainText || '', Validators.required];
      formGroup.onlineApplicationUrl = [this.userDefaults.onlineApplicationUrl || ''];
    }

    // Special logic for rent reminder
    if (this.dynamicModal.pageType === 'Rent Reminder' ||
      this.dynamicModal.pageType === 'Paseo Renewal' ||
      this.dynamicModal.pageType === 'Revere Rent Reminder') {
      formGroup.residentPortalUrl = [this.userDefaults.residentPortalUrl || '', Validators.required];
      }

    // Special logic for templates with application URL
    if (this.dynamicModal.pageType === 'Apartment Concession' ||
    this.dynamicModal.pageType === 'Apartment Tour' ||
    this.dynamicModal.pageType === 'Guest Card' || 
    this.dynamicModal.pageType === 'Apartment Accepting Applications') {
      formGroup.onlineApplicationUrl = [this.userDefaults.onlineApplicationUrl || '', Validators.required];
    }

    // Special logic for event reminder
    if (this.dynamicModal.pageType === 'Apartment Event Reminder') {
      formGroup.eventName = [this.userDefaults.eventName || '', Validators.required];
      formGroup.eventDate = [this.userDefaults.eventDate || '', Validators.required];
      formGroup.eventTime = [this.userDefaults.eventTime || '', Validators.required];
      formGroup.eventLocation = [this.userDefaults.eventLocation || '', Validators.required];
      formGroup.eventText = [this.userDefaults.eventText || ''];
      formGroup.eventImage = [this.userDefaults.eventImage || ''];
    }

    // Insert all dynamic fields into form group.
    for (var i = 0; i < this.dynamicModal.fields.length; i++) {
      var field = this.dynamicModal.fields[i];
      formGroup[field.key] = ['']
      if (field.required) {
        formGroup[field.key].push(Validators.required);
      }
    }
    this.newPageForm = this.formBuilder.group(formGroup);
    this.newPageForm.controls.type.setValue(this.dynamicModal.pageType);

    this.newPageForm.get('bannerImage').valueChanges.subscribe(val => {
      if (!val) {
        this.changeControlOnFormImages('bannerImage')
      } else if (val.length && val.length > 0) {
      }
    });

    this.loading = false;
    this.loaderService.stopLoader();

    // TODO IF BULK UPLOAD
    if (this.bulkUpload) {
      this.bulkUploadNewPage();
      this.newPageForm.valueChanges.subscribe(() => {

        // Triger re-evaluation of bulk upload readiness
        this.bulkUploadNewPage();
      }, (err) => {
        console.error('There was an error listening to form changes! ', err);
      });
    }
  }

  changeControlOnFormImages(name) {
    this.debouncedChangeDetector({target: {checked: false}}, name);
  }

  loadLabels() {
    return new Promise((resolve, reject) => {
      this.labelsService.getLabels().subscribe((data) => {
        this.labelData = data.result;
        resolve(data.result);
      }, (err) => {
        this.labelData = ['Error retrieving labels'];
        console.error('There was an error retrieving the users labels: ', err);
        reject(err);
      });
    })
  }

  savePage(form) {
    this.createPageLoading = true;
    this.createPageError = '';
    var data = form.getRawValue();
    
    // Set location if Appointment Reminder
    if (this.dynamicModal.pageType === 'Apartment Appointment Reminder') {
      data.lat = this.userData.lat;
      data.long = this.userData.long;
    }

    if (this.isEmailTemplate) {
      this.emailsService.createEmail(data).subscribe((results) => {
        this.emailsCreated = results.result;
        this.sampleEmailData = results.result;

        // Set object to be used to show available tokens.
        this.tokenSamples = JSON.parse(JSON.stringify(this.sampleEmailData.data));
        delete this.tokenSamples.pageName;
        delete this.tokenSamples.label;
        delete this.tokenSamples.bannerImage;
        delete this.tokenSamples.leftLogo;
        delete this.tokenSamples.footerContent;
        delete this.tokenSamples.type;
        delete this.tokenSamples.updated;
        delete this.tokenSamples.created;
        this.sendEmailForm = this.formBuilder.group({
          recipientEmailAddress: [results.result.data.clientEmailAddress || '', Validators.required],
          emailSubjectLine: [this.defaultMessageDict[this.emailsCreated.data.type], Validators.required]
        });
        this.remainingCharacterCount = function() {
          return 60 - this.sendEmailForm.controls.emailSubjectLine.value.length;
        };
        this.modalService.open(this.sendEmailModal, this.emailModalOptions).result.then((result) => {
          this.router.navigate(['dashboard']);
        }, () => {
          this.createPageLoading = false;
          this.toastService.show('Your email was not sent.', {
            classname: 'bg-danger text-light',
            delay: 5000
          });
        });
      }, (err) => {
        console.error('There was an error creating your email. ', err);
        this.createPageLoading = false;
        this.createPageError = err;
      });
    } else {
      this.pagesService.createPage(data).subscribe(page => {
        this.createPageLoading = false;
        this.router.navigate(['pages'], { queryParams: { fromCreate: true }, state: {pagesCreated: page.result}});
      }, (err) => {
        this.createPageLoading = false;
        this.createPageError = err;
      });
    }

  }

  exitCreateNew() {
    this.router.navigate(['templates']);
  }

  openMediaModal(event, form, key) {
    event.preventDefault();
    event.stopPropagation();
    self.modalService.open(MediaModalComponent, self.modalOptions).result.then((result) => {
      self.activeTextData = {};
      if (result && result.url) {
        form.controls[key].setValue(result.url);
      }
    }, (reason) => {
      self.activeTextData = {};
      if (reason === 'Exit') {
      }
    });
  }
  
  resetImage(prop, form) {
    form.controls[prop].setValue('');
  }

  uploadMedia(files, form, property) {
    this.loadingImage[property] = true;
    this.mediaService.getSignedRequestHelper(files, form, property).
        then((data) => {
          var patch = {};
          patch[property] = data;
          setTimeout(() => {
            form.patchValue(patch);
            this.loadingImage[property] = false;
          }, 2000)
        }).
        catch((err) => {
          console.error('There was an error uploading media: ', err);
          this.toastService.show('There was an error uploading media: ' + err, { classname: 'bg-danger text-light', delay: 5000 });
          this.loadingImage[property] = false;
        });
  }

  newLabel() {
    this.labelsService.createLabel({name: this.currentLabelSearch}).
        subscribe((data) => {
          this.loadLabels().
              then(() => {
                this.setLabel(data.result);
                this.auto.close();
              });
        }, (err) => {
          console.error('There was an error creating the new label: ', err);
          this.toastService.show('Error create label, please try again.', { classname: 'bg-danger text-light', delay: 5000 });
        });
  }

  updateCurrentLabel(label) {
    this.currentLabelSearch = label;
    if (this.labelData.length > 0) {
      if ((!this.currentLabelSearch || this.currentLabelSearch.length === 0) && (this.labelData[0]._id === 0)) {
        this.labelData.shift();
      } else if (this.currentLabelSearch && this.currentLabelSearch.length > 0 && (this.labelData[0]._id === 0)) {
        this.labelData[0].name = label + ' (create new)';
      } else if (this.currentLabelSearch && this.currentLabelSearch.length > 0 && (this.labelData[0]._id !== 0)) {
        this.labelData.unshift({_id: 0, name: label + ' (create new)'})
      }
    } else {
      this.labelData.unshift({_id: 0, name: label + ' (create new)'});
    }
  }

  setLabel(label) {
    if (label._id === 0) {
      this.newLabel();
    } else {
      this.currentLabelSearch = label.name;
      this.newPageForm.controls.label.setValue(label.name);
    }
  }

  clearLabelNew() {
    if (this.labelData[0]._id === 0) {
      this.labelData.shift();
    }
  }

  updateDefaults(e, name) {

    // Listen for user un-checking make default box
    if (Object.keys(this.userDefaults).length > 0 && !e.target.checked) {
      this.userDefaults[name] = null;
      this.templatesService.updateTemplateDefaults(this.dynamicModal.pageType, this.userDefaults).subscribe((defaults) => {
        this.userDefaults = defaults.result;
        this.showHideDefaultUpdated(name, 'defaultRemoved');
      }, (err) => {
        console.error('There was an error saving template defaults: ', err);
        this.toastService.show('There was an error saving template defaults', { classname: 'bg-danger text-light', delay: 5000 });
      });

    // Listen for user checking make default box
    }  else if (Object.keys(this.userDefaults).length > 0 && e.target.checked) {
      this.userDefaults[name] = this.newPageForm.controls[name].value;
      this.templatesService.updateTemplateDefaults(this.dynamicModal.pageType, this.userDefaults).subscribe((defaults) => {
        this.userDefaults = defaults.result;
        this.showHideDefaultUpdated(name, 'defaultAdded');
      }, (err) => {
        console.error('There was an error saving template defaults: ', err);
        this.toastService.show('There was an error saving template defaults', { classname: 'bg-danger text-light', delay: 5000 });
      });
    }
  }

  checkSectionValidity(section) {
    if (section === 'organizationalSettings') {
      if (this.newPageForm.controls.pageName.valid || this.bulkUpload) {
        this.safelyAdjustClassName('#organizational-settings-header', false);
        return true;
      } else {
        this.safelyAdjustClassName('#organizational-settings-header', true);
        return false;
      }
    } else if (section === 'propertyInfo') {
      if (
        this.newPageForm.controls.bannerImage.valid &&
        this.newPageForm.controls.leftLogo.valid &&
        this.newPageForm.controls.apartmentName.valid &&
        this.newPageForm.controls.yourWebsite.valid
      ) {

        // Check other page types
        if (
          this.pageType === 'Apartment Concession' && 
          this.newPageForm.controls.concession.valid &&
          this.newPageForm.controls.onlineApplicationUrl.valid
        ) {
          this.safelyAdjustClassName('#property-info-header', false);
          return true;
        } else if (
          (this.pageType === 'Rent Reminder' ||
          this.pageType === 'Revere Rent Reminder' ||
          this.pageType === 'Paseo Renewal')&&
          this.newPageForm.controls.residentPortalUrl.valid
        ) {
          this.safelyAdjustClassName('#property-info-header', false);
          return true;
        } else if (
          (this.pageType === 'Apartment Tour' || this.pageType === 'Guest Card' || this.pageType === 'Apartment Accepting Applications') &&
          this.newPageForm.controls.onlineApplicationUrl.valid
        ) {
          this.safelyAdjustClassName('#property-info-header', false);
          return true;
        } else if (this.pageType !== 'Apartment Concession' && this.pageType !== 'Apartment Tour' && this.pageType !== 'Guest Card' && this.pageType !== 'Rent Reminder'  && this.pageType !== 'Apartment Accepting Applications') {
          this.safelyAdjustClassName('#property-info-header', false);

          return true;
        } else {
          this.safelyAdjustClassName('#property-info-header', true);
          return false;
        }
      } else {
        this.safelyAdjustClassName('#property-info-header', true);
        return false;
      }
    } else if (section === 'propertyContactInfo') {
      if (
        this.newPageForm.controls.yourName.valid &&
        this.newPageForm.controls.yourEmailAddress.valid &&
        this.newPageForm.controls.yourPhoneNumber.valid
      ) {
        this.safelyAdjustClassName('#property-contact-info-header', false);
        return true;
      } else {
        this.safelyAdjustClassName('#property-contact-info-header', true);
        return false;
      }
    } else if (section === 'otherInfo') {
      if (
        this.pageType === 'Apartment Event Reminder' && 
        this.newPageForm.controls.eventName.valid &&
        this.newPageForm.controls.eventDate.valid &&
        this.newPageForm.controls.eventTime.valid &&
        this.newPageForm.controls.eventLocation.valid
      ) {
        this.safelyAdjustClassName('#other-info-header', false);
        return true;
      } else if (this.pageType !== 'Apartment Event Reminder') {
        this.safelyAdjustClassName('#other-info-header', false);
        return true; 
      } else {
        this.safelyAdjustClassName('#other-info-header', true);
        return false;
      }
    } else if (section == 'prospectInfo') {
      var valid = true;

      // Get custom fields to check validity
      for (var i = 0; i < this.dynamicModal.fields.length; i++) {
        if (this.dynamicModal.fields[i].required && !this.newPageForm.controls[this.dynamicModal.fields[i].key].valid) {
          valid = false;
          break;
        }
      }
      if (valid) {
        this.safelyAdjustClassName('#custom-fields-header', false);
        return true;
      } else {
        this.safelyAdjustClassName('#custom-fields-header', true);
        return false;
      }
    } else {
      return false;
    }
  }

  safelyAdjustClassName(querySelector, unset) {
    if (document.querySelector(querySelector) && unset) {
      document.querySelector(querySelector).className = 'card-header';
    } else if (document.querySelector(querySelector) && !unset) {
      document.querySelector(querySelector).className = 'card-header valid-section';
    }
  }

  bulkUploadNewPage() {
    this.activeTypeRequiredFields.length = 0;
    switch (this.pageType) {
      case 'Apartment Tour':

        // Required fields expected to be found on spreadsheet.
        self.requiredDefaults = ['pageName', 'clientFirstName'];

        // All fields required to generate page.
        self.requiredFields = self.apartmentTourRequiredFields;

        // Add optional fields that can be found on spreadsheet.
        self.optionalFields = ['moveInDate', 'clientBedroomCount', 'leaseTerm', 'monthlyRent'];
        break;
      case 'Move In Checklist':
        self.requiredDefaults = ['pageName', 'clientFirstName', 'apartmentNumber', 'moveInDate'];
        self.requiredFields = self.moveInChecklistRequiredFields;
        self.optionalFields = ['moveInCosts', 'specialPaymentInstructions', 'powerProvider', 'specialPowerProviderInstructions', 'specialInsuranceInstructions'];
        break;
      case 'Guest Card':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.guestCardRequiredFields;
        self.optionalFields = ['moveInDate', 'clientBedroomCount'];
        break;
      case 'Apartment Concession':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.apartmentConcessionRequiredFields;
        self.optionalFields = [];
        break;
      case 'Apartment Renewal':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.apartmentLeaseRenewalRequiredFields;
        self.optionalFields = ['leaseTerm', 'renewalSpecial'];
        break;
      case 'Apartment Maintenance Followup':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.apartmentMaintenanceFollowUpRequiredFields;
        self.optionalFields = ['surveyLink'];
        break;
      case 'Apartment Event Reminder':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.eventReminderRequiredFields;
        self.optionalFields = [];
        break;
      case 'The Royce Rent Reminder':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.theRoyceRentReminderRequiredFields;
        self.optionalFields = [];
        break;
      case 'Bella Posta Remodel Relocation':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.theRoyceRentReminderRequiredFields;
        self.optionalFields = [];
        break;
      case 'Rent Reminder':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.rentReminderRequiredFields;
        self.optionalFields = [];
        break;
      case 'Revere Rent Reminder':
          self.requiredDefaults = ['pageName', 'clientFirstName'];
          self.requiredFields = self.rentReminderRequiredFields;
          self.optionalFields = [];
          break;
      case 'Paseo Renewal':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.paseoRenewalRequiredFields;
        self.optionalFields = [];
        break;
      case 'Apartment Appointment Reminder':
        self.requiredDefaults = ['pageName', 'clientFirstName', 'appointmentDate', 'appointmentTime'];
        self.requiredFields = self.apartmentAppointmentReminderRequiredFields;
        self.optionalFields = [];
        break;
      case 'Apartment Interest List':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.apartmentInterestListRequiredFields;
        self.optionalFields = [];
        break;
      case 'Apartment Covid Screening':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.apartmentCovidScreeningRequiredFields;
        self.optionalFields = [];
        break;
      case 'Harper Self Guided Tour':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.harperSelfGuidedTourRequiredFields;
        self.optionalFields = [];
        break;
      case 'Harper Move In Checklist':
        self.requiredDefaults = ['pageName', 'clientFirstName', 'apartmentNumber', 'moveInDate'];
        self.requiredFields = self.harperMoveInChecklistRequiredFields;
        self.optionalFields = ['moveInCosts'];
        break;
      case 'Apartment Accepting Applications':
        self.requiredDefaults = ['pageName', 'clientFirstName'];
        self.requiredFields = self.apartmentAcceptingApplicationsRequiredFields;
        self.optionalFields = ['moveInDate', 'clientBedroomCount'];
        break;
    }

    if (self.isEmailTemplate) {
      if (self.requiredFields.indexOf('clientEmailAddress') === -1) {
        self.requiredFields.push('clientEmailAddress');
      }
      if (self.requiredDefaults.indexOf('clientEmailAddress') === -1) {
        self.requiredDefaults.push('clientEmailAddress');
      }
    } else {
      if (self.requiredFields.indexOf('clientPhoneNumber') === -1) {
        self.requiredFields.push('clientPhoneNumber');
      }
      if (self.requiredDefaults.indexOf('clientPhoneNumber') === -1) {
        self.requiredDefaults.push('clientPhoneNumber');
      }
    }

    // Check if the required fields to generate page have been populated in form.
    for (var i = 0; i < self.requiredFields.length; i++) {
      if (!this.newPageForm.getRawValue()[self.requiredFields[i]]) {

        // These are the ACTUAL remaining required fields needed to generate page.
        this.activeTypeRequiredFields.push(self.requiredFields[i])
      }
    }

    // If there are required fields needed still that ARE NOT expected on spreadsheet, disable upload.
    setTimeout(() => {
      this.uploadDisabled = !_.isEqual(this.activeTypeRequiredFields.sort(), this.requiredDefaults.sort());
    });
  }

  selectUploadMethod(e, method) {
    this.uploadSelection = method;
    if (e) {
      e.preventDefault();
    }
    if (method === 'nurtureBoss') {
      setTimeout(() => {
        this.setListenersForFileUpload();
      });
    }
    this.ngWizardService.next();
  }

  processUploadedFile(e) {
    e.preventDefault();
    this.papa.parse(this.fileUploaded, this.parserConfig);
  }

  goToNextStep(e, dedupe, isEmail) {
    e.preventDefault();
    if (dedupe) {
      var dedupeField;
      if (isEmail) {
        dedupeField = 'clientEmailAddress';
      } else {
        dedupeField = 'clientPhoneNumber';
      }
      var dedupedDataForUpload = this.dedupeArray(this.dataForUpload, dedupeField);
      this.duplicatesRemoved = this.dataForUpload.length - dedupedDataForUpload.length;
      this.dataForUpload = dedupedDataForUpload;
    }
    this.ngWizardService.next();
  }

  // Event listener to detect user upload of file.
  setListenersForFileUpload() {
    var file;
    var config;
    document.querySelector("#data-upload").addEventListener('change', () => {
      file = ( < HTMLInputElement > document.getElementById('data-upload')).files[0];
      config = {
        delimiter: "", // auto-detect
        newline: "", // auto-detect
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        transformHeader: undefined,
        dynamicTyping: false,
        preview: 0,
        encoding: "",
        worker: false,
        comments: false,
        step: this.validateTemplateContent,
        complete: this.onFileUploaded,
        error: undefined,
        download: false,
        downloadRequestHeaders: undefined,
        skipEmptyLines: false,
        chunk: undefined,
        fastMode: undefined,
        beforeFirstChunk: undefined,
        withCredentials: undefined,
        transform: undefined,
        delimitersToGuess: [',', '\t', '|', ';', String.fromCharCode(30), String.fromCharCode(31)]
      }
      this.reader.readAsArrayBuffer(file);
    });
    this.reader.onload = () => {
      this.fileUploaded = file;
      this.parserConfig = config;
    };
  }

  // Validate file that user uploads.
  validateTemplateContent(results, parser) {
    parser.pause();
    self.bulkUploadError = false;
    var requiredFields = self.activeTypeRequiredFields;
    if (results.errors.length > 0 && !Array.isArray(results.data)) {
      console.error('CSV Parser Error: ', results.errors);
      self.bulkUploadError = 'Upload Error! Please check your spreadsheet and try again. Click START OVER below when ready.';
      for (var i = 0; i < results.errors.length; i++) {
        console.error('There was an error with your uploaded file! ' + results.errors[i]);
        self.toastService.show('There was an error with your uploaded file!', {
          classname: 'bg-danger text-light',
          delay: 7000
        });
      }
      parser.abort();
    }
    var count = 0;
    if (Array.isArray(results.data)) {

      // Empt line... no-op
    } else {
      for (let i in results.data) {

        // Check if field is empty
        if (!results.data[i] || results.data[i].length === 0) {
          if (self.userDefaults[i]) {
            delete results.data[i];
          }
        } else {
          if (requiredFields.indexOf(i) >= 0) {
            count = count + 1;
          }
        }
      }

      if (count < requiredFields.length) {
        console.error('Missing Fields! ', count, requiredFields.length);
        parser.abort();
        self.toastService.show('Your uploaded CSV is missing required fields.', {
          classname: 'bg-danger text-light',
          delay: 7000
        });
        self.bulkUploadError = 'Missing fields! Please check your spreadsheet and try again. Click START OVER below when ready.';
      } else {

        // Save to DB
        results.data.type = self.pageType;

        // Strip out all non-digits if text template.
        if (results.data.clientPhoneNumber) {
          results.data.clientPhoneNumber = results.data.clientPhoneNumber.replace(/\D/g,'');
        }
        var finalData = {
          ...self.userDefaults,
          ...results.data
        };
        self.dataForUpload.push(finalData);
        self.sampleData = results.data;
      }
    }
    parser.resume();
  }

  // Handle bulk CSV file upload.
  onFileUploaded(results, file) {
    self.ngWizardService.next();
  }

  dedupeArray(array, prop) {
    var obj = {};
    for (var i = 0; i < array.length; i++ ) {
      obj[array[i][prop]] = array[i];
    }
    var newArray = new Array();
    for (var key in obj) {
      newArray.push(obj[key]);
    }
    return newArray;
  }

  createPages(e) {
    e.preventDefault();
    self.isUploading = true;
    self.pageCreateError = '';
    for (var b = 0; b < self.dataForUpload.length; b++) {

      // Set location if Appointment Reminder
      if (this.dynamicModal.pageType === 'Apartment Appointment Reminder') {
        self.dataForUpload[b].lat = this.userData.lat;
        self.dataForUpload[b].long = this.userData.long;
      }
      self.observableOperations$.push(self.pagesService.createPage(self.dataForUpload[b]));
    }
    forkJoin(...self.observableOperations$).subscribe((results) => {
      var dataToPass = [];
      var showModal = false;
      for (var i = 0; i < results.length; i++) {
        if (results[i].result) {
          dataToPass.push(results[i].result);
        } else {
          showModal = true;
          self.pageCreateError = results[i].error;
        }
      }
      self.isUploading = false;
      self.observableOperations$ = [];
      if (showModal) {
        this.modalService.open(this.modalContent, this.errorModalOptions).result.then(() => {
          this.router.navigate(['pages'], { queryParams: { fromCreate: true }, state: {pagesCreated: dataToPass}});
        });
      } else {
        this.router.navigate(['pages'], { queryParams: { fromCreate: true }, state: {pagesCreated: dataToPass}});
      }
    });
  }

  createEmails(e) {
    e.preventDefault();
    self.bulkEmail = true;
    self.isUploading = true;
    self.pageCreateError = '';
    self.sendEmailForm = self.formBuilder.group({
      recipientEmailAddress: ['', Validators.required],
      emailSubjectLine: [self.defaultMessageDict[self.dataForUpload[0].type], Validators.required]
    });
    for (var b = 0; b < self.dataForUpload.length; b++) {

      // Set location if Appointment Reminder
      if (self.dynamicModal.pageType === 'Apartment Appointment Reminder') {
        self.dataForUpload[b].lat = self.userData.lat;
        self.dataForUpload[b].long = self.userData.long;
      }
      self.observableOperations$.push(self.emailsService.createEmail(self.dataForUpload[b]));
    }
    forkJoin(...self.observableOperations$).subscribe((results) => {
      var dataToPass = [];
      var showModal = false;
      for (var i = 0; i < results.length; i++) {
        if (results[i].result) {
          dataToPass.push(results[i].result);
          self.sampleEmailData = results[i].result;
        } else {
          showModal = true;
          self.pageCreateError = results[i].error;
        }
      }
      self.isUploading = false;
      self.observableOperations$ = [];
      self.bulkEmailsToSend = dataToPass;

      // Set object to be used to show available tokens.
      this.tokenSamples = JSON.parse(JSON.stringify(dataToPass[0].data));
      delete this.tokenSamples.pageName;
      delete this.tokenSamples.label;
      delete this.tokenSamples.bannerImage;
      delete this.tokenSamples.leftLogo;
      delete this.tokenSamples.footerContent;
      delete this.tokenSamples.type;
      delete this.tokenSamples.updated;
      delete this.tokenSamples.created;
      this.remainingCharacterCount = function() {
        return 60 - this.sendEmailForm.controls.emailSubjectLine.value.length;
      };
      if (showModal) {
        self.modalService.open(self.modalContent, self.errorModalOptions).result.then(() => {
          self.modalService.open(self.sendEmailModal, self.modalOptions).result.then(() => {
            self.router.navigate(['dashboard']);
          }, () => {
            self.createPageLoading = false;
            self.toastService.show('Your email was not sent.', {
              classname: 'bg-danger text-light',
              delay: 5000
            });
          });
        });
      } else {
        self.modalService.open(self.sendEmailModal, self.modalOptions).result.then(() => {
          self.router.navigate(['dashboard']);
        }, () => {
          self.createPageLoading = false;
          self.toastService.show('Your email was not sent.', {
            classname: 'bg-danger text-light',
            delay: 5000
          });
        });
      }
    });
  }

  completeSpreadsheetSelection(data, sampleData, skippedPages) {
    self.dataForUpload = data;
    self.sampleData = sampleData
    self.ngWizardService.next();
    self.skippedPages = skippedPages || 0;
  }

  refresh(): void {
    window.location.reload();
  }

  checkForRealPageResults() {
      self.intervalCheckCount++;

      // Stop this from running forever.
      if (self.intervalCheckCount > 30) {
        clearInterval(self.loopingInterval);
        self.loaderService.stopLoader();
        console.error('There was an error retrieving prospect data from RealPage. Took too long.');
        self.toastService.show('Could not contact RealPage', { classname: 'bg-danger text-light', delay: 5000 });
        self.activeJob = null;
        self.loopingInterval = null;
        self.intervalCheckCount = 0;
        self.hasPmsIntegrationDataRequest = false;
        return;
      }
      self.contactsService.getContactsWorker(self.activeJob.result.jobId).subscribe((returnBack) => {
        if (returnBack.result && returnBack.result.length && !self.hasPmsIntegrationDataRequest) {
          clearInterval(self.loopingInterval);
          self.hasPmsIntegrationDataRequest = true;
          var data = returnBack.result;
          self.pmsIntegrationData = [];
          self.statusFilter = {};
          self.sourcesFilter = {};
          self.optInFilter = {};
          self.sourceFilterOptions = [];
          self.optInFilterOptions = [];
  
          // Set columns for table
          self.cols = [
            { field: 'clientFullName', header: 'Name' },
            { field: 'status', header: 'Status' },
            { field: 'recentActivityDate', header: 'Recent Activity Date' },
            { field: 'recentActivityType', header: 'Recent Activity Type' },
            { field: 'recentActivityComments', header: 'Recent Activity' },
            { field: 'stage', header: 'Stage' },
            { field: 'source', header: 'Source' }
          ];
    
          // Prepare model for UI.
          for (var i = 0; i < data.length; i++) {
            var contact = data[i];
            if (self.isEmailTemplate) {
              self.sourcesFilter[contact.source] = true;
              self.optInFilter[contact.optIn] = true;
              contact.clientFullName = contact.contactIdentifier;
              contact.clientGuestCardId = contact.yardiGuestCardId;
              contact.clientFirstName = contact.firstName;
              contact.clientPhoneNumber = contact.phoneNumber;
              contact.clientEmailAddress = contact.emailAddress;
              self.pmsIntegrationData.push(contact);
              self.statusFilter[contact.status] = true;
            } else if (self.marketingTemplates.indexOf(self.pageType) > -1) {
              if (contact.optIn) {
                self.sourcesFilter[contact.source] = true;
                self.optInFilter[contact.optIn] = true;
                contact.clientFullName = contact.contactIdentifier;
                contact.clientGuestCardId = contact.yardiGuestCardId;
                contact.clientFirstName = contact.firstName;
                contact.clientPhoneNumber = contact.phoneNumber;
                contact.clientEmailAddress = contact.emailAddress;
                self.pmsIntegrationData.push(contact);
                self.statusFilter[contact.status] = true;
              }
            } else {
              self.sourcesFilter[contact.source] = true;
              self.optInFilter[contact.optIn] = true;
              contact.clientFullName = contact.contactIdentifier;
              contact.clientGuestCardId = contact.yardiGuestCardId;
              contact.clientFirstName = contact.firstName;
              contact.clientPhoneNumber = contact.phoneNumber;
              contact.clientEmailAddress = contact.emailAddress;
              self.pmsIntegrationData.push(contact);
              self.statusFilter[contact.status] = true;
            }
          }
  
          // Set filter for status
          for (var t in self.statusFilter) {
            self.statusFilterOptions.push({
              label: t,
              value: t
            });
          }
  
          // Set filter for source
          for (var t in self.sourcesFilter) {
            self.sourceFilterOptions.push({
              label: t,
              value: t
            });
          }

          // Set filter for opt-in
          for (var t in self.optInFilter) {
            self.optInFilterOptions.push({
              label: t,
              value: t
            });
          }
          self.pmsIntegrationDataToShow = JSON.parse(JSON.stringify(self.pmsIntegrationData));
          self.totalRecords = self.pmsIntegrationDataToShow.length;
          self.disableAll = false;
          self.loaderService.stopLoader();
          self.selectUploadMethod(null, 'yardi');
          self.activeJob = null;
          self.loopingInterval = null;
          self.intervalCheckCount = 0;
        }
      });
  }

  getYardiProspects(e) {
    this.disableAll = true;
    this.loaderService.triggerLoader(true);
    if (e) {
      e.preventDefault();
    }
    this.contactsService.getContacts('yardi').subscribe((job) => {

      // Store worker ID and check for results.
      this.activeJob = job;
      this.loopingInterval = setInterval(this.checkForYardiResults, 2000)

    }, (err) => {
      this.loaderService.stopLoader();
      console.error('There was an error retrieving prospect data from RealPage: ', err);
    });
  }

  checkForYardiResults() {
    self.intervalCheckCount++;

    // Stop this from running forever.
    if (self.intervalCheckCount > 30) {
      clearInterval(self.loopingInterval);
      self.loaderService.stopLoader();
      console.error('There was an error retrieving prospect data from Yardi. Took too long.');
      self.toastService.show('Could not contact Yardi', { classname: 'bg-danger text-light', delay: 5000 });
      self.activeJob = null;
      self.loopingInterval = null;
      self.intervalCheckCount = 0;
      self.hasPmsIntegrationDataRequest = false;
      return;
    }
    self.contactsService.getContactsWorker(self.activeJob.result.jobId).subscribe((returnBack) => {
      if (returnBack.result && returnBack.result.length && !self.hasPmsIntegrationDataRequest) {
        clearInterval(self.loopingInterval);
        self.hasPmsIntegrationDataRequest = true;
        var data = returnBack.result;
        self.pmsIntegrationData = [];
        self.statusFilter = {};
        self.sourcesFilter = {};
        self.optInFilter = {};
        self.sourceFilterOptions = [];
        self.optInFilterOptions = [];

        // Set columns for table
        self.cols = [
          { field: 'clientFullName', header: 'Name' },
          { field: 'status', header: 'Status' },
          { field: 'recentActivityDate', header: 'Recent Activity Date' },
          { field: 'recentActivityType', header: 'Recent Activity Type' },
          { field: 'recentActivityComments', header: 'Recent Activity' },
          { field: 'stage', header: 'Stage' },
          { field: 'source', header: 'Source' }
        ];
  
        // Prepare model for UI.
        for (var i = 0; i < data.length; i++) {
          var contact = data[i];
          if (self.isEmailTemplate) {
            self.sourcesFilter[contact.source] = true;
            self.optInFilter[contact.optIn] = true;
            contact.clientFullName = contact.contactIdentifier;
            contact.clientGuestCardId = contact.yardiGuestCardId;
            contact.clientFirstName = contact.firstName;
            contact.clientPhoneNumber = contact.phoneNumber;
            contact.clientEmailAddress = contact.emailAddress;
            self.pmsIntegrationData.push(contact);
            self.statusFilter[contact.status] = true;

          // If we are texting, only show contacts who have opted in.
          } else if (self.marketingTemplates.indexOf(self.pageType) > -1) {
            if (contact.optIn) {
              self.sourcesFilter[contact.source] = true;
              self.optInFilter[contact.optIn] = true;
              contact.clientFullName = contact.contactIdentifier;
              contact.clientGuestCardId = contact.yardiGuestCardId;
              contact.clientFirstName = contact.firstName;
              contact.clientPhoneNumber = contact.phoneNumber;
              contact.clientEmailAddress = contact.emailAddress;
              self.pmsIntegrationData.push(contact);
              self.statusFilter[contact.status] = true;
            }
          } else {
            self.sourcesFilter[contact.source] = true;
            self.optInFilter[contact.optIn] = true;
            contact.clientFullName = contact.contactIdentifier;
            contact.clientGuestCardId = contact.yardiGuestCardId;
            contact.clientFirstName = contact.firstName;
            contact.clientPhoneNumber = contact.phoneNumber;
            contact.clientEmailAddress = contact.emailAddress;
            self.pmsIntegrationData.push(contact);
            self.statusFilter[contact.status] = true;
          }
        }

        // Set filter for status
        for (var t in self.statusFilter) {
          self.statusFilterOptions.push({
            label: t,
            value: t
          });
        }

        // Set filter for source
        for (var t in self.sourcesFilter) {
          self.sourceFilterOptions.push({
            label: t,
            value: t
          });
        }

        // Set filter for optIn
        for (var t in self.optInFilter) {
          self.optInFilterOptions.push({
            label: t,
            value: t
          });
        }
        self.pmsIntegrationDataToShow = JSON.parse(JSON.stringify(self.pmsIntegrationData));
        self.totalRecords = self.pmsIntegrationDataToShow.length;
        self.disableAll = false;
        self.loaderService.stopLoader();
        self.selectUploadMethod(null, 'yardi');
        self.activeJob = null;
        self.loopingInterval = null;
        self.intervalCheckCount = 0;
      }
    });
  }

  paginate(event) {
    this.pagination.first = event.first;
    this.pagination.last = event.first + event.rows;
  }

  onOptInFilterChange(event) {
    var val = [];
    for (var t = 0; t < event.value.length; t++) {
      if (event.value[t] === 'false') {
        val.push(false);
      } else {
        val.push(true)
      }
    }
    this.table.filter(val, 'optIn', 'in');
  }

  onSourceFilterChange(event) {
    this.table.filter(event.value, 'source', 'in');
  }

  onStageFilterChange(event) {
    this.table.filter(event.value, 'stage', 'in');
  }

  onStatusFilterChange(event) {
    this.table.filter(event.value, 'status', 'in');
  }

  onDateSelect(value) {
    if (this.rangeDates.length === 2) {
      this.rangeDates = [value];
      return;
    } else {
      this.rangeDates.push(value);
    }
    if (this.rangeDates.length === 2) {
      this.rangeDates.sort(function(a, b) {
        return +new Date(a) - +new Date(b);
      });
      this.table.filter(this.rangeDates, 'recentActivityDate', 'dateRangeFilter')
    }
  }

  onCreateDateSelect(value) {
    if (this.createRangeDates.length === 2) {
      this.createRangeDates = [value];
      return;
    } else {
      this.createRangeDates.push(value);
    }
    if (this.createRangeDates.length === 2) {
      this.createRangeDates.sort(function(a, b) {
        return +new Date(a) - +new Date(b);
      });
      this.table.filter(this.createRangeDates, 'created', 'dateRangeFilter')
    }
  }

  getRealPageProspects(e) {
    this.disableAll = true;
    this.loaderService.triggerLoader(true);
    if (e) {
      e.preventDefault();
    }
    this.contactsService.getContacts('realpage').subscribe((job) => {

      // Store worker ID and check for results.
      this.activeJob = job;
      this.loopingInterval = setInterval(this.checkForRealPageResults, 2000)

    }, (err) => {
      this.loaderService.stopLoader();
      console.error('There was an error retrieving prospect data from RealPage: ', err);
    });
  }

  continueToDataVerification(e) {
    if (e) {
      e.preventDefault();
    }
    // Logic for p-table
    if (this.selectedRows.length > 0) {
      for (var t = 0; t < this.selectedRows.length; t++) {
        var createObjectForPage:any = {
          type: this.pageType,
          clientFirstName: this.selectedRows[t].clientFirstName,
          pageName: this.selectedRows[t].clientFullName,
          clientPhoneNumber: this.selectedRows[t].clientPhoneNumber.replace(/\D/g,''),
          clientEmailAddress: this.selectedRows[t].clientEmailAddress
        };
        if (this.uploadSelection === 'yardi') {
          createObjectForPage.yardiGuestCardId = this.selectedRows[t].clientGuestCardId;
        } else {
          createObjectForPage.realPageGuestCardId = this.selectedRows[t].clientGuestCardId;
        }
        delete self.userDefaults.created;
        delete self.userDefaults.ownerId;
        delete self.userDefaults.updated;
        delete self.userDefaults.__v;
        delete self.userDefaults._id;
        var finalData = {
          ...self.userDefaults,
          ...createObjectForPage
        };
        self.dataForUpload.push(finalData);
        self.sampleData = finalData;
      }
    }
    self.ngWizardService.next();
  }

  exitModal(e, modal) {
    e.preventDefault();
    modal.dismiss();
  }

  openScheduleModal(modal, e, parentModal) {
    e.preventDefault();
    this.modalService.open(modal, {
      windowClass: "custom-modal-styles-new schedule-email-modal"
    }).result.then(() => {
      this.sendDate = null;
      this.sendTime = null;
      parentModal.close();
      this.router.navigate(['templates']);
    }, () => {
      this.sendDate = null;
      this.sendTime = null;
    });
  }

  saveSchedule(modal) {
    var postBody: any;
    this.schedSaveInProgress = true;
    if (this.bulkEmail) {
      var arrayOfPageIds = this.bulkEmailsToSend.map(function (obj) {
        return obj._id;
      });
      postBody = {
        pageIds: arrayOfPageIds,
        emailSubjectLine: this.sendEmailForm.controls.emailSubjectLine.value
      }
    } else {
      postBody = {
        pageIds: [this.emailsCreated._id],
        emailSubjectLine: this.sendEmailForm.controls.emailSubjectLine.value
      }
    }
    var dateString = this.sendDate.month + '/' + this.sendDate.day + '/' + this.sendDate.year + ' ' + this.sendTime.hour + ':' + this.sendTime.minute;
    this.emailsService.scheduleEmailSend({
      dateTime: new Date(dateString),
      data: postBody
    }).subscribe(() => {
      this.schedSaveInProgress = false;
      modal.close();
    }, (err) => {
      console.error('There was an error creating your schedules! ', err);
      this.schedSaveInProgress = false;
      this.toastService.show('There was an error creating your schedules', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
    });
  }

  deliverEmail(modal, bulk) {
    if (this.sendEmailForm.invalid && !bulk) {
      return;
    } else {
      this.errorMessage = null;
      this.sendingEmail = true;
      var postBody;
      if (bulk) {
        var arrayOfEmailObjectIds = this.bulkEmailsToSend.map(function (obj) {
          return obj._id;
        });
        postBody = {
          emailObjectIds: arrayOfEmailObjectIds,
          emailSubjectLine: this.sendEmailForm.controls.emailSubjectLine.value
        }
        this.emailsService.sendBulkEmails(postBody).subscribe((data) => {
          this.sendingEmail = false;
          this.toastService.show('Emails sent.', {
            classname: 'bg-success text-light',
            delay: 5000
          });
          modal.close('Success');
        }, (err) => {
          this.sendingEmail = false;
          console.error('There was an error sending your text ', err);
        });
      } else {
        postBody = {
          to: this.sendEmailForm.controls.recipientEmailAddress.value,
          emailId: this.emailsCreated._id,
          emailSubjectLine: this.sendEmailForm.controls.emailSubjectLine.value
        }
        this.emailsService.sendEmail(postBody).subscribe(() => {
          this.sendingEmail = false;
          this.toastService.show('Email sent.', {
            classname: 'bg-success text-light',
            delay: 5000
          });
          modal.close('Success');
        }, (err) => {
          this.sendingEmail = false;
          console.error('There was an error sending your email ', err);
          this.toastService.show('There was an error sending your email, please try again.', {
            classname: 'bg-danger text-light',
            delay: 5000
          });
          this.errorMessage = err;
        });
      }
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isToday(date: NgbDate) {
    return date.equals(this.toDate);
  }

  getContacts(e) {
    this.disableAll = true;
    this.loaderService.triggerLoader(true);
    if (e) {
      e.preventDefault();
    }
    this.contactsService.getContacts('').subscribe((contacts) => {
      var data = contacts.result;
      self.pmsIntegrationData = [];
      self.statusFilter = {};
      self.sourcesFilter = {};
      self.optInFilter = {};
      self.sourceFilterOptions = [];
      self.optInFilterOptions = [];

      // Set columns for table
      self.cols = [
        { field: 'clientFullName', header: 'Name' },
        { fields: 'created', header: 'Created' },
        { field: 'status', header: 'Status' },
        { field: 'recentActivityDate', header: 'Recent Activity Date' },
        { field: 'recentActivityType', header: 'Recent Activity Type' },
        { field: 'recentActivityComments', header: 'Recent Activity' },
        { field: 'stage', header: 'Stage' },
        { field: 'source', header: 'Source' }
      ];

      // Prepare model for UI.
      for (var i = 0; i < data.length; i++) {
        var contact = data[i];
        if (self.isEmailTemplate) {
          self.sourcesFilter[contact.source] = true;
          self.optInFilter[contact.optIn] = true;
          contact.clientFullName = contact.contactIdentifier;
          contact.clientGuestCardId = contact.yardiGuestCardId;
          contact.clientFirstName = contact.firstName;
          contact.clientPhoneNumber = contact.phoneNumber;
          contact.clientEmailAddress = contact.emailAddress;
          self.pmsIntegrationData.push(contact);
          self.statusFilter[contact.status] = true;
        } else if (self.marketingTemplates.indexOf(self.pageType) > -1) {
          if (contact.optIn) {
            self.sourcesFilter[contact.source] = true;
            self.optInFilter[contact.optIn] = true;
            contact.clientFullName = contact.contactIdentifier;
            contact.clientGuestCardId = contact.yardiGuestCardId;
            contact.clientFirstName = contact.firstName;
            contact.clientPhoneNumber = contact.phoneNumber;
            contact.clientEmailAddress = contact.emailAddress;
            self.pmsIntegrationData.push(contact);
            self.statusFilter[contact.status] = true;
          }
        } else {
          self.sourcesFilter[contact.source] = true;
          self.optInFilter[contact.optIn] = true;
          contact.clientFullName = contact.contactIdentifier;
          contact.clientGuestCardId = contact.yardiGuestCardId;
          contact.clientFirstName = contact.firstName;
          contact.clientPhoneNumber = contact.phoneNumber;
          contact.clientEmailAddress = contact.emailAddress;
          self.pmsIntegrationData.push(contact);
          self.statusFilter[contact.status] = true;
        }
      }

      // Set filter for status
      for (var t in self.statusFilter) {
        self.statusFilterOptions.push({
          label: t,
          value: t
        });
      }

      // Set filter for source
      for (var t in self.sourcesFilter) {
        self.sourceFilterOptions.push({
          label: t,
          value: t
        });
      }

      // Set filter for opt-in
      for (var t in self.optInFilter) {
        self.optInFilterOptions.push({
          label: t,
          value: t
        });
      }
      self.pmsIntegrationDataToShow = JSON.parse(JSON.stringify(self.pmsIntegrationData));
      self.totalRecords = self.pmsIntegrationDataToShow.length;
      self.disableAll = false;
      self.loaderService.stopLoader();
      self.selectUploadMethod(null, 'contacts');
    }, (err) => {
      this.loaderService.stopLoader();
      console.error('There was an error retrieving contacts: ', err);
    });
  }

  showToken(token) {
    var tokensToHide = [
      'bannerImage',
      'centerImage',
      'centerLogo',
      'leftLogo',
      'onlineApplicationUrl',
      'footerContent',
      'type',
      'created',
      'updated',
      '__v',
      '_id',
      'ownerId',
      'templateName',
      'pageName',
      'label',
      'yardiGuestCardId'
    ];
    return tokensToHide.indexOf(token) === -1;
  }
}
