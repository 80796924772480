<div id="tracking">
  <div class="page-title">
      <!-- <div class="actions left">
          <i class="far fa-bars"></i>
      </div> -->
      <h2>Tracking</h2>
      <!-- <div class="actions right">
          <i class="far fa-search"></i>
      </div> -->
  </div>
  <!-- <div class="alert alert-warning mb-0 mt-0 template-alert">
      <p class="no-margin">By default, only pages you have not sent text messages to are shown.</p>
  </div> -->
  <div class="card mt-4">
      <div class="card-body">
        <!-- <div class="card-actions">
            <div class="item">
                <i class="far fa-list-ol"></i>
                Grid
            </div>
            <div class="item active">
                <i class="far fa-border-all"></i>
                List
            </div>
        </div> -->
        <p class="table-row-indicator"><strong>Showing {{pagination.first}} to {{pagination.last}} of {{totalRecords}} rows</strong></p>
        <p-table #dt [responsive]="true" [value]="messages" class="tracking-table"
          [lazy]="true" [paginator]="true" rows="25" totalRecords="{{totalRecords}}" [showCurrentPageReport]="true"
          currentPageReportTemplate="Showing {first} to {last} of {{totalRecords}} entries"
          [rowsPerPageOptions]="[25,50,100,250]" (onPage)="paginate($event)"
        >
          <ng-template pTemplate="header">
              <tr>
                  <th>
                      Full Name
                  </th>
                  <th>
                      Page Link
                  </th>
                  <th>
                      To
                  </th>
                  <th>
                      Date
                  </th>
                  <th>
                      Status
                  </th>
              </tr>
              <tr>
                  <th>
                      <input pInputText type="text" placeholder="Search" [(ngModel)]="pageNameSearchText" (ngModelChange)="debouncedSearch(pageNameSearchText, 'pageName')">
                  </th>
                  <th></th>
                  <th></th>
                  <th>
                      <input pInputText type="text" placeholder="Search" [(ngModel)]="createdSearchText" (ngModelChange)="debouncedSearch(createdSearchText, 'created')">
                  </th>
                  <th>
                      <input pInputText type="text" placeholder="Search" [(ngModel)]="statusSearchText" (ngModelChange)="debouncedSearch(statusSearchText, 'status')">
                  </th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-message let-rowData>
              <tr>
                  <td>
                    {{message.pageName}}
                  </td>
                  <td>
                    <span *ngIf="message.pageId === 'NONE' || !message.pageId">No Page sent</span>
                    <a *ngIf="message.pageId !== 'NONE' && message.pageId" [href]="'https://sites.customnurturepages.com?id=' + message.pageId + '&track=false'" target="_blank">View Page</a>
                  </td>
                  <td>
                    {{message.to}}
                  </td>
                  <td>
                    {{message.created | date}}
                  </td>
                  <td class="text-center">
                    {{message.status}}
                  </td>
              </tr>
          </ng-template>
        </p-table>
      </div>
  </div>
</div>