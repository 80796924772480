import { Component, Input } from '@angular/core';

@Component({
  selector: 'interestlistemailpreview',
  templateUrl: 'interest-list-email-preview.component.html',
  styleUrls: ['interest-list-email-preview.component.less']
})
export class InterestListEmailPreviewComponent {

  @Input() emailData: any;

  constructor() {
    //no-op
  }
}
  