import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// used to create fake backend
// import { fakeBackendProvider } from './_helpers';

import { AppComponent } from './app.component';
import { appRoutingModule } from './app.routing';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { LoginComponent } from './login';
import { PagesComponent } from './pages';
import { BillingComponent, IntegrationsComponent, MediaComponent, SettingsComponent } from './settings';
import { TrackingComponent } from './tracking';
import { ToastsContainer } from './toast'
import { SnakeCasePipe} from './_helpers/snakeCase.pipe';
import { PageFilterPipe } from './_helpers/page-filter.pipe';
import { FilterPipe } from './_helpers/filter.pipe';
import { MessageFilterPipe } from './_helpers/message-filter.pipe';
import { PhonePipe } from './_helpers/phone.pipe';
import { CamelCasePipe } from './_helpers/camelCase.pipe';
import { 
    MediaModalComponent,
    SFSFederalTemplateComponent,
    MasterNurtureTemplateComponent
} from './modals';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { ForgotPasswordComponent } from './forgot-password';
import { PasswordResetComponent } from './password-reset';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SheetJSComponent } from './sheetjs';
import { ConcessionEmailPreviewComponent, GuestCardEmailPreviewComponent, InterestListEmailPreviewComponent } from './templates/email-previews';
import { DashboardComponent } from './dashboard';
import { TemplatesComponent, TemplateCreateComponent, TemplatePickerComponent, TemplateCreateNonMultifamilyComponent } from './templates';
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { AnalyticsComponent } from './analytics';
import { ContactComponent } from './contact';
import { TwoWayComponent } from './two-way';
import { AutomationComponent, SingleAutomationComponent } from './automation';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AdminComponent } from './admin';
import { ContactsComponent } from './contacts';
import { AccountComponent } from './settings/account/account.component';
import { DefaultsComponent } from './settings/defaults/defaults.component';
import { ComplianceComponent } from './settings/compliance/compliance.component';

export var options: Partial<IConfig> | (() => Partial<IConfig>);
const ngWizardConfig: NgWizardConfig = {
    theme: THEME.default
};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        appRoutingModule,
        NgbModule,
        FormsModule,
        AutocompleteLibModule,
        TableModule,
        MultiSelectModule,
        CalendarModule,
        ChipsModule,
        NgxMaskModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        NgWizardModule.forRoot(ngWizardConfig),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    declarations: [
        AppComponent,
        TemplatesComponent,
        DashboardComponent,
        ComplianceComponent,
        IntegrationsComponent,
        ContactComponent,
        PagesComponent,
        LoginComponent,
        ForgotPasswordComponent,
        PasswordResetComponent,
        SettingsComponent,
        BillingComponent,
        AccountComponent,
        DefaultsComponent,
        AdminComponent,
        AutomationComponent,
        MediaComponent,
        TrackingComponent,
        MediaModalComponent,
        PageFilterPipe,
        FilterPipe,
        MessageFilterPipe,
        CamelCasePipe,
        PhonePipe,
        SnakeCasePipe,
        ToastsContainer,
        SFSFederalTemplateComponent,
        MasterNurtureTemplateComponent,
        SheetJSComponent,
        GuestCardEmailPreviewComponent,
        ConcessionEmailPreviewComponent,
        InterestListEmailPreviewComponent,
        TemplateCreateComponent,
        TemplatePickerComponent,
        TemplateCreateNonMultifamilyComponent,
        AnalyticsComponent,
        SingleAutomationComponent,
        ContactComponent,
        ContactsComponent,
        TwoWayComponent
    ],
    entryComponents: [
        MediaModalComponent,
        SFSFederalTemplateComponent,
        MasterNurtureTemplateComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }