import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(
        private http: HttpClient,
        private apiHelper: APIHelperService
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(this.apiHelper.fillUrl('login', {}, {}), { email, password })
            .pipe(map(user => {

                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user.result));
                this.currentUserSubject.next(user.result);
                return user.result;
            }));
    }

    getPasswordResetLink(email: string) {
      return this.http.post<any>(this.apiHelper.fillUrl('resetPasswordLink', {}, {}), { email })
        .pipe(map(response => {
          return response.result;
        }));
    }

    updatePassword(password: string, token: string) {
      return this.http.post<any>(this.apiHelper.fillUrl('updatePassword', {}, { token }), { password })
        .pipe(map(response => {
          return response.result;
        }))
    }

    register(email: string, password: string, passwordMatch: string) {
        return this.http.post<any>(this.apiHelper.fillUrl('register', {}, {}), { email, password, passwordMatch })
            .pipe(map(user => {
                localStorage.setItem('currentUser', JSON.stringify(user.result));
                this.currentUserSubject.next(user.result);
                return user.result;
            }));
    }

    agreeToTerms() {
        return this.http.post<any>(this.apiHelper.fillUrl('terms', {}, {}), {})
            .pipe(map(response => {
                return response;
            }));
    }

    logout() {

        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('mimic');
        this.currentUserSubject.next(null);
    }

    getAllAccounts() {
        return this.http.get<any>(this.apiHelper.fillUrl('adminAccounts', {}, {}))
            .pipe(map(response => {
                return response;
            }));
    }

    mimick(id) {
        return this.http.get<any>(this.apiHelper.fillUrl('mimicAccount', {id: id}, {}))
            .pipe(map(response => {
                return response;
            }));
    }
}