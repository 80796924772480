import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { AnalyticsService, ToastService, LoaderService } from '@app/_services';
import { Chart } from 'chart.js';
import * as _ from 'lodash';
import {NgbDate, NgbCalendar, NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

var self;

@Component({
  templateUrl: 'analytics.component.html',
  styleUrls: ['analytics.component.less']
})
export class AnalyticsComponent implements OnInit {
  @ViewChildren('chartCanvas') elemQueryResults: QueryList<any>;
  @ViewChild('datepicker') datePicker: NgbInputDatepicker;

  loading: string = 'none';
  totalEventsCanvas: ElementRef<HTMLCanvasElement>;
  prospectCanvas: ElementRef<HTMLCanvasElement>;
  topTenProspectCanvas: ElementRef<HTMLCanvasElement>;
  eventDetailsByProspect: ElementRef<HTMLCanvasElement>;
  colorArray: Array<string> = [
    '#40B9F5',
    '#2F88B5',
    '#1E5875',
    '#0E2836',
    '#39A5DB'
  ];
  totalEventsConfig: any;
  prospectEventsConfig: any;
  topTenProspectConfig: any;
  eventTypeProspectDetailConfig: any;
  byday:any = {};
  generalEventLabels: Array<any> = [];
  generalEventData: Array<any> = [];
  prospectEventLabels: Array<any> = [];
  prospectEventData: Array<any> = [];
  topTenProspectEventLabels: Array<any> = [];
  topTenProspectEventData: Array<any> = [];
  groupByProspectKeep: any;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  exportReportData: any;
  selectedStartDate: any;
  selectedEndDate: any;
  eventTypes: any = {};
  sortedEventTypeKeys: Array<string> = [];

  constructor(
    private analyticsService: AnalyticsService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    this.loading = 'none';
    this.fromDate = this.calendar.getPrev(calendar.getToday(), 'd', 30);
    this.toDate = this.calendar.getToday();
    self = this;    
    this.runQuery();
  }

  runQuery(startDate?, endDate?) {
    this.selectedStartDate = startDate;
    this.selectedEndDate = endDate;
    this.loaderService.triggerLoader();

    // Retrieve analytics
    this.analyticsService.runQuery(startDate, endDate).subscribe((data) => {
      
      self.eventTypes = {};

      // Group all events by day for "Total Events (30 Days)"
      for (var i = 0; i < data.result.length; i++) {

        if (self.eventTypes[data.result[i].eventtype]) {
          self.eventTypes[data.result[i].eventtype].push(data.result[i]);
        } else {
          self.eventTypes[data.result[i].eventtype] = [data.result[i]]
        }
        // Check for errors.
        if (data.result[i].error) {
          this.toastService.show('There was an error loading analytics', { classname: 'bg-danger text-light', delay: 5000 });
        }

        this.groupday(data.result[i])
      }

      self.sortedEventTypeKeys = Object.keys(self.eventTypes).sort();

      // Set labels and data  for "Total Events (30 Days)"
      for (var n in this.byday) {
        try {
          const d = new Date(parseInt(n) * (1000*60*60*24));
          const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d)
          const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
          var formattedDate = mo + '/' + da;
          this.generalEventLabels.push(formattedDate);
          this.generalEventData.push(this.byday[n].length);
        } catch (e) {
          console.error('Error translating timestamp: ', e);
          continue;
        }
      }

      // Send data to chart.js for "Total Events (30 Days)"
      this.totalEventsConfig.data.labels = this.generalEventLabels;
      this.totalEventsConfig.data.datasets[0].data = this.generalEventData;

      // Group all events for "Events By All Prospects (30 Days)"
      var groupedByProspect:any = _.groupBy(data.result, 'pagename');
      self.groupByProspectKeep = groupedByProspect;
      groupedByProspect = Object.keys(groupedByProspect)
        .map(function(k) { return { key: k, value: groupedByProspect[k] }; })
        .sort(function(a, b) { return b.value.length - a.value.length; });

      // Set labels and data for "Events By All Prospects (30 Days)"
      for (var k = 0; k < groupedByProspect.length; k++) {
        this.prospectEventLabels.push(groupedByProspect[k].key);
        this.prospectEventData.push(groupedByProspect[k].value.length);
      }

      // Send data to chart.js for "Events By All Prospects (30 Days)"
      this.prospectEventsConfig.data.labels = this.prospectEventLabels;
      this.prospectEventsConfig.data.datasets[0].data = this.prospectEventData;

      // Send data to chart.js for "Events By Top 10 Prospects (30 Days)"
      this.topTenProspectConfig.data.labels = this.prospectEventLabels.slice(0,10);
      this.topTenProspectConfig.data.datasets[0].data = this.prospectEventData.slice(0,10);

      var dataSetVar = [];
      var colorNumber = 0;
      for (var h in self.groupByProspectKeep) {

        // Group by event type
        var eventGroups = {};
        for (var t = 0; t < self.groupByProspectKeep[h].length; t++) {
          if (eventGroups[self.groupByProspectKeep[h][t].eventtype]) {
            eventGroups[self.groupByProspectKeep[h][t].eventtype].push(self.groupByProspectKeep[h][t]);
          } else {
            eventGroups[self.groupByProspectKeep[h][t].eventtype] = [self.groupByProspectKeep[h][t]];
          }
        }

        // Push number of events into array according to alphabatized order.
        var dataArray = [];
        for (var m = 0; m < self.sortedEventTypeKeys.length; m++) {
          if (eventGroups[self.sortedEventTypeKeys[m]] && eventGroups[self.sortedEventTypeKeys[m]].length) {
            dataArray.push(eventGroups[self.sortedEventTypeKeys[m]].length);
          } else {
            dataArray.push(0);
          }
        }

        dataSetVar.push({
          label: h,
          backgroundColor: self.colorArray[colorNumber],
          data: dataArray
        });
        if (colorNumber === 5) {
          colorNumber = 0;
        } else {
          colorNumber++
        }
      }
      this.eventTypeProspectDetailConfig.data.datasets = dataSetVar;
      this.eventTypeProspectDetailConfig.data.labels = self.sortedEventTypeKeys;

      this.exportReportData = dataSetVar;

      // Capture DOM elements for charts
      this.elemQueryResults.forEach((v) => {
        if (v.nativeElement.id === 'totalEventsCanvas') {
          this.totalEventsCanvas = v;
          var ctx = this.totalEventsCanvas.nativeElement.getContext('2d');
          new Chart(ctx, this.totalEventsConfig);
          document.getElementById('totalEventsCanvas').style.height = '600px';
          document.getElementById('totalEventsCanvas').style.width = '100%';
        } else if (v.nativeElement.id === 'prospectCanvas') {
          this.prospectCanvas = v;
          var ctx = this.prospectCanvas.nativeElement.getContext('2d');
          new Chart(ctx, this.prospectEventsConfig);
          document.getElementById('prospectCanvas').style.height = '600px';
          document.getElementById('prospectCanvas').style.width = '100%';
        } else if (v.nativeElement.id === 'topTenProspectCanvas') {
          this.topTenProspectCanvas = v;
          var ctx = this.topTenProspectCanvas.nativeElement.getContext('2d');
          new Chart(ctx, this.topTenProspectConfig);
          document.getElementById('topTenProspectCanvas').style.height = '600px';
          document.getElementById('topTenProspectCanvas').style.width = '100%';
        }  else if (v.nativeElement.id === 'eventDetailsByProspect') {
          this.eventDetailsByProspect = v;
          var ctx = this.eventDetailsByProspect.nativeElement.getContext('2d');
          new Chart(ctx, this.eventTypeProspectDetailConfig);
          document.getElementById('eventDetailsByProspect').style.height = '600px';
          document.getElementById('eventDetailsByProspect').style.width = '100%';
        }
      });

      this.loading = 'block';
      this.loaderService.stopLoader();
    }, (err) => {
      console.error('ERROR: ', err);
      this.loaderService.stopLoader();
      this.toastService.show('There was an error loading analytics', { classname: 'bg-danger text-light', delay: 5000 });
      this.loading = 'block';
    });
  }

  downloadReport() {
    try {
      var headerRow = JSON.parse(JSON.stringify(self.sortedEventTypeKeys));
      headerRow.unshift('Identifier');

      var rows = [
        headerRow
      ];
  
      // Create formatted data for CSV.
      for (var i = 0; i < this.exportReportData.length; i++) {
        var row = [this.exportReportData[i].label];
        for (var t = 0; t < this.exportReportData[i].data.length; t++) {
          row.push(this.exportReportData[i].data[t])
        }
        rows.push(row);
      }
  
      let csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      var range;
      if (this.selectedStartDate && this.selectedEndDate) {
        var m = new Date(this.selectedStartDate);
        var n = new Date(this.selectedEndDate);
        var start = (m.getUTCMonth()+1) +'-'+ m.getUTCDate() +'-'+ m.getUTCFullYear();
        var end = (n.getUTCMonth()+1) +'-'+ n.getUTCDate() +'-'+ n.getUTCFullYear();
        range = start + '-To-' + end;
      } else {
        range = 'Report'; 
      }
      var fileName = 'Nurture-Boss-' + range + '.csv';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error('There was an error downloading your report: ', e);
      this.toastService.show('There was an error downloading your report', { classname: 'bg-danger text-light', delay: 5000 });
    }
  }

  retrieveNumberOfResidentPortalVisits(prospect) {
    var count = 0;
    for (var j = 0; j < this.groupByProspectKeep[prospect].length; j++) {
      if (this.groupByProspectKeep[prospect][j].eventtype === 'Resident Portal Visit') {
        count++;
      }
    }
    return count;
  }

  ngOnInit() {
    Chart.Tooltip.positioners.custom = function(elements, position) {
      if (!elements.length) {
        return false;
      }
      var offset = 0;
      //adjust the offset left or right depending on the event position
      if (elements[0]._chart.width / 2 > position.x) {
        offset = 20;
      } else {
        offset = -20;
      }
      return {
        x: position.x + offset,
        y: position.y
      }
    }
    this.totalEventsConfig = {
			type: 'horizontalBar',
			data: {
				datasets: [{
          barPercentage: 0.9,
          barThickness:'flex',
					label: 'Number of Events',
					backgroundColor: this.colorArray[0],
          borderColor: this.colorArray[0],
          borderWidth: 1,
					fill: 'origin',
				}]
			},
			options: {
				responsive:true,
        maintainAspectRatio: false,
				title: {
					display: false
        },
        legend: {
          display: false
        },
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
				scales: {
          gridLines: {
            color: 'rgba(0,0,0,1)'
          },
					x: {
						display: true
					},
					y: {
						display: true
          },
          xAxes: [{
            gridLines: {
              display:false
            },
            drawOnChartArea: false,
            display: true
          }],
          yAxes: [{
            gridLines: {
              display:false
            },
            drawOnChartArea: false,
            display: true
          }]
				}
			}
    };
    this.prospectEventsConfig = {
			type: 'horizontalBar',
			data: {
				datasets: [{
          barPercentage: 0.9,
          barThickness:'flex',
					label: 'Number of Events',
					backgroundColor: this.colorArray[1],
          borderColor: this.colorArray[1],
          borderWidth: 1,
					fill: 'origin',
				}]
			},
			options: {
				responsive:true,
        maintainAspectRatio: false,
				title: {
					display: false
        },
        legend: {
          display: false
        },
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
				scales: {
          gridLines: {
            color: 'rgba(0,0,0,1)'
          },
					x: {
						display: true
					},
					y: {
						display: true
          },
          xAxes: [{
            gridLines: {
              display:false
            },
            drawOnChartArea: false,
            display: true
          }],
          yAxes: [{
            gridLines: {
              display:false
            },
            drawOnChartArea: false,
            display: true
          }]
				}
			}
    };
    this.topTenProspectConfig = {
			type: 'horizontalBar',
			data: {
				datasets: [{
          barPercentage: 0.9,
          barThickness:'flex',
					label: 'Number of Events',
					backgroundColor: this.colorArray[2],
          borderColor: this.colorArray[2],
          borderWidth: 1,
					fill: 'origin',
				}]
			},
			options: {
				responsive:true,
        maintainAspectRatio: false,
				title: {
					display: false
        },
        legend: {
          display: false
        },
				tooltips: {
					mode: 'index',
					intersect: false,
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
				scales: {
          gridLines: {
            color: 'rgba(0,0,0,1)'
          },
					x: {
						display: true
					},
					y: {
						display: true
          },
          xAxes: [{
            gridLines: {
              display:false
            },
            drawOnChartArea: false,
            display: true
          }],
          yAxes: [{
            gridLines: {
              display:false
            },
            drawOnChartArea: false,
            display: true
          }]
				}
			}
    };
    this.eventTypeProspectDetailConfig = {
      type: 'bar',
			data: {
        labels: ['Page Visits', 'Click To Call', 'Click To Email', 'Apply Online', 'Visit Website'],
				datasets: []
			},
			options: {
				responsive:true,
        maintainAspectRatio: false,
				title: {
					display: false
        },
        legend: {
          display: false
        },
				tooltips: {
          position: 'custom'
				},
				hover: {
					mode: 'nearest',
					intersect: true
				},
				scales: {
          gridLines: {
            color: 'rgba(0,0,0,1)'
          },
					x: {
						display: true
					},
					y: {
						display: true
          },
          xAxes: [{
            gridLines: {
              display:false
            },
            drawOnChartArea: false,
            display: true,
            stacked: true
          }],
          yAxes: [{
            gridLines: {
              display:false
            },
            drawOnChartArea: false,
            display: true,
            stacked: true
          }]
				}
			}
		};
  }

  groupday(value) {
    var d = new Date(parseInt(value['time']));
    var correctedDate = Math.floor(d.getTime()/(1000*60*60*24));
    this.byday[correctedDate] = this.byday[correctedDate]||[];
    this.byday[correctedDate].push(value);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  triggerUpdate(e) {
    e.preventDefault();
    if (this.fromDate && this.toDate) { 
      var startDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).getTime();
      var endDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day).getTime();
      this.runQuery(startDate, endDate);
      this.datePicker.close();
    }
  }

  close() {
    this.datePicker.close();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}