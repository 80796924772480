import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'snakecase'
})
export class SnakeCasePipe implements PipeTransform {
  transform(word: string): string {
    if(!word) return '';
    word = word.toLowerCase();
    var splitWord = word.split('_');
    word = splitWord.join(' ');
    return word;
  }
}
