<div class="page-title">
  <h2>Account</h2>
</div>
<div class="page">

  <!-- USER SETTINGS -->
  <div class="overflow-auto margin-bottom-24">
    <h6 class="title pull-left">Account Information</h6>
    <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="saveTimezone()">
      <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
      Save Settings
    </button>
  </div>
  <div class="user-table">
    <div>
      <h6>Username:</h6>
      {{userData.user.email}}
    </div>
    <div>
      <h6>Timezone:</h6>
      <div class="form-group">
        <select class="form-control" [(ngModel)]="settings.timezone" [ngModelOptions]="{standalone: true}">
          <option value="America/Los_Angeles">America/Los_Angeles (Pacific)</option>
          <option value="America/Phoenix">America/Phoenix</option>
          <option value="America/Denver">America/Denver (Mountain)</option>
          <option value="America/Chicago">America/Chicago (Central)</option>
          <option value="America/New_York">America/New_York (Eastern)</option>
        </select>
      </div>
    </div>
    <div *ngIf="userData.user.claims.indexOf('real estate') === -1 || userData.user.claims.indexOf('admin') > -1">
      <h6>Property Name:</h6>
      {{userData.user.propertyName}}
    </div>
    <div *ngIf="userData.user.claims.indexOf('real estate') === -1 || userData.user.claims.indexOf('admin') > -1">
      <h6>Property Address:</h6>
      {{userData.user.address}}
    </div>
  </div>
</div>