import { Component, OnInit} from '@angular/core';
import { LoaderService, TemplatesService, ToastService } from '@app/_services';
import { AutomationSettingsService } from '@app/_services/automationSettings.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-single-automation',
  templateUrl: './single-automation.component.html',
  styleUrls: ['./single-automation.component.less']
})
export class SingleAutomationComponent implements OnInit {

  automationType: string;
  automationConfig: any = {};
  steps: any;
  savingAutomation: boolean = false;
  validDefaults: any = {};

  constructor(
    private loaderService: LoaderService,
    private automationSettingsService: AutomationSettingsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private templatesService: TemplatesService
  ) {
    //no-op
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.automationType = params['type'];
      if (!this.automationType) {
        this.router.navigate(['automation']);
      } else  {
        this.loadData();
      }
  });
  }

  loadData() {

    // Get user settings.
    this.loaderService.triggerLoader();
    this.automationSettingsService.getSingleAutomation(this.automationType).subscribe((data) => {
      this.automationConfig = data.result;
      this.steps = data.result.data.steps.sort(function(a, b) {
        return a - b;
      });

      // Get template defaults for each step.
      this.validDefaults = {};
      for (var i = 0; i < this.steps.length; i++) {
        this.validateDefatuls(this.steps[i].pageTemplate, this.steps[i]);
      }
      this.loaderService.stopLoader();
    }, (err) => {
      this.loaderService.stopLoader();
      console.error('There was an error retrieving automation config: ', err);
    });
  }

  goBack(e) {
    e.preventDefault();
    this.router.navigate(['../automation']);
  }

  saveConfiguration() {
    this.savingAutomation = true;
    this.automationSettingsService.updateSingleAutomation(this.automationConfig).subscribe((data) => {
      this.toastService.show('Configuration saved!', {
        classname: 'bg-success text-light',
        delay: 5000
      });
      this.automationConfig = data.result;
      this.steps = data.result.data.steps;
      this.savingAutomation = false;
    }, (err) => {
      console.error('There was an error updating your automation config ', err);
      this.toastService.show(err, {
        classname: 'bg-danger text-light',
        delay: 5000
      });
      this.savingAutomation = false;
    });
  }

  validateDefatuls(pageType, step) {
    return new Promise((resolve) => {
      this.templatesService.getTemplateDefaults(pageType).subscribe((response) => {
        if (response.result.length === 0) {
          this.validDefaults[step.stepId] = false;
          resolve('');
        } else {
          if (pageType === 'Guest Card') {
            if (
              !response.result.yourEmailAddress ||
              !response.result.yourPhoneNumber ||
              !response.result.yourName ||
              !response.result.bannerImage ||
              !response.result.yourWebsite ||
              !response.result.onlineApplicationUrl ||
              !response.result.leftLogo
            ) {
              this.validDefaults[step.stepId] = false;
              resolve('');
            } else {
              this.validDefaults[step.stepId] = true;
              resolve('');
            }
          } else if (pageType === 'Apartment Concession') {
            if (
              !response.result.yourEmailAddress ||
              !response.result.yourPhoneNumber ||
              !response.result.yourName ||
              !response.result.bannerImage ||
              !response.result.yourWebsite ||
              !response.result.onlineApplicationUrl ||
              !response.result.leftLogo ||
              !response.result.concession
            ) {
              this.validDefaults[step.stepId] = false;
              resolve('');
            } else {
              this.validDefaults[step.stepId] = true;
              resolve('');
            }
          } else if (pageType === 'Apartment Interest List') {
            if (
            !response.result.yourEmailAddress ||
            !response.result.yourPhoneNumber ||
            !response.result.yourName ||
            !response.result.bannerImage ||
            !response.result.yourWebsite ||
            !response.result.mainText ||
            !response.result.leftLogo
            ) {
              this.validDefaults[step.stepId] = false;
              resolve('');
            } else {
              this.validDefaults[step.stepId] = true;
              resolve('');
            }

          } else {
            this.validDefaults[step.stepId] = false;
            resolve('');
          }
        }
      }, (e) => {
        this.validDefaults[step.stepId] = false;
        console.error('Error retrieving template defalts ', e);
        resolve('');
      });
    });
  }

  templateTypeChange(step) {
    this.loaderService.triggerLoader();
    this.validateDefatuls(step.pageTemplate, step).then(() => {
      this.loaderService.stopLoader();
    });
  }
}
