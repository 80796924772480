import {
  Component,
  OnInit
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import {
  PagesService,
  ToastService,
  TokenParserService,
  AuthenticationService,
  TemplatesService,
  LoaderService,
  TextMessagesService
} from '@app/_services';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplateRef, ViewChild } from '@angular/core';
import {NgbDate, NgbCalendar, NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

var self;

@Component({
  templateUrl: 'pages.component.html',
  styleUrls: ['pages.component.less']
})
export class PagesComponent implements OnInit {

  @ViewChild('datepicker') datePicker: NgbInputDatepicker;
  @ViewChild('sentTextModal') private sentTextModal: TemplateRef<any>;

  objectKeys = Object.keys;
  sendTextMessageForm: FormGroup;
  pages: Array < any > = [];
  modalOptions: NgbModalOptions;
  activeTextData: any = {
    data: {}
  };
  sendingText: boolean = false;
  remainingCharacterCount: Function = function() {
    return 1600;
  };
  messagePreview: Function = function() {
    return '';
  };
  userData: any;
  textTemplates: any;
  saveTextTemplateActive: boolean = false;
  loadTextTemplateActive: boolean = false;
  textTemplateSaveInAction: boolean = false;
  textTemplateName: string;
  selectedPages: Array<string> = [];
  selectedColumns: any;
  bulkText: boolean = false;
  bulkTextSendPages: Array<any> = [];
  pagesRemoved: boolean = false;
  cols: Array<any>;
  errorMessage:any = null;
  totalRecords: any;
  debouncedSearch: any;
  pageNameSearchText: string = '';
  labelSearchText: string = '';
  pageTypeSearchText: string = '';
  activeQuery: any = {
    query: '',
    property: ''
  };
  pagination: any = {
    first: 1,
    last: 25
  };
  showAllPages: boolean = false;
  defaultMessageDict: any = {
    'Apartment Concession': 'Hey [clientFirstName]! [apartmentName] is offering [concession] on new move ins! Take a look at this deal we\'ve made just for you! [shortUrl]',
    'Guest Card': 'Hey [clientFirstName]! [apartmentName] has some new availability and rates! Take a look at this page we made just for you! [shortUrl]',
    'Apartment Tour': 'Hey [clientFirstName]! This is [yourName] from [apartmentName]. I wanted to follow up on our tour, I\'ve prepared this page just for you! [shortUrl]',
    'Move In Checklist': 'Hey [clientFirstName]! Congratulations on your new apartment at [apartmentName]! Click this link for the move in checklist I created for you! [shortUrl]',
    'Apartment Renewal': 'Hi [clientFirstName]! As a reminder, your lease at [apartmentName] is set to expire soon. Click here for a renewal offer created just for you! [shortUrl]',
    'Apartment Maintenance Followup': 'Hey [clientFirstName]! I wanted to follow up on your recent maintenance request, please share your experience and any feedback! [shortUrl]',
    'Apartment Event Reminder': 'Hey [clientFirstName]! [apartmentName] is hosting [eventName] on [eventDate] and you\'re invited! Click here for more details! [shortUrl]',
    'The Royce Rent Reminder': 'Hey [clientFirstName]! This is a reminder that rent is due on the 1st, click here to look at our Flex options! [shortUrl]',
    'Bella Posta Remodel Relocation': 'Hey [clientFirstName]! [apartmentName] will be posting a notice on your door regarding your lease renewal. Please click here to get more info [shortUrl]',
    'Rent Reminder': 'Hey [clientFirstName]! This is a friendly rent reminder, click here to see more details! [shortUrl]',
    'Revere Rent Reminder': 'Hey [clientFirstName]! This is a friendly rent reminder, click here to see more details! [shortUrl]',
    'Apartment Appointment Reminder': 'Hey [clientFirstName]! This is a friendly reminder of your upcoming appointment on [appointmentDate] at [appointmentTime]! Click here for more details [shortUrl]',
    'Master Nurture Template': 'Hey [clientFirstName]!, This is [yourName], take a look at this page I made just for you! [shortUrl]',
    'Paseo Renewal': 'Hi [clientFirstName]! As a reminder this is the last week to accept your current renewal offer & stay in your current unit at [apartmentName]. Please log into your portal today and accept the renewal offer. Happy Holidays! [shortUrl]',
    'Digital Business Card': 'Hi [clientFirstName]! [yourName] here, it was great connecting today, you can find all my contact info here: [shortUrl]',
    'Real Estate General Communication': 'Hi [clientFirstName]! [yourName] here, it was great connecting today, you can find all my contact info here: [shortUrl]',
    'Apartment Interest List': 'Hi [clientFirstName]! [apartmentName] would like to invite you to join our VIP waiting list! Click here to learn more [shortUrl]',
    'Apartment Covid Screening': 'Hi [clientFirstName]! Please take a look at our COVID screening checklist before your appointment at [apartmentName]: [shortUrl]',
    'Harper Self Guided Tour': 'Hi [clientFirstName]!  You can find instructions for your self guided tour at [apartmentName] by clicking this link: [shortUrl]',
    'Harper Move In Checklist': 'Hey [clientFirstName]! Congratulations on your new apartment at [apartmentName]! Click this link for the move in checklist I created for you! [shortUrl]',
    'Apartment Accepting Applications': 'Hi [clientFirstName]!  [apartmentName] is excited to announce that we are now accepting applications! Click here for more info [shortUrl]'
  };
  modalActionTitle:string;
  fromCreateState: any;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  sendDate: any;
  sendTime: any;
  schedules: any;
  activeSchedules: number;
  scheduledPages: number;
  schedSaveInProgress: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private pagesService: PagesService,
    private toastService: ToastService,
    private tokenService: TokenParserService,
    private authService: AuthenticationService,
    private templatesService: TemplatesService,
    private loaderService: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private textMessageService: TextMessagesService
  ) {
    this.modalOptions = {
      windowClass: "custom-modal-styles-new text-modal"
    };
    this.userData = this.authService.currentUserValue;
    this.debouncedSearch = _.debounce(this.searchFunc, 300);
    this.fromDate = this.calendar.getPrev(calendar.getToday(), 'd', 30);
    this.toDate = this.calendar.getToday();
    self = this;
  }

  searchFunc(query, property) {
    this.activeQuery = {
      query: query,
      property: property
    };
    this.showAllPages = true;
    this.loadData(query, property);
  }

  loadData(query?, property?, load?) {
    this.loaderService.triggerLoader();
    this.pagesService.getAll(0, 25, query, property, this.showAllPages).subscribe(pages => {
      this.pages = pages.result.data;
      this.totalRecords = pages.result.totalPages;
      this.loaderService.stopLoader();
    }, (error) => {
      console.error('There was an error retrieving pages: ', error);
      this.loaderService.stopLoader();
      this.toastService.show('There was an error loading your pages', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
    });
  }

  ngOnInit() {
    this.loadData(null, null, true);
    this.pagesService.retrieveSchedules().subscribe((data) => {
      this.schedules = data.result;
      this.activeSchedules = 0;
      this.scheduledPages = 0;
      for (var i = 0; i < this.schedules.length; i++) {
        if (!this.schedules[i].acknowledged) {
          this.activeSchedules++;
          this.scheduledPages = this.scheduledPages + this.schedules[i].numberOfPages;
        }
      }
    }, (err) => {
      console.error('There was an error retrieving schedules! ', err);
    });
  }

  ngAfterViewInit() {

    // Capture the page type we are creating!
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['fromCreate'] === 'true' && window.history.state.pagesCreated) {
        this.sendNewText(this.sentTextModal, window.history.state.pagesCreated);
      }
    });
  }

  paginate(event) {
    this.loaderService.triggerLoader();
    this.pagination.first = event.first;
    this.pagination.last = event.first + event.rows;
    this.pagesService.getAll(event.first, event.rows, this.activeQuery.query, this.activeQuery.property, this.showAllPages).subscribe(pages => {
      this.pages = pages.result.data;
      this.totalRecords = pages.result.totalPages;
      this.loaderService.stopLoader();
    }, (error) => {
      console.error('There was an error retrieving pages: ', error);
      this.loaderService.stopLoader();
      this.toastService.show('There was an error loading your pages', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
    });
  }

  sendNewText(textContent, page) {
    
    // Create copy as to not affect original.
    var page = JSON.parse(JSON.stringify(page));
    this.errorMessage = null;
    this.pagesRemoved = false;

    if (page.length) {

      var pages = page

      // We are bulk sending texts.
      this.bulkText = true;
      this.bulkTextSendPages = [];
      
      // First weed out pages that do not have a clientPhoneNumber
      for (var i = 0; i < pages.length; i++) {

        // Add sanitized number to array.
        if (pages[i].data.clientPhoneNumber && pages[i].data.clientPhoneNumber.toString().length > 0) {
          pages[i].data.clientPhoneNumber = pages[i].data.clientPhoneNumber.toString().replace(/\D/g, '')
          this.bulkTextSendPages.push(pages[i]);
        } else {
          this.pagesRemoved = true;
        }
      }

      // Now get all unique tokens.
      for (var i = 0; i < this.bulkTextSendPages.length; i++) {
        this.bulkTextSendPages[i].data.shortUrl = this.bulkTextSendPages[i].shortUrl;
        for (var prop in this.bulkTextSendPages[i].data) {
          if (!this.activeTextData.data[prop]) {
            this.activeTextData.data[prop] = true;
          }
        }
      }

      // Add fields we need
      this.activeTextData.data.shortUrl = true;

      // Remove properties we do not want to include.
      delete this.activeTextData.data.bannerImage;
      delete this.activeTextData.data.centerImage;
      delete this.activeTextData.data.centerLogo;
      delete this.activeTextData.data.leftLogo;
      delete this.activeTextData.data.onlineApplicationUrl;
      delete this.activeTextData.data.footerContent;
      delete this.activeTextData.data.type;
      delete this.activeTextData.data.created;
      delete this.activeTextData.data.updated;
      delete this.activeTextData.data.__v;
      delete this.activeTextData.data._id;
      delete this.activeTextData.data.ownerId;
      delete this.activeTextData.data.templateName;
      delete this.activeTextData.data.pageName;
      delete this.activeTextData.data.label;
      delete this.activeTextData.data.yardiGuestCardId;

      this.sendTextMessageForm = this.formBuilder.group({
        textMessageContent: ['', Validators.required]
      });

      // Set default message depending on what pages are selected.
      var defaultMessage;
      var sameType = true;
      for (var i = 0; i < pages.length; i++) {
        if (pages[0].data.type !== pages[i].data.type) {
          sameType = false;
          break;
        }
      }
      if (sameType) {

        // Load default message for this type
        defaultMessage = this.defaultMessageDict[pages[0].data.type];
      } else {

        // Generic default message.
        defaultMessage = 'Hello [clientFirstName]! [apartmentName] has some new availability and rates! Take a look at this page we made just for you! [shortUrl]';
      }
      this.sendTextMessageForm.controls.textMessageContent.setValue(defaultMessage);
      this.remainingCharacterCount = function() {
        return 1600 - this.sendTextMessageForm.controls.textMessageContent.value.length;
      }
      this.messagePreview = function() {
        return this.tokenService.replaceTokens(this.sendTextMessageForm.controls.textMessageContent.value, this
          .bulkTextSendPages[0].data);
      }

      this.modalService.open(textContent, this.modalOptions).result.then((result) => {
        this.activeTextData= {
          data: {}
        };
        window.location.reload();
      }, () => {
        this.activeTextData= {
          data: {}
        };
      });
    } else {

      this.bulkText = false;
      this.bulkTextSendPages.length = 0;

      // Set default message depending on what page is selected.
      var defaultMessage = this.defaultMessageDict[page.data.type];

      // Remove properties we do not want to include.
      delete page.data.bannerImage;
      delete page.data.centerImage;
      delete page.data.centerLogo;
      delete page.data.leftLogo;
      delete page.data.onlineApplicationUrl;
      delete page.data.footerContent;
      delete page.data.type;
      delete page.data.created;
      delete page.data.updated;
      delete page.data.__v;
      delete page.data._id;
      delete page.data.ownerId;
      delete page.data.templateName;
      delete page.data.pageName;
      delete page.data.label;
      delete page.data.yardiGuestCardId;
  
      // Add shortUrl as token.
      page.data.shortUrl = page.shortUrl;
      if (page.data.clientPhoneNumber) {
        page.data.clientPhoneNumber = page.data.clientPhoneNumber.toString().replace(/\D/g, '');
      }
      this.activeTextData = page;
      this.sendTextMessageForm = this.formBuilder.group({
        recipientPhoneNumber: [page.data.clientPhoneNumber || '', Validators.required],
        textMessageContent: ['', Validators.required]
      });
      this.sendTextMessageForm.controls.textMessageContent.setValue(defaultMessage);
      this.remainingCharacterCount = function() {
        return 1600 - this.sendTextMessageForm.controls.textMessageContent.value.length;
      }
      this.messagePreview = function() {
        return this.tokenService.replaceTokens(this.sendTextMessageForm.controls.textMessageContent.value, this
          .activeTextData.data);
      }
      this.modalService.open(textContent, this.modalOptions).result.then((result) => {
        this.activeTextData = {
          data: {}
        };
        window.location.reload();
      }, () => {
        this.activeTextData = {
          data: {}
        };
      });
    }

  }

  deliverText(modal, bulk) {
    if (this.sendTextMessageForm.invalid) {
      return;
    } else {
      this.errorMessage = null;
      this.sendingText = true;
      var postBody;
      if (bulk) {
        var arrayOfPageIds = this.bulkTextSendPages.map(function (obj) {
          return obj._id;
        });
        postBody = {
          message: this.sendTextMessageForm.controls.textMessageContent.value,
          pageIds: arrayOfPageIds
        }
        this.pagesService.sendBulkText(postBody).subscribe((data) => {
          this.sendingText = false;
          this.toastService.show('Text message sent.', {
            classname: 'bg-success text-light',
            delay: 5000
          });
          modal.close('Success');
        }, (err) => {
          this.sendingText = false;
          console.error('There was an error sending your text ', err);
          this.toastService.show('There was an error sending your text, please try again.', {
            classname: 'bg-danger text-light',
            delay: 5000
          });
        });
      } else {
        postBody = {
          to: this.sendTextMessageForm.controls.recipientPhoneNumber.value,
          message: this.sendTextMessageForm.controls.textMessageContent.value,
          pageId: this.activeTextData._id
        }
        this.textMessageService.sendText(postBody).subscribe(() => {
          this.sendingText = false;
          this.toastService.show('Text message sent.', {
            classname: 'bg-success text-light',
            delay: 5000
          });
          modal.close('Success');
        }, (err) => {
          this.sendingText = false;
          console.error('There was an error sending your text ', err);
          this.toastService.show('There was an error sending your text, please try again.', {
            classname: 'bg-danger text-light',
            delay: 5000
          });
          this.errorMessage = err;
        });
      }
    }

  }

  saveTextAsTemplate(e, modal) {
    e.preventDefault();
    this.textTemplateSaveInAction = true;
    this.templatesService.newTextTemplate({
      message: this.sendTextMessageForm.controls.textMessageContent.value,
      name: this.textTemplateName
    }).subscribe((data) => {
      this.saveTextTemplateActive = false;
      this.textTemplateSaveInAction = false;
      modal.dismiss();
      this.toastService.show('Text template saved.', {
        classname: 'bg-success text-light',
        delay: 5000
      });
    }, (e) => {
      console.error('There was an error saving text template: ', e);
      this.toastService.show('There was an error saving text template, please try again.', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
      this.saveTextTemplateActive = false;
      this.textTemplateSaveInAction = false;
    });
  }

  triggerSaveTextAsTemplate(modal, e) {
    e.preventDefault();
    this.modalActionTitle = 'Save New Text Template';
    this.textTemplateName = null;
    this.textTemplates = null;
    this.saveTextTemplateActive = true;
    this.modalService.open(modal, {
      windowClass: "custom-modal-styles-new text-action-modal"
    }).result.then((result) => {
      this.modalActionTitle = '';
      this.saveTextTemplateActive = false;
    }, () => {
      this.modalActionTitle = '';
      this.saveTextTemplateActive = false;
    });
  }

  loadFromExistingTextTemplate(modal, e) {
    e.preventDefault();
    this.modalActionTitle = 'Load Existing Template';
    this.loadTextTemplateActive = true;
    this.templatesService.getTextTemplates().subscribe((data) => {
      this.textTemplates = data.result;
      this.modalService.open(modal, {
        windowClass: "custom-modal-styles-new text-action-modal"
      }).result.then((result) => {
        this.modalActionTitle = '';
        this.loadTextTemplateActive = false;
      }, () => {
        this.modalActionTitle = '';
        this.loadTextTemplateActive = false;
      });
    }, (e) => {
      console.error('There was an error retrieving text template: ', e);
      this.toastService.show('There was an error loading your text templates', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
    });
  }

  openScheduleModal(modal, e) {
    e.preventDefault();
    this.modalService.open(modal, {
      windowClass: "custom-modal-styles-new schedule-text-modal"
    }).result.then((result) => {
      this.sendDate = null;
      this.sendTime = null;
      window.location.reload();
    }, () => {
      this.sendDate = null;
      this.sendTime = null;
    });
  }

  selectTextTemplate(e, template, modal) {
    e.preventDefault();
    this.sendTextMessageForm.controls.textMessageContent.setValue(template.message);
    this.textTemplates = null;
    modal.close();
  }

  deleteTextTemplate(templateId, index, e) {
    e.stopPropagation();
    this.textTemplates.splice(index, 1);
    this.templatesService.deleteTextTemplate(templateId).subscribe(() => {
      this.toastService.show('Text template deleted', {
        classname: 'bg-success text-light',
        delay: 5000
      });
    }, () => {
      this.toastService.show('There was an error deleting your text template', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
    });
  }

  exitModal(e, modal) {
    e.preventDefault();
    modal.dismiss();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isToday(date: NgbDate) {
    return date.equals(this.toDate);
  }

  saveSchedule(modal) {
    var postBody: any;
    this.schedSaveInProgress = true;
    if (this.bulkText) {
      var arrayOfPageIds = this.bulkTextSendPages.map(function (obj) {
        return obj._id;
      });
      postBody = {
        message: this.sendTextMessageForm.controls.textMessageContent.value,
        pageIds: arrayOfPageIds
      }
    } else {
      postBody = {
        message: this.sendTextMessageForm.controls.textMessageContent.value,
        pageIds: [this.activeTextData._id]
      }
    }
    var dateString = this.sendDate.month + '/' + this.sendDate.day + '/' + this.sendDate.year + ' ' + this.sendTime.hour + ':' + this.sendTime.minute;
    this.textMessageService.scheduleTextSend({
      dateTime: new Date(dateString),
      data: postBody
    }).subscribe((data) => {
      this.schedSaveInProgress = false;
      modal.close();

      //Close and refresh page!
    }, (err) => {
      console.error('There was an error creating your schedules! ', err);
      this.schedSaveInProgress = false;
      this.toastService.show('There was an error creating your schedules', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
    });
  }

  deletePage(page, e) {
    e.preventDefault();
    this.loaderService.triggerLoader();

    // Check that page has no texts
    if (page.textCount === 0) {
      this.pagesService.deletePage(page._id).subscribe(() => {

        // Deleted page
        this.loaderService.stopLoader();
        this.loadData();
      }, (error) => {
        this.loaderService.stopLoader();
        this.toastService.show('There was an error deleting your page', {
          classname: 'bg-danger text-light',
          delay: 5000
        });
      });
    }
  }

  bulkDeletePages(pages, e) {
    e.preventDefault();
    this.loaderService.triggerLoader();

    // Create promis array of deletion calls and verify no texts sent.
    var promiseArray: Array<any> = [];
    for (var i = 0; i < pages.length; i++) {
      if (pages[i].textCount === 0) {
        promiseArray.push(new Promise((resolve, reject) => {
          this.pagesService.deletePage(pages[i]._id).subscribe(() => {
            resolve('');
          }, () => {
            reject();
          });
        }));
      }
    }
    Promise.all(promiseArray).
      then(function() {
        self.loaderService.stopLoader();
        self.loadData();
      }).
      catch(function() {
        self.loaderService.stopLoader();
        this.toastService.show('Some of your pages couldn\'t be deleted', {
          classname: 'bg-danger text-light',
          delay: 5000
        });
        setTimeout(() => {
          self.loadData();
        }, 2000);
      });
  }
}
