import { Component} from '@angular/core';
import { AuthenticationService, ToastService, LoaderService } from '@app/_services';
import { YardiService } from '@app/_services/yardi.service';
import { AutomationSettingsService } from '@app/_services/automationSettings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-automation',
  templateUrl: './automation.component.html',
  styleUrls: ['./automation.component.less']
})
export class AutomationComponent {

  userData: any;
  loading: boolean = true;
  hasIntegration: boolean;
  preLeaseAutomationStatus: boolean = false;
  interestListAutomationStatus: boolean = false;
  appointmentReminderAutomationStatus: boolean = false;
  tourFollowUpAutomationStatus: boolean = false;
  realEstateAutomationActive: boolean = false;

  constructor(
    private loaderService: LoaderService,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private yardiService: YardiService,
    private automationSettingsService: AutomationSettingsService,
    private router: Router
  ) {
      this.loadData();
  }

  loadData() {

    // Get user settings.
    this.loading = true;
    this.userData = this.authService.currentUserValue;
    if (this.userData.user.claims.indexOf('yardi') > -1) {
      this.hasIntegration = true;
      
      // Has integration, fetch source options from Yardi.
      // TODO: Make dynamic and support RealPage.
      this.loaderService.triggerLoader();
      this.yardiService.getYardiSources().subscribe((data) => {
        try {
          this.automationSettingsService.getAllAutomations().subscribe((data) => {
            for (var i = 0; i < data.result.length; i++) {
              if (data.result[i].type === 'preLeaseAutomation') {
                this.preLeaseAutomationStatus = data.result[i].active;
              } else if (data.result[i].type === 'interestListAutomation') {
                this.interestListAutomationStatus = data.result[i].active;
              }
            }
            this.loading = false;
            this.loaderService.stopLoader();
          }, (err) => {
            console.error('There was an err retrieving automations: ', err);
            this.toastService.show('There was an error loading your Yardi integration', { classname: 'bg-danger text-light', delay: 5000 });
            this.loaderService.stopLoader();
            this.loading = false;
          });
        } catch (e) {
          this.toastService.show('There was an error loading your Yardi sources', { classname: 'bg-danger text-light', delay: 5000 });
          this.loaderService.stopLoader();
          this.loading = false;
          console.error('Error setting sources: ', e);
        }
      }, (e) => {
        this.toastService.show('There was an error loading your Yardi sources', { classname: 'bg-danger text-light', delay: 5000 });
        this.loaderService.stopLoader();
        this.loading = false;
        console.error('Error setting sources: ', e);
      });
    }
    if (this.userData.user.claims.indexOf('real estate') > -1) {
      this.loaderService.triggerLoader();
      this.hasIntegration = true;
      this.automationSettingsService.getAllAutomations().subscribe((data) => {
        for (var i = 0; i < data.result.length; i++) {
          if (data.result[i].type === 'realEstateAutomation') {
            this.realEstateAutomationActive = data.result[i];
          }
        }
        this.loaderService.stopLoader();
        this.loading = false;
      }, (err) => {
        console.error('There was an err retrieving automations: ', err);
        this.toastService.show('There was an error loading your automation settings', { classname: 'bg-danger text-light', delay: 5000 });
        this.loading = false;
      });
    }
    if (this.userData.user.claims.indexOf('real estate') === -1 && this.userData.user.claims.indexOf('yardi') === -1) {
      this.hasIntegration = false;
      this.loading = false;
    }
  }

  configureAutomation(automationType) {
    this.router.navigate(['/automation/configure'], {queryParams: {type: automationType}});
  }

  updateRealEstateConfiguration() {
    this.automationSettingsService.updateSingleAutomation(this.realEstateAutomationActive).subscribe((data) => {
      this.toastService.show('Automation updated!', {
        classname: 'bg-success text-light',
        delay: 5000
      });
      this.realEstateAutomationActive = data.result;
    }, (err) => {
      console.error('There was an error updating your automation ', err);
      this.toastService.show('Error saving configuration.', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
    });
  }
}
