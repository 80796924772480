import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class LabelsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  createLabel(data) {
    return this.http.post<any>(this.apiHelper.fillUrl('labels', {}, {}), data)
      .pipe(map(data => {
        return data;
      }));
  }

  getLabels() {
    return this.http.get<any>(this.apiHelper.fillUrl('labels', {}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getLabel(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('label', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }
}
