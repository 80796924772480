<div class="page-title">
  <h2>Messages</h2>
</div>
<div class="page" id="messages">
  <div *ngIf="registeredUserData.user.twilioAccountId" class="row full-height">
    <div class="conversation-sidebar col-md-4">
      <div *ngFor="let thread of conversations" [ngClass]="{'unread': !thread.read, 'active': selectedThread && (selectedThread._id === thread._id)}" class="conversations-row row" (click)="getConversation(thread)">
        <div class="col-md-2 user-icon">
          <i class="far fa-user"></i>
        </div>
        <div class="col-md-7">
          <p class="contact-name">{{thread.contact.firstName}}</p>
          <p class="truncate message-preview full-width">{{thread.body}}</p>
        </div>
        <div class="col-md-3">
          <span class="thread-date">{{thread.mostRecentActivity | date:'h:mm a'}}<br />{{thread.mostRecentActivity | date:'M/d/yy'}}</span>
        </div>
      </div>
    </div>
    <div class="col-md-8 chat-wrapper" *ngIf="selectedThread">
      <div class="message-action-wrap">
        <h4 class="contact-click pull-left" (click)="goToContact(selectedThread.contact._id)">{{selectedThread.contact.contactIdentifier}} - {{selectedThread.contact.phoneNumber | phone}}</h4>
        <div class="button button-small pull-right button-dark" (click)="checkOptInStatus(selectedThread.contact._id)">Opt In/Out</div>
      </div>
      <div id="chat-window">
        <div class="chat-bubble-wrap" *ngFor="let message of activeConversation; let i = index">
          <p
            [ngClass]="{'from-them': message.from === selectedThread.contact.phoneNumber,
                      'from-me': message.to === selectedThread.contact.phoneNumber}">
              {{message.body}}
          </p>
          <span [ngClass]="{'them-time': message.from === selectedThread.contact.phoneNumber,
              'me-time': message.to === selectedThread.contact.phoneNumber}"
              class="timestamp" *ngIf="i === (activeConversation.length - 1) || i%5 === 0">
            {{message.updated | date:'MMM d, y, h:mm a'}}
          </span>
        </div>
      </div>
      <div class="reply-input">
        <div class="row">
          <div class="col-md-9">
            <div class="input-group mb-3">
              <input placeholder="Reply..." [(ngModel)]="replyMessage"
              [ngModelOptions]="{standalone: true}" class="form-control reply-field" />
            </div>
          </div>
          <div class="col-md-3">
            <button class="button button-dark button-small submit-button" [disabled]="sendingText" (click)="sendText($event)"><span *ngIf="sendingText" class="spinner-border spinner-border-sm mr-1"></span>Send</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8" *ngIf="!selectedThread">
      <div id="chat-window">
        <p class="text-center"><img src="/assets/no-messaging.png" /></p>
        <h3 class="text-center">
          Select a conversation to get started!
        </h3>
      </div>
    </div>
  </div>
  <div *ngIf="!registeredUserData.user.twilioAccountId" class="row messaging-disabled">
    <p class="text-center full-width margin-top-48">
      <img src="/assets/no-messaging.png" />
    </p>
    <h3 class="full-width margin-top-48">You do not have two way messaging enabled, please contact <a href="mailto:customersuccess@nurtureboss.io">customersuccess@nurtureboss.io</a> to enable this for your account!</h3>
  </div>
</div>

<!-- Opt Out Modal -->
<ng-template #optOutModal let-optOutModal>
  <div class="modal-header">
      <h5 class="modal-title">{{optOutMessage}} Contact</h5>
      <div class="float-right">
          <button type="button" class="button button-white button-small" (click)="exitModal($event, optOutModal)"><i class="far fa-times" aria-hidden="true"></i></button>
      </div>
  </div>
  <div class="schedule-text-modal modal-body">
      <div class="alert alert-danger"><strong>Warning:</strong> You are about to {{optOutMessage}} this contact. Please be sure this is your intention.</div>
      <button (click)="changeOptInStatus(optOutModal, selectedThread.contact._id)" [disabled]="optOutInProgress" class="button button-dark">
          <span *ngIf="optOutInProgress" class="spinner-border spinner-white spinner-border-sm mr-1"></span>
          {{optOutMessage}} Contact
      </button>
  </div>
</ng-template>