import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  runQuery(startDate?, endDate?) {
    return this.http.get<any>(this.apiHelper.fillUrl('analytics', {}, {startDate, endDate}))
      .pipe(map(data => {
        return data;
      }));
  }
}
