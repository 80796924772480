<div>
  <div class="page-title">
    <h2>Automation</h2>
  </div>
  <div class="page" id="automation">
    <div class="card">
      <div class="card-body">
  
        <!-- No Integration Enabled -->
        <div *ngIf="!hasIntegration" class="row">
          <p class="text-center full-width margin-top-48">
            <img src="/assets/no-messaging.png" />
          </p>
          <h3 class="text-center full-width margin-top-48">You do not have your PMS integration enabled. Please contact <a href="mailto:customersuccess@nurtureboss.io">customersuccess@nurtureboss.io</a> to enable this for your account!</h3>
        </div>

        <!-- Yardi Automation Tracks -->
        <div *ngIf="this.userData.user.claims.indexOf('yardi') > -1" class="sequence-wrapper">
          <div class="row">
            <div class="col-md-4 sequence-options-column">
              <p>
                <strong>Interest List Automation</strong>
              </p>
              <p>
                <span>4 Touch Point Sequence</span>
              </p>
              <div class="sequence-option-wrapper">
                <div class="guest-card-follow-up-template">
                  <img src="/assets/yardi-icon.png" />
                </div>
                <p><strong>Send reminders for prospects to join the interest list for your community being built.</strong></p>
                <button (click)="configureAutomation('interestListAutomation')" class="button button-white">
                  <i class="fad fa-robot"></i> Configure
                </button>
                <div class="track-status-wrapper">
                  <div class="track-status {{interestListAutomationStatus ? 'active' : 'not-active'}}">
                  </div>
                  <span>{{interestListAutomationStatus ? 'ON' : 'OFF'}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-4 sequence-options-column">
              <p>
                <strong>Pre Lease Automation</strong>
              </p>
              <p>
                <span>5 Touch Point Sequence</span>
              </p>
              <div class="sequence-option-wrapper">
                <div class="guest-card-follow-up-template">
                  <img src="/assets/yardi-icon.png" />
                </div>
                <p><strong>Nurture new guest cards to schedule appointments/tours</strong></p>
                <button (click)="configureAutomation('preLeaseAutomation')" class="button button-white">
                  <i class="fad fa-robot"></i> Configure
                </button>
                <div class="track-status-wrapper">
                  <div class="track-status {{preLeaseAutomationStatus ? 'active' : 'not-active'}}">
                  </div>
                  <span>{{preLeaseAutomationStatus ? 'ON' : 'OFF'}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-4 sequence-options-column">
              <p>
                <strong>Appointment Reminders</strong>
              </p>
              <p>
                <span>1 Week Sequence</span>
              </p>
              <div class="sequence-option-wrapper">
                <div class="guest-card-follow-up-template">
                  <img src="/assets/yardi-icon.png" />
                </div>
                <p><strong>Remind prospects of upcoming appointments for better turn out</strong></p>
                <button disabled class="button button-white">
                  <i class="fad fa-robot"></i> Coming Soon
                </button>
                <div class="track-status-wrapper">
                  <div class="track-status {{appointmentReminderAutomationStatus ? 'active' : 'not-active'}}">
                  </div>
                  <span>{{appointmentReminderAutomationStatus ? 'ON' : 'OFF'}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row margin-top-48">
            <div class="col-md-4 sequence-options-column">
              <p>
                <strong>Tour Follow Up</strong>
              </p>
              <p>
                <span>1 Week Sequence</span>
              </p>
              <div class="sequence-option-wrapper">
                <div class="guest-card-follow-up-template">
                  <img src="/assets/yardi-icon.png" />
                </div>
                <p><strong>Follow up with prospect who tour and get more submitted applications</strong></p>
                <button disabled class="button button-white">
                  <i class="fad fa-robot"></i> Coming Soon
                </button>
                <div class="track-status-wrapper">
                  <div class="track-status {{tourFollowUpAutomationStatus ? 'active' : 'not-active'}}">
                  </div>
                  <span>{{tourFollowUpAutomationStatus ? 'ON' : 'OFF'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Real Estate Automation -->
        <div *ngIf="this.userData.user.claims.indexOf('real estate') > -1" class="row sequence-wrapper">
          <div class="col-md-4 sequence-options-column">
            <p>
              <strong>Monthly Outreach</strong>
            </p>
            <p>
              <span>Once A Month Communication</span>
            </p>
            <div class="sequence-option-wrapper">
              <div class="real-estate-monthly-follow-up">
                <i class="fas fa-house-user"></i>
              </div>
              <p><strong>Nurture contacts on a monthly cadence</strong></p>
              <div class="text-center">
                <h6>Off/On</h6>
                <label class="switch">
                <input type="checkbox" [(ngModel)]="realEstateAutomationActive.active" (change)="updateRealEstateConfiguration()">
                <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>