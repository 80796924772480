<div id="app-container" [class.isLogin]="(regexIndexOf(noSideBarStates, router.url) !== -1)">
  <nav id="sidebar-wrapper" *ngIf="(regexIndexOf(noSideBarStates, router.url) === -1)">
    <div class="sidebar-heading">
      <a href="/">
        <img class="logo" src="/assets/nurturebossFULLcolor.png" />
      </a>
    </div>
    <a routerLink="/admin" [class.active]="router.url === '/admin'" *ngIf="claimsData.indexOf('admin') > -1">
      <div class="row nav-item">
        <div class="nav-icon">
          <i class="far fa-users-crown"></i>
        </div>
        <div class="nav-text">
          Admin
        </div>
      </div>
    </a>
    <div class="nav-links-wrapper">
      <a routerLink="/dashboard" [class.active]="router.url === '/dashboard'">
        <div class="row nav-item">
          <div class="nav-icon">
            <i class="far fa-tachometer-alt-slowest"></i>
          </div>
          <div class="nav-text">
            Dashboard
          </div>
        </div>
      </a>
      <a routerLink="/contacts" [class.active]="router.url === '/contacts'" *ngIf="claimsData.indexOf('real estate') > -1 || claimsData.indexOf('admin') > -1">
        <div class="row nav-item">
          <div class="nav-icon">
            <i class="far fa-id-card"></i>
          </div>
          <div class="nav-text">
            Contacts
          </div>
        </div>
      </a>
      <a routerLink="/messages" [class.active]="router.url === '/messages'">
        <div class="row nav-item">
          <div class="nav-icon">
            <i class="far fa-comments"></i>
          </div>
          <div class="nav-text">
            Messages <div class="unread-message-indicator" *ngIf="unreadMessages > 0">{{unreadMessages}}</div>
          </div>
        </div>
      </a>
      <a routerLink="/templates" [class.active]="router.url === '/templates'">
        <div class="row nav-item">
          <div class="nav-icon">
            <i class="far fa-pen-square"></i>
          </div>
          <div class="nav-text">
            Templates
          </div>
        </div>
      </a>
      <a routerLink="/pages" [class.active]="router.url === '/pages'">
        <div class="row nav-item">
          <div class="nav-icon">
            <i class="far fa-clone"></i>
          </div>
          <div class="nav-text">
            Pages
          </div>
        </div>
      </a>
      <a routerLink="/tracking" [class.active]="router.url === '/tracking'">
        <div class="row nav-item">
          <div class="nav-icon">
            <i class="far fa-list"></i>
          </div>
          <div class="nav-text">
            Tracking
          </div>
        </div>
      </a>
      <a routerLink="/analytics" [class.active]="router.url === '/analytics'">
        <div class="row nav-item">
          <div class="nav-icon">
            <i class="far fa-analytics"></i>
          </div>
          <div class="nav-text">
            Analytics
          </div>
        </div>
      </a>
      <a routerLink="/automation" [class.active]="router.url === '/automation'">
        <div class="row nav-item">
          <div class="nav-icon">
            <i class="far fa-robot"></i>
          </div>
          <div class="nav-text">
            Automation
          </div>
        </div>
      </a>
      <a href="https://nurtureboss.io/knowledge-base" target="_blank">
        <div class="row nav-item">
          <div class="nav-icon">
            <i class="far fa-life-ring"></i>
          </div>
          <div class="nav-text">
            Knowledge Base
          </div>
        </div>
      </a>
      <a routerLink="/settings" [class.active]="router.url === '/settings'">
        <div class="row nav-item">
          <div class="nav-icon">
            <i class="far fa-cog"></i>
          </div>
          <div class="nav-text">
            Settings
          </div>
        </div>
      </a>
      <a (click)="logout()">
        <div class="row nav-item">
          <div class="nav-icon">
            <i class="far fa-sign-out"></i>
          </div>
          <div class="nav-text">
            Logout
          </div>
        </div>
      </a>
    </div>
  </nav>
  <main id="page-content-wrapper">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<!-- <div class="d-flex" id="wrapper">
  
</div> -->

<!-- Toast Template -->
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<!-- Loading Template -->
<div id="global-loader" *ngIf="loaderActive">
  <div class="lds-grid">
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    <p class="long-load-message" *ngIf="longLoaderActive">Hang tight, this may take a few minutes.</p>
  </div>
</div>

<!-- Terms of Service Update -->
<div id="global-tos-update" *ngIf="showTosUpdate">
  <div class="tos-update-body">
    <img src="/assets/NB-logo-small.png" />
    <p>We've updated our Terms</p>
    <span>To continue please confirm that you agree to our Terms of Service</span>
    <a href="https://nurtureboss.io/terms-of-service/" target="_blank">Terms of Service</a>
    <button class="button button-dark" (click)="agreeToTerms()">I Agree</button>
  </div>
</div>

<!-- Update Usage Modal -->
<div id="account-mimic-warning" *ngIf="showMimicWarning">
  <p>Warning! You are logged in as {{showMimicWarning}}</p> <a (click)="showChangeUsage()">Update Usage</a>
</div>

<div id="update-usage-modal" *ngIf="showUpdateUsageModal">
  <div class="usage-update-body">
    <p>Enter the new amount of usage for this account:</p>
    <div class="form-group">
      <label># of pages and texts</label>
      <input type="number" class="form-control" [(ngModel)]="newUsageAmount" [ngModelOptions]="{standalone: true}" id="newUsageAmount">
    </div>
    <button class="button button-dark" (click)="updateUsage()">Add Usage</button>
    <button class="button button-white margin-top-12" (click)="showChangeUsage()">Exit</button>
  </div>
</div>