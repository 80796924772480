<div id="templates">
    <div class="page-title">
        <!-- <div class="actions left">
            <i class="far fa-bars"></i>
        </div> -->
        <h2>Templates</h2>
        <!-- <div class="actions right">
            <i class="far fa-search"></i>
        </div> -->
    </div>
    <!-- <div class="alert alert-success-light mb-0 mt-0 template-alert">
        <p class="no-margin">If you have a need for a new template let us know <a href="mailto:customersuccess@nurtureboss.io">here.</a></p>
    </div> -->
    <div class="card mt-4">
        <div class="card-body page-templates">
            <div class="card-actions">
                <div class="item" (click)="activeState = 'nurturepages'" [ngClass]="{'active': activeState === 'nurturepages'}">
                    <i class="far fa-browser"></i>
                    Nurture Pages
                </div>
                <div *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1" class="item active" (click)="activeState = 'emails'" [ngClass]="{'active': activeState === 'emails'}">
                    <i class="far fa-envelope-square"></i>
                    Emails
                </div>
            </div>
            <div class="row" *ngIf="activeState === 'nurturepages'">
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('harper') > -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <div class="new-banner">NEW!</div>
                        <a href="https://sites.customnurturepages.com/?id=602db0cbf39e5f0004ddf676&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/harper-move-in-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Harper Move In Checklist
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Harper Move In Checklist')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Harper Move In Checklist')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <div class="new-banner">NEW!</div>
                        <a href="https://sites.customnurturepages.com/?id=6026d8851566ac0004ffb890&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/accepting-applications-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Accepting Applications
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Apartment Accepting Applications')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Apartment Accepting Applications')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('harper') > -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <div class="new-banner">NEW!</div>
                        <a href="https://sites.customnurturepages.com/?id=6026b70c24d90700049ac3a6&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/harper-tour-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Harper Self-Guided Tour
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Harper Self Guided Tour')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Harper Self Guided Tour')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <div class="new-banner">NEW!</div>
                        <a href="https://sites.customnurturepages.com/?id=6025c23686760f00040457b8&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/covid-screen-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            COVID-19 Pre-Screen
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Apartment Covid Screening')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Apartment Covid Screening')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('revere') > -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <div class="new-banner">NEW!</div>
                        <a href="https://sites.customnurturepages.com/?id=60158f3d898b8f00048fd651&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/revere-rent-reminder-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Revere Rent Reminder
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Revere Rent Reminder')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Revere Rent Reminder')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <div class="new-banner">NEW!</div>
                        <a href="https://sites.customnurturepages.com/?id=6010c96cf62ec200042dfb13&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/interest-list-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Interest List
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Apartment Interest List')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Apartment Interest List')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5f526fe98c0355000449e649&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/apartment-appointment-reminder-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Appointment Reminder
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Apartment Appointment Reminder')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Apartment Appointment Reminder')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5f56690b247e480004ab4506&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/rent-reminder-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            General Rent Reminder
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Rent Reminder')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Rent Reminder')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('royce') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5f2889ee782cc7acd1e2cbc6&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/the-royce-rent-reminder-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Flex Rent / Rent Reminder
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('The Royce Rent Reminder')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('The Royce Rent Reminder')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('paseo') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5ec1c84c3865924bbf0a51e4&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/lease-renewal-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Paseo Renewal Reminder
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Paseo Renewal')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Paseo Renewal')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('bellaposta') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5f98ac87ec72970004b2c61b&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/bella-posta-remodel-relocation-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Remodel Relocation
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Bella Posta Remodel Relocation')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Bella Posta Remodel Relocation')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5f27253780ba410017dd681a&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/event-reminder-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Event Reminder
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Apartment Event Reminder')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Apartment Event Reminder')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5ef96cc497ca9e0017dcc97b&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/move-in-checklist-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Move In Checklist
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Move In Checklist')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Move In Checklist')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5e7cc13c090bd32d0968864b&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/apartment_tour_template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Tour Follow Up
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Apartment Tour')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Apartment Tour')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5eac34b2e714800017e00ffd&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/guest-card-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Guest Card Follow Up
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Guest Card')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Guest Card')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5eaf8d21abab1eb02a8c0925&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/apartment_concession_template.png')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Concession Offering
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Apartment Concession')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Apartment Concession')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5ec1c84c3865924bbf0a51e4&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/lease-renewal-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Lease Renewal
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Apartment Renewal')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Apartment Renewal')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5ecc022260575000173b29bb&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/maintenance-followup-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Maintenance Follow Up
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Apartment Maintenance Followup')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Apartment Maintenance Followup')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('SFS') > -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5ea710959c707b359ee0f738&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/sfs_federal_template.png')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            SFS Federal
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('SFS Federal')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('SFS Federal')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('real estate') > -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5ffb33be5733800004bdf84c&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/digital-business-card.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Digital Business Card
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Digital Business Card')">
                                Create Page
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('real estate') > -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5ffb4a58852a4900048e2c7e&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/real-estate-general-communication.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            General Communication
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Real Estate General Communication')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Real Estate General Communication')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('generalist') > -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="https://sites.customnurturepages.com/?id=5ffb42b65733800004bdf84e&track=false" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/master-nurture-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Master Nurture Template
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewPage('Master Nurture Template')">
                                Create Page
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewPage('Master Nurture Template')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="activeState === 'emails'">
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <div class="new-banner">NEW!</div>
                        <a href="assets/interest-list-email-template.html" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/interest-list-email-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Interest List
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewEmail('Apartment Interest List')">
                                Create Email
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewEmail('Apartment Interest List')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="assets/concession-email-template.html" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/concession-email-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Concession Offering
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewEmail('Apartment Concession')">
                                Create Email
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewEmail('Apartment Concession')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 margin-top-24" *ngIf="currentUser.user.claims && currentUser.user.claims.indexOf('nomultifamily') === -1 || currentUser.user.claims.indexOf('admin') > -1">
                    <div class="single-template-wrap">
                        <a href="assets/guest-card-template-sample.html" target="_blank">
                            <div class="template-preview" style="background-image: url('assets/guest-card-email-template.jpg')">                            
                            </div>
                        </a>
                        <div class="template-title">
                            Guest Card Follow Up
                        </div>
                        <div class="template-actions">
                            <div class="template-actions-left" (click)="createNewEmail('Guest Card')">
                                Create Email
                            </div>
                            <div class="template-actions-right" (click)="bulkUploadNewEmail('Guest Card')" >
                                Bulk Create
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>