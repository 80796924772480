import { Component} from '@angular/core';
import { UsersService, AuthenticationService, ToastService, LoaderService } from '@app/_services';
import { YardiService } from '@app/_services/yardi.service';
var self;

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.less']
})
export class IntegrationsComponent {

  hasIntegration: boolean = false;
  loadingYardiConfigurations: Boolean = true;
  localUserData:any;
  userData: any;
  yardiConfigurations: Array<any> = [];
  yardiILSConfigurations: String;
  showILSConfiguration: Boolean = false;
  yardiAgents: Array<string> = [];
  savingAgent: boolean = false;

  constructor(
    private userService: UsersService,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private yardiService: YardiService
  ) {
      this.localUserData = this.authService.currentUserValue;
      this.loadData();
      self = this;
  }

  loadData() {

    // Get user settings
    this.loaderService.triggerLoader();
    this.hasIntegration = this.localUserData.user.claims.indexOf('yardi') > -1;
    this.userService.getUserData(this.localUserData.user._id).subscribe((data) => {
      this.userData = data.result;
      if (this.hasIntegration) {
        this.yardiService.getPropertyConfiguration().subscribe((data) => {
          this.yardiConfigurations = data.result.GetPropertyConfigurationsResult.Properties.Property;
          this.yardiService.getILSConfiguration().subscribe((data) => {
            this.yardiILSConfigurations  = this.formatXml(data.result);
            this.yardiService.getYardiSources().subscribe((sources) => {
              try {
                this.yardiAgents = sources.result.GetYardiAgentsSourcesResults_LoginResult.Properties.Property.PropertyRequiredFields.Agents.AgentName
                this.loadingYardiConfigurations = false;
                this.loaderService.stopLoader();
              } catch(e) {
                console.error('There was an error loading your Yardi sources: ', e);
                this.loaderService.stopLoader();
                this.toastService.show('There was an error loading your Yardi sources', { classname: 'bg-danger text-light', delay: 5000 });  
              }
            }, (e) => {
              this.loaderService.stopLoader();
              console.error('There was an error loading your Yardi sources: ', e);
              this.toastService.show('There was an error loading your Yardi sources', { classname: 'bg-danger text-light', delay: 5000 });
            });
          }, (err) => {
            console.error('There was an error loading your Yardi configurations: ', err);
            this.toastService.show('There was an error loading your Yardi configurations', { classname: 'bg-danger text-light', delay: 5000 });
            this.loadingYardiConfigurations = false;
            this.loaderService.stopLoader();
          });
        }, (err) => {
          console.error('There was an error loading your Yardi configurations: ', err);
          this.toastService.show('There was an error loading your Yardi configurations', { classname: 'bg-danger text-light', delay: 5000 });
          this.loadingYardiConfigurations = false;
          this.loaderService.stopLoader();
        });
      } else {
        this.loadingYardiConfigurations = false;
        this.loaderService.stopLoader();
      }
    }, (err) => {
      this.loaderService.stopLoader();
      this.toastService.show('There was an error retrieving your user settings', { classname: 'bg-danger text-light', delay: 5000 });
      console.error('There was an error retrieving your user settings: ', err);
    });
  }

  updateAgentOfRecord() {
    this.savingAgent = true;
    this.userService.updateUser(this.userData._id, {
      yardiAgentName: this.userData.yardiAgentName
    }, true).subscribe(() => {
      this.savingAgent = false;
      this.toastService.show('Settings updated!', { classname: 'bg-success text-light', delay: 5000 });
    }, (e) => {
      this.savingAgent = false;
      console.error('There was an error updating user settings: ', e);
      this.toastService.show('There was an error updating settings', { classname: 'bg-danger text-light', delay: 5000 });
    });
  }

  formatXml(xml) {
    var formatted = '';
    var reg = /(>)(<)(\/*)/g;
    xml = xml.replace(reg, '$1\r\n$2$3');
    var pad = 0;
    xml.split('\r\n').forEach(function(node, index, a) {
      var indent = 0;
      if (node.match( /.+<\/\w[^>]*>$/ )) {
        indent = 0;
      } else if (node.match( /^<\/\w/ )) {
        if (pad != 0) {
          pad -= 1;
        }
      } else if (node.match( /^<\w[^>]*[^\/]>.*$/ )) {
        indent = 1;
      } else {
        indent = 0;
      }
      var padding = '';
      for (var i = 0; i < pad; i++) {
        padding += '  ';
      }
      formatted += padding + node + '\r\n';
      pad += indent;
    });
    return formatted;
  }

  triggerShowILSConfiguration() {
    this.showILSConfiguration = !this.showILSConfiguration;
  }
}