import {
    Component
  } from '@angular/core';
  
  @Component({
    templateUrl: 'templates.component.html',
    styleUrls: ['templates.component.less']
  })
  export class TemplatesComponent {
    constructor() {
      //no-op
    }
  }
  