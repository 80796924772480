<div id="login" class="app-entry">
    <div class="row">
        <div class="col-md-7 form-wrapper">
            <p class="text-center logo-wrapper"><img src="/assets/nurturebossFULLcolor.png" /></p>
            <div class="col-md-6 offset-md-3">
                <h2>Sign In</h2>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="email">Email</label>
                        <div class="input-group mb-3">
                            <input type="text" placeholder="Start typing..." formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <div class="input-group-append">
                              <span class="input-group-text"><i class="far fa-envelope-open"></i></span>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.email.errors" class="form-errors">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <div class="input-group mb-3">
                            <input type="password" placeholder="Start typing..." formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                            <div class="input-group-append">
                              <span class="input-group-text"><i class="far fa-lock"></i></span>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.password.errors" class="form-errors">
                            <div *ngIf="f.password.errors.required">Password is required</div>
                        </div>
                    </div>
                    <div class="row accessories-bar">
                        <div class="col-sm-6 remember-me">
                            <!-- Remember Me -->
                        </div>
                        <div class="col-sm-6">
                            <div routerLink="/forgot-password" class="recover-psw">Recover Password</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <button [disabled]="loading" class="pull-left button button-dark">
                                Sign In
                            </button>
                        </div>
                        <div class="col-sm-6">
                            <!-- <button [disabled]="loading" class="pull-right button button-light">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Sign Up
                            </button> -->
                        </div>
                    </div>
                    <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                </form>
            </div>
        </div>
        <div class="col-md-5 login-bg"></div>
    </div>
</div>