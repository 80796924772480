import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PageTypesService {

  constructor(
    //
  ) {
    //no-op
  }

  loadModalData(pageType, isEmail?) {
    switch (pageType) {
      case 'Apartment Tour':
        return this.apartmentTourModal();
      case 'Apartment Concession':
          return this.apartmentConcessionsModal(isEmail);
      case 'Apartment Renewal':
        return this.apartmentLeaseRenewalModal();
      case 'Apartment Maintenance Followup':
        return this.apartmentMaintenanceFollowUpModal();
      case 'Guest Card':
        return this.apartmentGuestCardModal(isEmail);
      case 'Move In Checklist':
        return this.moveInChecklistModal();
      case 'Apartment Event Reminder':
        return this.eventReminderModal();
      case 'The Royce Rent Reminder':
        return this.theRoyceRentReminderModal();
      case 'Bella Posta Remodel Relocation':
        return this.bellaPostaRemodelRelocationModal();
      case 'Rent Reminder':
        return this.rentReminderModal();
      case 'Apartment Appointment Reminder':
        return this.appointmentReminderModal();
      case 'Master Nurture Template':
        return this.masterNurtureTemplateModal();
      case 'Paseo Renewal':
        return this.paseoRenewalTemplateModal();
      case 'Digital Business Card':
        return this.digitalBusinessCardModal();
      case 'Real Estate General Communication':
        return this.realEstateGeneralCommunicationModal();
      case 'Apartment Interest List':
        return this.apartmentInterestListModal(isEmail);
      case 'Revere Rent Reminder':
        return this.revereRentReminderModal();
      case 'Apartment Covid Screening':
        return this.apartmentCovidScreeningModal();
      case 'Harper Self Guided Tour':
        return this.harperSelfGuidedToursModal();
      case 'Apartment Accepting Applications':
        return this.apartmentAcceptingApplicationsModal();
      case 'Harper Move In Checklist':
        return this.harperMoveInChecklistModal();
    }
  }

  apartmentTourModal() {
    return {
      modalTitle: 'New Apartment Tour Details',
      pageType: 'Apartment Tour',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        },
        {
          key: 'moveInDate',
          label: 'Move In Date',
          type: 'text',
          placeholder: 'e.g. 01/01/2022',
          required: false,
        },
        {
          key: 'clientBedroomCount',
          label: 'Bedroom Count',
          type: 'number',
          placeholder: 'e.g. 2',
          required: false,
        },
        {
          key: 'leaseTerm',
          label: 'Lease Term',
          type: 'number',
          placeholder: 'Enter number of months',
          required: false,
        },
        {
          key: 'monthlyRent',
          label: 'Monthly Rent',
          type: 'number',
          placeholder: 'Enter Dollar Amount',
          required: false,
        }
      ]
    };
  }

  apartmentConcessionsModal(isEmail) {
    var returnObj:any = {
      modalTitle: 'New Apartment Concession Details',
      pageType: 'Apartment Concession',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        }
      ]
    };
    if (isEmail) {
      returnObj.fields.push({
        key: 'clientEmailAddress',
        label: 'Email Address',
        type: 'email',
        placeholder: 'e.g. john@me.com',
        required: true,
        errorMessage: 'Email Address is required'
      });
    } else {
      returnObj.fields.push({
        key: 'clientPhoneNumber',
        label: 'Phone Number',
        type: 'text',
        mask: '(000) 000-0000',
        placeholder: 'e.g. 6025555555',
        required: true,
        errorMessage: 'Phone Number is required'
      });
    }
    return returnObj;
  }

  paseoRenewalTemplateModal() {
    return {
      modalTitle: 'New Lease Reminder Template',
      pageType: 'Paseo Renewal',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        }
      ]
    };
  }

  apartmentLeaseRenewalModal() {
    return {
      modalTitle: 'New Lease Renewal Details',
      pageType: 'Apartment Renewal',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        },
        {
          key: 'renewalAmount',
          label: 'Renewal Amount',
          type: 'number',
          placeholder: 'e.g. 2100.85',
          required: false,
        },
        {
          key: 'leaseExpiration',
          label: 'Lease Expiration',
          type: 'text',
          placeholder: 'e.g. 06/01/2022',
          required: false,
        },
        {
          key: 'leaseTerm',
          label: 'Lease Term',
          type: 'number',
          placeholder: 'Enter number of months',
          required: false,
        },
        {
          key: 'renewalSpecial',
          label: 'Renewal Special',
          type: 'text',
          placeholder: 'e.g. First 2 weeks free!',
          required: false,
        }
      ]
    };
  }

  apartmentMaintenanceFollowUpModal() {
    return {
      modalTitle: 'New Apartment Maintenance Followup Details',
      pageType: 'Apartment Maintenance Followup',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        },
        {
          key: 'surveyLink',
          label: 'Survey Link',
          type: 'text',
          placeholder: 'e.g. https://...',
          required: false,
        }
      ]
    };
  }

  apartmentGuestCardModal(isEmail) {
    var returnObj:any = {
      modalTitle: 'New Guest Card Follow Up Details',
      pageType: 'Guest Card',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'moveInDate',
          label: 'Move In Date',
          type: 'text',
          placeholder: 'e.g. 01/01/2022',
          required: false,
        },
        {
          key: 'clientBedroomCount',
          label: 'Bedroom Count',
          type: 'number',
          placeholder: 'e.g. 2',
          required: false,
        }
      ]
    };
    if (isEmail) {
      returnObj.fields.push({
        key: 'clientEmailAddress',
        label: 'Email Address',
        type: 'email',
        placeholder: 'e.g. john@me.com',
        required: true,
        errorMessage: 'Email Address is required'
      });
    } else {
      returnObj.fields.push({
        key: 'clientPhoneNumber',
        label: 'Phone Number',
        type: 'text',
        mask: '(000) 000-0000',
        placeholder: 'e.g. 6025555555',
        required: true,
        errorMessage: 'Phone Number is required'
      });
    }
    return returnObj;
  }

  moveInChecklistModal() {
    return {
      modalTitle: 'New Move In Checklist Details Details',
      pageType: 'Move In Checklist',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        },
        {
          key: 'moveInDate',
          label: 'Move In Date',
          type: 'text',
          placeholder: 'e.g. 06/01/2021',
          required: true,
        },
        {
          key: 'apartmentNumber',
          label: 'Apartment Number',
          type: 'text',
          placeholder: 'e.g. 1309',
          required: true,
        },
        {
          key: 'moveInCosts',
          label: 'Cost due at move in',
          type: 'number',
          placeholder: 'e.g. 2450',
          required: false,
        },
        {
          key: 'specialPaymentInstructions',
          label: 'Special move in cost instructions',
          type: 'text',
          placeholder: 'e.g. Cashiers check only',
          required: false,
        },
        {
          key: 'powerProvider',
          label: 'Power provider',
          type: 'text',
          placeholder: 'e.g. SRP',
          required: false,
        },
        {
          key: 'specialPowerProviderInstructions',
          label: 'Power provider website',
          type: 'text',
          placeholder: 'e.g. https://....',
          required: false,
        },
        {
          key: 'specialInsuranceInstructions',
          label: 'Renters insurance website',
          type: 'text',
          placeholder: 'e.g. https://....',
          required: false,
        }
      ]
    };
  }

  harperMoveInChecklistModal() {
    return {
      modalTitle: 'New Harper Move In Checklist Details Details',
      pageType: 'Harper Move In Checklist',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        },
        {
          key: 'moveInDate',
          label: 'Move In Date',
          type: 'text',
          placeholder: 'e.g. 06/01/2021',
          required: true,
        },
        {
          key: 'apartmentNumber',
          label: 'Apartment Number',
          type: 'text',
          placeholder: 'e.g. 1309',
          required: true,
        },
        {
          key: 'moveInCosts',
          label: 'Cost due at move in',
          type: 'number',
          placeholder: 'e.g. 2450',
          required: false,
        }
      ]
    };
  }

  eventReminderModal() {
    return {
      modalTitle: 'New Event Reminder Details',
      pageType: 'Apartment Event Reminder',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        }
      ]
    };
  }

  theRoyceRentReminderModal() {
    return {
      modalTitle: 'New Rent Reminder Details',
      pageType: 'The Royce Rent Reminder',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        }
      ]
    };
  }

  bellaPostaRemodelRelocationModal() {
    return {
      modalTitle: 'New Remodel Relocation Details',
      pageType: 'Bella Posta Remodel Relocation',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        }
      ]
    };
  }

  rentReminderModal() {
    return {
      modalTitle: 'New Rent Reminder Details',
      pageType: 'Rent Reminder',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        }
      ]
    };
  }

  revereRentReminderModal() {
    return {
      modalTitle: 'New Revere Rent Reminder Details',
      pageType: 'Revere Rent Reminder',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        }
      ]
    };
  }

  appointmentReminderModal() {
    return {
      modalTitle: 'New Appointment Reminder Details',
      pageType: 'Apartment Appointment Reminder',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        },
        {
          key: 'appointmentDate',
          label: 'Appointment Date',
          type: 'text',
          placeholder: 'e.g. 09/15/2020',
          required: true,
          errorMessage: 'Appointment Date is required'
        },
        {
          key: 'appointmentTime',
          label: 'Appointment Time',
          type: 'text',
          placeholder: 'e.g. 1:30 AM - INCLUDE AM/PM LABEL',
          required: true,
          errorMessage: 'Appointment Time is required'
        }
      ]
    };
  }

  masterNurtureTemplateModal() {
    return {
      modalTitle: 'New Master Nurture Template Details',
      pageType: 'Master Nurture Template',
      fields: []
    };
  }

  digitalBusinessCardModal() {
    return {
      modalTitle: 'New Digital Business Card Details',
      pageType: 'Digital Business Card',
      fields: []
    };
  }

  realEstateGeneralCommunicationModal() {
    return {
      modalTitle: 'New Real Estate General Communication Details',
      pageType: 'Real Estate General Communication',
      fields: []
    };
  }

  apartmentInterestListModal(isEmail) {
    var returnObj:any = {
      modalTitle: 'New Apartment Interest List Details',
      pageType: 'Apartment Interest List',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        }
      ]
    };
    if (isEmail) {
      returnObj.fields.push({
        key: 'clientEmailAddress',
        label: 'Email Address',
        type: 'email',
        placeholder: 'e.g. john@me.com',
        required: true,
        errorMessage: 'Email Address is required'
      });
    } else {
      returnObj.fields.push({
        key: 'clientPhoneNumber',
        label: 'Phone Number',
        type: 'text',
        mask: '(000) 000-0000',
        placeholder: 'e.g. 6025555555',
        required: true,
        errorMessage: 'Phone Number is required'
      });
    }
    return returnObj;
  }

  apartmentCovidScreeningModal() {
    return {
      modalTitle: 'New COVID-19 Pre-Screen Details',
      pageType: 'Apartment Covid Screening',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        }
      ]
    };
  }

  harperSelfGuidedToursModal() {
    return {
      modalTitle: 'New Harper Self-Guided Tour Details',
      pageType: 'Harper Self Guided Tour',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        }
      ]
    };
  }

  apartmentAcceptingApplicationsModal() {
    return {
      modalTitle: 'New Accepting Applications Details',
      pageType: 'Apartment Accepting Applications',
      fields: [
        {
          key: 'clientFirstName',
          label: 'First Name',
          type: 'text',
          placeholder: 'e.g. Jane',
          required: true,
          errorMessage: 'First Name is required'
        },
        {
          key: 'clientPhoneNumber',
          label: 'Phone Number',
          type: 'text',
          mask: '(000) 000-0000',
          placeholder: 'e.g. 6025555555',
          required: true,
          errorMessage: 'Phone Number is required'
        }
      ]
    };
  }
}
