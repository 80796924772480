import {
    Component, OnInit, ViewChild
} from '@angular/core';
import { LoaderService, ContactsService, ToastService } from '@app/_services';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { FilterUtils } from 'primeng/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Papa } from 'ngx-papaparse';

var self;

@Component({
  templateUrl: 'contacts.component.html',
  styleUrls: ['contacts.component.less']
})
export class ContactsComponent implements OnInit{

  @ViewChild('dt') table: Table;

  selectedRows: any = [];
  pagination: any = {
    first: 1,
    last: 25
  };
  totalRecords = 0;
  contactDataToShow: any = [];
  contactData: any = [];
  labelFilter: any = {};
  labelFilterOptions:any = [];
  rangeDates: any = [];
  loading: boolean = true;
  cols: any = [];
  newLabelData: any = [];
  labelSaveInProgress: boolean = false;
  reader: FileReader;
  fileUploaded: any;
  parserConfig: any;
  bulkUploadError: boolean = false;
  contactUploadRequiredFields: Array<string> = [
    'firstName',
    'lastName',
    'phoneNumber',
    'emailAddress',
    'optIn'
  ];
  dataForUpload: Array<any> = [];
  activeStep: number = 1;
  sampleData: any;
  contactUploadError: boolean = false;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private contactsService: ContactsService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private papa: Papa,
  ) {
    self = this;
  }
  
  ngOnInit() {
    this.getContacts();
    this.reader = new FileReader();

    // Custom filter for p-table date ranges.
    FilterUtils['dateRangeFilter'] = (value, filter): boolean => {
      if (filter.length === 2) { 
        var startDate = new Date(filter[0]).getTime();
        var endDate = new Date(filter[1]).getTime();
        return (new Date(value).getTime() < endDate && new Date(value).getTime() > startDate);
      } else {
        return false;
      }
    }
    FilterUtils['array-some'] = (value: any[], filters) => {
      if (filters === undefined || filters === null || filters.length === 0) {
          return true;
      }
      return value.some(v => filters.includes(v));
    };
  } 

  getContacts() {
    this.loaderService.triggerLoader(true);
    this.contactsService.getContacts('').subscribe((contacts) => {
      var data = contacts.result;
      self.contactData = [];
      self.labelFilter = {};
      self.labelFilterOptions = [];

      // Prepare model for UI.
      for (var i = 0; i < data.length; i++) {
        var contact = data[i];
        for (var b = 0; b < contact.labels.length; b++) {
          self.labelFilter[contact.labels[b]] = true;
        }
        contact.clientFullName = contact.contactIdentifier;
        contact.clientPhoneNumber = contact.phoneNumber;
        contact.clientEmailAddress = contact.emailAddress;
        self.contactData.push(contact);
      }

      // Set filter for label
      for (var t in self.labelFilter) {
        self.labelFilterOptions.push({
          label: t,
          value: t
        });
      }
      self.contactDataToShow = JSON.parse(JSON.stringify(self.contactData));
      self.totalRecords = self.contactDataToShow.length;
      setTimeout(() => {
        self.loading = false;
      })
      self.loaderService.stopLoader();
    }, (err) => {
      this.loaderService.stopLoader();
      self.loading = false;
      console.error('There was an error retrieving contacts: ', err);
    });
  }

  viewContact(id, e) {
    e.preventDefault();
    this.router.navigate(['/contact'], { queryParams: { id: id, backTo: 'contacts'} });
  }

  paginate(event) {
    this.pagination.first = event.first;
    this.pagination.last = event.first + event.rows;
  }

  onLabelFilterChange(event) {
    this.table.filter(event.value, 'labels', 'array-some');
  }

  onCreatedDateSelect(value) {
    if (this.rangeDates.length === 2) {
      this.rangeDates = [value];
      return;
    } else {
      this.rangeDates.push(value);
    }
    if (this.rangeDates.length === 2) {
      this.rangeDates.sort(function(a, b) {
        return +new Date(a) - +new Date(b);
      });
      this.table.filter(this.rangeDates, 'created', 'dateRangeFilter')
    }
  }

  onUpdatedDateSelect(value) {
    if (this.rangeDates.length === 2) {
      this.rangeDates = [value];
      return;
    } else {
      this.rangeDates.push(value);
    }
    if (this.rangeDates.length === 2) {
      this.rangeDates.sort(function(a, b) {
        return +new Date(a) - +new Date(b);
      });
      this.table.filter(this.rangeDates, 'updated', 'dateRangeFilter')
    }
  }

  addLabelToContacts(e, modal) {
    e.preventDefault();
    this.modalService.open(modal, {
      windowClass: "custom-modal-styles-new"
    }).result.then((result) => {
      // this.sendDate = null;
      // this.sendTime = null;
      // window.location.reload();
    }, () => {
      // this.sendDate = null;
      // this.sendTime = null;
    });
  }

  createNewContacts(e, modal) {
    e.preventDefault();
    this.dataForUpload = [];
    this.bulkUploadError = null;
    this.activeStep = 1;
    this.fileUploaded = null;
    this.contactUploadError = false;
    this.modalService.open(modal, {
      windowClass: "custom-modal-styles-new"
    }).result.then((result) => {
      window.location.reload();
    }, () => {
      // no-op
    });
    setTimeout(() => {
      this.setListenersForFileUpload()
    });
  }

  exitModal(e, modal) {
    e.preventDefault();
    modal.dismiss();
  }

  saveNewLabel(e, modal) {
    this.labelSaveInProgress = true;
    e.preventDefault();
    var idArray = [];
    for (var t = 0; t < this.selectedRows.length; t++) {
      idArray.push(this.selectedRows[t]._id);
    }
    this.contactsService.addLabelToContacts({
      label: this.newLabelData,
      contactIds: idArray
    }).subscribe(() => {
      this.newLabelData = [];
      this.toastService.show('Label created and added to contacts!', {
        classname: 'bg-success text-light',
        delay: 5000
      });
      this.labelSaveInProgress = false;
      modal.dismiss();
      window.location.reload();
    }, (err) => {
      console.error('There was an error adding labels ', err);
      this.toastService.show('There was an error creating your label', {
        classname: 'bg-danger text-light',
        delay: 5000 
      });
      this.labelSaveInProgress = false;
    });
  }

  refresh(): void {
    window.location.reload();
  }

  // Event listener to detect user upload of file.
  setListenersForFileUpload() {
    var file;
    var config;
    document.querySelector("#data-upload").addEventListener('change', () => {
      file = ( < HTMLInputElement > document.getElementById('data-upload')).files[0];
      config = {
        delimiter: "", // auto-detect
        newline: "", // auto-detect
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        transformHeader: undefined,
        dynamicTyping: false,
        preview: 0,
        encoding: "",
        worker: false,
        comments: false,
        step: this.validateTemplateContent,
        complete: this.onFileUploaded,
        error: undefined,
        download: false,
        downloadRequestHeaders: undefined,
        skipEmptyLines: false,
        chunk: undefined,
        fastMode: undefined,
        beforeFirstChunk: undefined,
        withCredentials: undefined,
        transform: undefined,
        delimitersToGuess: [',', '\t', '|', ';', String.fromCharCode(30), String.fromCharCode(31)]
      }
      this.reader.readAsArrayBuffer(file);
    });
    this.reader.onload = () => {
      this.fileUploaded = file;
      this.parserConfig = config;
    };
  }

  // Validate file that user uploads.
  validateTemplateContent(results, parser) {
    parser.pause();
    var requiredFields = self.contactUploadRequiredFields;
    if (results.errors.length > 0 && !Array.isArray(results.data)) {
      console.error('CSV Parser Error: ', results.errors);
      self.bulkUploadError = 'Upload Error! Please check your spreadsheet and try again.';
      for (var i = 0; i < results.errors.length; i++) {
        console.error('There was an error with your uploaded file! ' + results.errors[i]);
        self.toastService.show('There was an error with your uploaded file!', {
          classname: 'bg-danger text-light',
          delay: 7000
        });
      }
      parser.abort();
    }
    var count = 0;
    if (Array.isArray(results.data)) {

      // Empt line... no-op
    } else {
      for (let i in results.data) {

        // Check if field is empty
        if (requiredFields.indexOf(i) >= 0) {
          count = count + 1;
        }
      }

      if (count < requiredFields.length) {
        console.error('Missing Fields! ', count, requiredFields.length);
        parser.abort();
        self.toastService.show('Your uploaded CSV is missing required fields.', {
          classname: 'bg-danger text-light',
          delay: 7000
        });
        self.bulkUploadError = 'Missing fields! Please check your spreadsheet and try again.';
      } else {

        // Save to DB
        results.data.type = self.pageType;

        // Strip out all non-digits.
        results.data.phoneNumber = results.data.phoneNumber.replace(/\D/g,'');
        self.dataForUpload.push(results.data);
        self.sampleData = results.data;
      }
    }
    parser.resume();
  }

  // Handle bulk CSV file upload.
  onFileUploaded(results, file) {
    setTimeout(() => {
      if (!self.bulkUploadError) {
        self.activeStep = 2;
      }
    });
  }

  uploadContacts(e, modal) {
    e.preventDefault();
    this.loaderService.triggerLoader();
    this.contactsService.createContacts({contacts: self.dataForUpload}).subscribe((data) => {
      for (var i = 0; i < data.result.length; i++) {
        if (data.result[i].state === 'rejected') {
          this.contactUploadError = true;
          break;
        }
      }
      this.loaderService.stopLoader();
      if (!this.contactUploadError) {
        this.toastService.show('Contact uploaded!', {
          classname: 'bg-success text-light',
          delay: 5000
        });
      } else {
        this.toastService.show('Some contacts created!', {
          classname: 'bg-info text-light',
          delay: 5000
        });
      }
      modal.close();
    }, (err) => {
      console.error('There was an error uploading your contacts: ', err);
      this.toastService.show('There was an error uploading your contacts', {
        classname: 'bg-danger text-light',
        delay: 5000
      });
      this.contactUploadError = true;
      this.loaderService.stopLoader();
    });
  }

  processUploadedFile(e) {
    e.preventDefault();
    this.papa.parse(this.fileUploaded, this.parserConfig);
  }

  bulkDeleteContacts(e) {
    e.preventDefault();
    this.loaderService.triggerLoader();

    // Create promis array of deletion calls and verify no texts sent.
    var promiseArray: Array<any> = [];
    for (var i = 0; i < this.selectedRows.length; i++) {
      promiseArray.push(new Promise((resolve, reject) => {
        this.contactsService.deleteContact(this.selectedRows[i]._id).subscribe(() => {
          resolve('');
        }, () => {
          reject();
        });
      }));
    }
    Promise.all(promiseArray).
      then(function() {
        self.loaderService.stopLoader();
        self.getContacts();
      }).
      catch(function() {
        self.loaderService.stopLoader();
        this.toastService.show('Some of your contacts couldn\'t be deleted', {
          classname: 'bg-danger text-light',
          delay: 5000
        });
        setTimeout(() => {
          self.getContacts();
        }, 2000);
      });
  }
}
  