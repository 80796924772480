import { Component} from '@angular/core';
import { UsersService, ToastService, MediaService } from '@app/_services';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MediaModalComponent } from '@app/modals';
var self;

@Component({
  selector: 'app-defaults',
  templateUrl: './defaults.component.html',
  styleUrls: ['./defaults.component.less']
})
export class DefaultsComponent {

  modalOptions: NgbModalOptions;
  templateDefaults: any = {
    bannerImage: null,
    leftLogo: null,
    concession: null,
    yourWebsite: null,
    onlineApplicationUrl: null,
    residentPortalUrl: null,
    yourEmailAddress: null,
    yourPhoneNumber: null,
    yourName: null,
    footerContent: null
  };
  loadingImage: any = {
    bannerImage: false,
    leftLogo: false
  }
  savingDefaults: boolean = false;

  constructor(
    private toastService: ToastService,
    private mediaService: MediaService,
    private usersService: UsersService,
    private modalService: NgbModal
  ) {
      self = this;
      this.modalOptions = { windowClass : 'custom-modal-styles-new'};
  }

  uploadMedia(files, property) {
    this.loadingImage[property] = true;
    this.mediaService.getSignedRequestHelper(files, false, property).
        then((data) => {
          setTimeout(() => {
            this.templateDefaults[property] = data
            this.loadingImage[property] = false;
          }, 2000)
        }).
        catch((err) => {
          console.error('There was an error uploading media: ', err);
          this.toastService.show('There was an error uploading media: ' + err, { classname: 'bg-danger text-light', delay: 5000 });
          this.loadingImage[property] = false;
        });
  }

  openMediaModal(event, key) {
    event.preventDefault();
    event.stopPropagation();
    self.modalService.open(MediaModalComponent, self.modalOptions).result.then((result) => {
      if (result && result.url) {
        this.templateDefaults[key] = result.url;
      }
    }, (reason) => {
      if (reason === 'Exit') {
      }
    });
  }

  resetImage(prop) {
    this.templateDefaults[prop] = null;
  }

  saveDefault() {
    this.savingDefaults = true;
    this.usersService.setGlobalDefaults(this.templateDefaults).subscribe(() => {
      this.savingDefaults = false;
      self.toastService.show('Your template defaults have been updated!', { classname: 'bg-success text-light', delay: 5000 });
      this.templateDefaults = {};
    }, (err) => {
      this.savingDefaults = false;
      this.toastService.show('There was an setting your defaults: ' + err, { classname: 'bg-danger text-light', delay: 5000 });
    });
  }
}
