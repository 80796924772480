import { Component, Input } from '@angular/core';

@Component({
  selector: 'concessionemailpreview',
  templateUrl: 'concession-email-preview.component.html',
  styleUrls: ['concession-email-preview.component.less']
})
export class ConcessionEmailPreviewComponent {

  @Input() emailData: any;

  constructor() {
    //no-op
  }
}
  