<div id="single-automation">
  <div class="page-title">
    <h2><a (click)="goBack($event)" href="">&lt; Back To Automations</a></h2><br />
    <h2>{{automationConfig.type | camelcase}}</h2>
    <br />
    <div class="pull-left">
      <h6>Off/On</h6>
      <label class="switch">
      <input type="checkbox" [(ngModel)]="automationConfig.active" (change)="saveConfiguration()">
      <span class="slider round"></span>
      </label>
    </div>
    <button [disabled]="savingAutomation" (click)="saveConfiguration()" class="button button-dark pull-right">
      <span *ngIf="savingAutomation" class="spinner-border spinner-border-sm mr-1"></span>
      Save Configuration
    </button>
  </div>
  <div *ngFor="let step of steps" class="page">
    <div class="step-wrapper card mt-4">
      <div class="card-body dashboard-table">
        <div>
          <h6>Step #{{step.priority +1}}</h6>
          <div class="form-group">
            <label>Text Message Content</label>
            <textarea 
                rows="3"
                class="form-control"
                maxlength="320"
                [(ngModel)]="step.message" [ngModelOptions]="{standalone: true}"
            ></textarea>
          </div>
          <div class="form-group">
            <label>Email Subject</label>
            <input 
                class="form-control"
                [(ngModel)]="step.emailSubjectLine" [ngModelOptions]="{standalone: true}"
            />
          </div>
          <div class="form-group" *ngIf="automationType === 'preLeaseAutomation'">
            <label>Template</label>
            <select class="form-control" [(ngModel)]="step.pageTemplate" (change)="templateTypeChange(step)" [ngModelOptions]="{standalone: true}">
              <option value="Guest Card">Guest Card</option>
              <option value="Apartment Concession">Apartment Concession</option>
            </select>
          </div>
          <div class="form-group" *ngIf="automationType !== 'preLeaseAutomation'">
            <label>Template</label>
            <p>{{step.pageTemplate}}</p>
          </div>
          <div *ngIf="step.timeSinceLastEvent" class="form-group">
            <label>Hours Since Last Message</label>
            <select class="form-control" [(ngModel)]="step.timeSinceLastEvent" [ngModelOptions]="{standalone: true}">
              <option value="24">24</option>
              <option value="48">48</option>
              <option value="72">72</option>
              <option value="96">96</option>
              <option value="120">120</option>
            </select>
          </div>
          <div class="required-template-defaults">
            <label>Required Template Defaults</label>
            <div *ngIf="validDefaults[step.stepId] === undefined" class="loader spinner-border spinner-border-sm"></div>
            <div *ngIf="validDefaults[step.stepId]">
              <div class="alert alert-success">
                Template defaults set. <a routerLink="/settings/defaults">Update Defaults</a>
              </div>
            </div>
            <div *ngIf="!validDefaults[step.stepId] && validDefaults[step.stepId] !== undefined">
              <div class="alert alert-danger">
                Template defaults not set. <a routerLink="/settings/defaults">Set Defaults</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>