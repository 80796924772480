import { Component, AfterViewInit, Input } from '@angular/core';

var self;

@Component({
	selector: 'sheetjs',
	templateUrl: 'sheetjs.component.html',
	styleUrls: ['sheetjs.component.less']
})

export class SheetJSComponent implements AfterViewInit {

	@Input() requiredFields: Array<string>;
	@Input() activePageType: string;
	@Input() userDefaults: any;
	@Input() createPageHandler:Function;
	@Input() modal: any;
	@Input() optionalFields: any;

	_file: any;
	_grid: any;
	cdg: any;
	stepError: any = null;
	activeStep: number;
	stepSuccess: any = null;
	firstRowOfData: number;
	lastRowOfData: number;
	activeIndex: number = 0;
	selectedFirstRow: string;
	selectedLastRow: string;
	selectedColumn: string;
	selectedColumnIndex: number;
	dataColumnsForPages:any = {};
	sampleData: any;
	objectKeys:any = Object.keys;
	finalDataArray: Array<any>;
	customUploadCreatePagesInProgress: boolean = false;
	working: boolean = false;
	specialInstruction: any = {};
	splitOnSpace: any = null;
	activeFileName: any;
	stepOptional: boolean = false;
	activeFieldName: string;
	skippedPages: number = 0;

	constructor(
		//none
	) {
		self = this;
	}

	ngAfterViewInit() {
		this._file = document.getElementById('file');
		this._grid = document.getElementById('grid');
		this.cdg = (window as any).canvasDatagrid({
			parentNode: this._grid,
			allowSorting: false,
			columnHeaderClickBehavior: 'select',
			allowColumnReordering: false,
			editable: false,
			showClearSettingsOption: false,
			showFilter: false,
			showNewRow: false,
			showOrderByOption: false,
			columnSelectorText: '',
			hideColumnText: false,
			showColumnSelector: false
		});
		this.cdg.style.height = '300px';
		this.cdg.style.width = '100%';
		this.cdg.addEventListener('click', function(e, fn) {

			// Reset data collectors.
			self.stepError = null;
			self.stepSuccess = null;
			self.selectedFirstRow = null;
			self.lastRowOfData = null;
			self.selectedLastRow = null;
			self.selectedColumn = null;
			self.selectedColumnIndex = null;
			if (self.activeStep === 1) {
				if (e.cell.style !== 'rowHeaderCell') {
					self.stepError = 'Oops! You didn\'t select a row! Try again.';
				} else {
					self.stepSuccess = 'Great! Ready for the next step?'
					self.firstRowOfData = e.cell.rowIndex;
					self.selectedFirstRow = e.cell.formattedValue;
				}
			} else if (self.activeStep === 2) {
				if (e.cell.style !== 'rowHeaderCell') {
					self.stepError = 'Oops! You didn\'t select a row! Try again.';
				} else {
					self.stepSuccess = 'Great! Ready for the next step?';
					self.lastRowOfData = e.cell.rowIndex;
					self.selectedLastRow = e.cell.formattedValue;
				}
			} else if (self.activeStep > 2) {
				self.removeNewSplitElementButton();
				if (e.cell.style !== 'columnHeaderCell') {
					self.stepError = 'Oops! You didn\'t select a column! Try again.';
				} else {

					// Add split column logic
					if (self.requiredFields[self.activeIndex] === 'clientFirstName') {
						if (/\s/g.test(self.cdg.data[0][e.cell.columnIndex])) {
							self.createNewSplitElementButton(e);
						}
					}

					self.stepSuccess = 'Great! Ready for the next step?';
					self.selectedColumn = e.cell.formattedValue;
					// self.selectedColumnIndex = e.cell.columnIndex;

					// Use this method to solve issue of index being off when column has no data.
					self.selectedColumnIndex = parseInt(e.cell.header.name);
				}
			}
		});
		window.addEventListener('resize', this._resize);
		(window as any).DropSheet({
			file: this._file,
			on: {
				workstart: this._workstart,
				workend: this._workend,
				sheet: this._onsheet,
			},
			errors: {
				badfile: this._badfile,
				pending: this._pending,
				failed: this._failed,
				large: this._large,
			}
		});
	}

	nextStep(e, skip) {
		if (e) {
			e.preventDefault();
		}

		// Reset states for step to prepare for logic.
		this.stepSuccess = false;
		this.splitOnSpace = false;

		// Check that we don't have special instruction buttons showing.
		this.removeNewSplitElementButton();
		if (this.activeStep === 1) {

			// Delete all rows above selected row.
			self.cdg.data.splice(0, this.firstRowOfData);
		} else if (this.activeStep === 2) {

			// Delete all rows above selected row.
			self.cdg.data.splice(this.lastRowOfData + 1, (self.cdg.data.length) - this.lastRowOfData);
			this.activeFieldName = this.requiredFields[this.activeIndex];

		// Here we are selecting columns to represent data on the page.
		} else if (this.activeStep > 2) {

			// This means we are still working through data selection, assign column to required field.
			if ((this.requiredFields.length - 1) >= this.activeIndex) {
				this.dataColumnsForPages[this.requiredFields[this.activeIndex]] = this.selectedColumnIndex;
			}

			// Check to see if we are now dealing with optional fields.
			if ((this.requiredFields.length - 1) < this.activeIndex && !skip) {
				this.dataColumnsForPages[this.optionalFields[this.activeIndex - (this.requiredFields.length)]] = this.selectedColumnIndex;
			}

			// Check to see if we are done.
			if (((this.requiredFields.length - 1) + (this.optionalFields.length)) === this.activeIndex) {
				this.preparePageData();
				this.activeStep++;
				return;
			}

			// Advance index of fields to iterate.
			this.activeIndex++;

			// Check if we should turn optional flag on.
			this.stepOptional = (this.activeIndex > (this.requiredFields.length - 1));

			// Set field name to be shown in UI.
			if (this.stepOptional) {
				this.activeFieldName = this.optionalFields[this.activeIndex - (this.requiredFields.length)];
			} else {
				this.activeFieldName = this.requiredFields[this.activeIndex];
			}

			// Reset selection tools to prepare for next step.
			this.selectedColumn = null;
			this.selectedColumnIndex = null;
		}

		// Advance to next step when all done.
		this.activeStep++;
	}

	removeNewSplitElementButton() {
		var button = document.getElementById('columnSplitter');
		var button2 = document.getElementById('columnSplitter2');
		if (button) {
			button.remove();
		}
		if (button2) {
			button2.remove();
		}
	}

	createNewSplitElementButton(e) {
		
		// First word split
		var newDiv = document.createElement("div"); 
		newDiv.id = 'columnSplitter';
		newDiv.style.position = 'absolute';
		newDiv.style.top = (e.cell.y - 35) + 'px';
		newDiv.style.left = (e.cell.x) + 'px';
		newDiv.innerHTML = '<button class="button button-dark button-small">Use first word</button>';
		document.getElementById('grid').appendChild(newDiv);
		newDiv.addEventListener('click', function(e) {
			e.preventDefault();

			// Add special instructions for column
			self.specialInstruction[self.requiredFields[self.activeIndex]] = 'split'
			self.splitOnSpace = true;
		});

		// Second word split
		var newDiv2 = document.createElement("div"); 
		newDiv2.id = 'columnSplitter2';
		newDiv2.style.position = 'absolute';
		newDiv2.style.top = (e.cell.y - 35) + 'px';
		newDiv2.style.left = (e.cell.x + 110) + 'px';
		newDiv2.innerHTML = '<button class="button button-dark button-small">Use second word</button>';
		document.getElementById('grid').appendChild(newDiv2);
		newDiv2.addEventListener('click', function(e) {
			e.preventDefault();

			// Add special instructions for column
			self.specialInstruction[self.requiredFields[self.activeIndex]] = 'second split'
			self.splitOnSpace = true;
		});
	}

	preparePageData() {
		this.customUploadCreatePagesInProgress = false;
		this.finalDataArray = [];
		for (var i = 0; i < this.cdg.data.length; i++) {
			var skippedRow = false;
			var dataObj = {
				type: this.activePageType
			};
			for (var n in this.dataColumnsForPages) {

				if (!this.cdg.data[i][this.dataColumnsForPages[n]] || this.cdg.data[i][this.dataColumnsForPages[n]].length === 0) {
					this.skippedPages++;
					skippedRow = true;
					break;
				}
				
				// Strip out all non-digits.
				if (n === 'clientPhoneNumber') {
					try {
						this.cdg.data[i][this.dataColumnsForPages[n]] = this.cdg.data[i][this.dataColumnsForPages[n]].replace(/\D/g,'');
					} catch (e) {
						console.warn('could not sanitize phone number! ', e)
					}
				}

				// TODO: Hardcoded to only expect split on clientFirstName, make dynamic.
				if (self.specialInstruction[n] === 'split') {
					dataObj[n] = this.cdg.data[i][this.dataColumnsForPages[n]].split(' ')[0];
				} else if(self.specialInstruction[n] === 'second split') {
					dataObj[n] = this.cdg.data[i][this.dataColumnsForPages[n]].split(' ')[1];
				} else {
					dataObj[n] = this.cdg.data[i][this.dataColumnsForPages[n]];
				}
			}
			if (!skippedRow) {
				this.sampleData = dataObj;
				var finalData = {
					...self.userDefaults,
					...dataObj
				};
				this.finalDataArray.push(finalData);
			}
		}
		this.createPageHandler(this.finalDataArray, this.sampleData, this.skippedPages);
	}

	_workstart() { 
		self.activeStep = 0;
		self.working = true;
		self.activeFileName = self._file.files[0].name;
	}

	_workend() {
		self.working = false;
	}

	/** Alerts **/
	_badfile() {
		alert('This file does not appear to be a valid Excel file.  If we made a mistake, please send this file to Nurture Boss Support so we can take a look.');
	};

	_pending() {
		alert('Please wait until the current file is processed.');
	};

	_large(len, cb:Function) {
		var r = confirm("This file is " + len + " bytes and may take a few moments.  Your browser may lock up during this process.  Shall we continue?");
		if (r) {
			cb();
		}
	};

	_failed(e) {
		alert('Looks like we couldn\'t read this file, please reach out to Nurture Boss Support and send us the file for review');
	};

	make_buttons(sheetnames, cb) {
		var buttons = document.getElementById('buttons');
		buttons.innerHTML = "";
		sheetnames.forEach(function(s,idx) {
			var btn = document.createElement('button');
			btn.className = 'button button-light margin-bottom-24';
			btn.type = 'button';
			btn.name = 'btn' + idx;
			btn.innerText = s;
			btn.addEventListener('click', function(e) {
				cb(idx);
				self.nextStep(e);
			}, false);
			buttons.appendChild(btn);
			buttons.appendChild(document.createElement('br'));
		});
		if (sheetnames.length === 1) {
			self.nextStep(null);
		}
	};

	_resize() {
		this._grid.style.height = '100%';
		this._grid.style.width = '100%';
	}

	_onsheet (json, sheetnames, select_sheet_cb) {
		setTimeout(() => {
			self.make_buttons(sheetnames, select_sheet_cb);
		
			/* show grid */
			self._grid.style.display = 'block';
			self._resize();
		
			/* set up table headers */
			var L = 0;
			json.forEach(function(r) { if(L < r.length) L = r.length; });
			for(var i = json[0].length; i < L; ++i) {
				json[0][i] = "";
			}
		
			/* load data */
			self.cdg.data = json;
		});
	};

	refresh() {
		location.reload();
	}
}