import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TemplatesService, PageTypesService, ToastService, UsersService, AuthenticationService, MediaService, PagesService, LoaderService, RealPageService, SubscriptionsService, ContactsService } from '@app/_services';
import { MediaModalComponent } from '@app/modals';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgWizardConfig, THEME, NgWizardService } from 'ng-wizard';
import { forkJoin, Observable } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { Table } from 'primeng/table';
import { FilterUtils } from 'primeng/utils';
var self;

@Component({
  templateUrl: 'template-create-non-multifamily.component.html',
  styleUrls: ['template-create-non-multifamily.component.less']
})
export class TemplateCreateNonMultifamilyComponent implements OnInit {

  @ViewChild("content") modalContent: TemplateRef<any>;
  @ViewChild('auto') auto;
  @ViewChild('dt') table: Table;

  observableOperations$: Array < Observable < any >> = [];
  changeDetection: any;
  newPageForm: FormGroup;
  userDefaults: any = {};
  dynamicModal: any;
  defaultUpdated: any = {};
  defaultAdded: any = {};
  defaultRemoved: any = {};
  userData: any;
  labelData: Array<any>;
  createPageLoading: boolean = false;
  createPageError: string = '';
  loadingImage: any = {};
  currentLabelSearch: string;
  loading: boolean = true;
  pageType: string;
  modalOptions: NgbModalOptions;
  errorModalOptions: NgbModalOptions;
  reader: FileReader;
  activeTypeRequiredFields: Array < string > = [];
  uploadDisabled: boolean = true;
  bulkUpload: boolean = false;
  masterNurtureTemplateRequiredFields: Array < String > = [
    'callToActionLink',
    'callToActionText',
    'mainText',
    'centerImage',
    'centerLogo',
    'clientFirstName',
    'clientPhoneNumber',
    'pageName',
    'yourEmailAddress',
    'yourName',
    'yourPhoneNumber'
  ];
  realEstateGeneralCommunicationRequiredFields: Array < String > = [
    'callToActionLink',
    'callToActionText',
    'mainText',
    'centerImage',
    'centerLogo',
    'headShot',
    'clientFirstName',
    'clientPhoneNumber',
    'pageName',
    'yourEmailAddress',
    'yourName',
    'yourPhoneNumber'
  ];
  config: NgWizardConfig = {
    selected: 0,
    theme: THEME.dots,
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    },
    anchorSettings: {
      anchorClickable: false
    }
  };
  pageTypeToTemplate: any = {
    'Master Nurture Template': 'master-nurture-template',
    'Real Estate General Communication': 'real-estate-general-communication',
  };
  uploadSelection: any = null;
  fileUploaded: any;
  parserConfig: any;
  isUploading: boolean = false;
  bulkUploadError: any = false;
  requiredDefaults: any;
  requiredFields: any;
  dataForUpload: any = [];
	objectKeys:any = Object.keys;
  sampleData: any;
  disableAll: boolean = false;
  optionalFields: any;
  skippedPages: number;
  pagesRemaining: number;
  pageCreateError: string;
  selectedRows: any = [];
  pagination: any = {
    first: 1,
    last: 25
  };
  totalRecords = 0;
  contactDataToShow: any = [];
  contactData: any = [];
  labelFilter: any = {};
  labelFilterOptions:any = [];
  rangeDates: any = [];
  duplicatesRemoved: number = 0;

  constructor(
    private toastService: ToastService,
    private templatesService: TemplatesService,
    private activatedRoute: ActivatedRoute,
    private pageTypesService: PageTypesService,
    private usersService: UsersService,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    public mediaService: MediaService,
    private modalService: NgbModal,
    private pagesService: PagesService,
    private loaderService: LoaderService,
    private router: Router,
    private ngWizardService: NgWizardService,
    private papa: Papa,
    private subscriptionService: SubscriptionsService,
    private contactsService: ContactsService
  ) {
    
    // Bulk Upload Logic
    // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    self = this;
    this.changeDetection = _.debounce(this.debouncedChangeDetector, 200);
    this.modalOptions = { windowClass : 'custom-modal-styles-new'};
    this.errorModalOptions = { windowClass: 'custom-modal-styles-new error-modal'};
  }

  // Capture the page type we are creating!
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
        this.pageType = params['pageType'];
        if (!this.pageType) {
          this.router.navigate(['templates']);
        } else  {
          this.dynamicModal = this.pageTypesService.loadModalData(this.pageType);
          this.loadData();
        }
        if (params['bulk']) {
          this.bulkUpload = true;
          this.reader = new FileReader();
        }
    });
    this.subscriptionService.getSubscription().subscribe((data) => {
      this.pagesRemaining = data.result.remainingUsage;
    }, (err) => {
      console.error('There was an error retrieving subscription data: ', err);
    });

    // Custom filter for p-table date ranges.
    FilterUtils['dateRangeFilter'] = (value, filter): boolean => {
      if (filter.length === 2) { 
        var startDate = new Date(filter[0]).getTime();
        var endDate = new Date(filter[1]).getTime();
        return (new Date(value).getTime() < endDate && new Date(value).getTime() > startDate);
      } else {
        return false;
      }
    }
    FilterUtils['array-some'] = (value: any[], filters) => {
      if (filters === undefined || filters === null || filters.length === 0) {
          return true;
      }
      return value.some(v => filters.includes(v));
    };
  } 

  debouncedChangeDetector(e, name) {    
    if (this.newPageForm.controls[name].value.length === 0) {
      this.userDefaults[name] = null;
      this.templatesService.updateTemplateDefaults(this.dynamicModal.pageType, this.userDefaults).subscribe((defaults) => {
        this.userDefaults = defaults.result;
        this.showHideDefaultUpdated(name, 'defaultRemoved');
      }, (err) => {
        console.error('There was an error saving template defaults: ', err);
        this.toastService.show('There was an error saving template defaults', { classname: 'bg-danger text-light', delay: 5000 });
      });
    } else if (this.newPageForm.controls[name].value.length > 1 && this.userDefaults[name]) {
      this.userDefaults[name] = this.newPageForm.controls[name].value;
      this.templatesService.updateTemplateDefaults(this.dynamicModal.pageType, this.userDefaults).subscribe((defaults) => {
        this.userDefaults = defaults.result;
        this.showHideDefaultUpdated(name, 'defaultUpdated');
      }, (err) => {
        console.error('There was an error saving template defaults: ', err);
        this.toastService.show('There was an error saving template defaults', { classname: 'bg-danger text-light', delay: 5000 });
      });
    }
  }

  showHideDefaultUpdated(name, type) {

    // Set all messages to false first.
    this.defaultRemoved = {};
    this.defaultAdded = {};
    this.defaultUpdated = {};
    this[type][name] = true;
    setTimeout(() => {
      this[type][name] = false;
    }, 3000);
  }

  loadData() {
    this.loaderService.triggerLoader();

    // Load user data for apartment name.
    this.usersService.getUserData(this.authService.currentUserValue.user._id).subscribe((user) => {
      this.userData = user.result;
      this.templatesService.getTemplateDefaults(this.dynamicModal.pageType).subscribe((response) => {
  
        // If no defaults exists, create them.
        if (response.result.length === 0) {
          this.userDefaults = {};
          this.userDefaults.templateName = this.dynamicModal.pageType;
          this.templatesService.createTemplateDefaults(this.userDefaults).subscribe((defaults) => {
            this.userDefaults = defaults.result;
          }, (err) => {
            console.error('There was an error saving template defaults: ', err);
            this.loaderService.stopLoader();
            this.toastService.show('There was an error saving template defaults', { classname: 'bg-danger text-light', delay: 5000 });
          });
        } else {
          this.userDefaults = response.result;
        }
        this.initiateForm();
      }, (e) => {
        this.loaderService.stopLoader();
        console.error('Error retrieving template defalts ', e);
        this.loading = false;
      });
    }, (e) => {
      this.loaderService.stopLoader();
      console.error('Error retrieving user data ', e);
      this.loading = false;
    });
  }

  initiateForm() {
    var formGroup:any = {
      pageName: ['', Validators.required],
      centerLogo: [this.userDefaults.centerLogo || '', Validators.required],
      centerImage: [this.userDefaults.centerImage || '', Validators.required],
      callToActionLink: [this.userDefaults.callToActionLink || '', Validators.required],
      callToActionText: [this.userDefaults.callToActionText || '', Validators.required],
      yourPhoneNumber: [this.userDefaults.yourPhoneNumber || '', Validators.required],
      yourEmailAddress: [this.userDefaults.yourEmailAddress || '', Validators.required],
      yourName: [this.userDefaults.yourName || '', Validators.required],
      footerContent: [this.userDefaults.footerContent || ''],
      clientFirstName: ['', Validators.required],
      clientPhoneNumber: ['', Validators.required],
      type: ['', Validators.required],
    };

    // Insert all dynamic fields into form group.
    // for (var i = 0; i < this.dynamicModal.fields.length; i++) {
    //   var field = this.dynamicModal.fields[i];
    //   formGroup[field.key] = ['']
    //   if (field.required) {
    //     formGroup[field.key].push(Validators.required);
    //   }
    // }

    if (this.pageType === 'Master Nurture Template' || this.pageType === 'Real Estate General Communication') {
      formGroup.mainText = [this.userDefaults.mainText || '', Validators.required];
    }
    if (this.pageType === 'Digital Business Card' || this.pageType === 'Real Estate General Communication') {
      formGroup.facebookUrl = [this.userDefaults.facebookUrl || ''];
      formGroup.twitterUrl = [this.userDefaults.twitterUrl || ''];
      formGroup.instagramUrl = [this.userDefaults.instagramUrl || ''];
      formGroup.youtubeUrl = [this.userDefaults.youtubeUrl || ''];
      formGroup.headShot = [this.userDefaults.headShot || '', Validators.required];
      formGroup.yourTitle = [this.userDefaults.yourTitle || '', Validators.required];
      formGroup.scheduleMeetingUrl = [this.userDefaults.scheduleMeetingUrl || ''];
    }
    this.newPageForm = this.formBuilder.group(formGroup);
    this.newPageForm.controls.type.setValue(this.dynamicModal.pageType);
    this.newPageForm.get('centerImage').valueChanges.subscribe(val => {
      if (!val) {
        this.changeControlOnFormImages('centerImage')
      } else if (val.length && val.length > 0) {
      }
    });
    this.newPageForm.get('centerLogo').valueChanges.subscribe(val => {
      if (!val) {
        this.changeControlOnFormImages('centerLogo')
      } else if (val.length && val.length > 0) {
      }
    });
    if (this.pageType === 'Digital Business Card' || this.pageType === 'Real Estate General Communication') {
      this.newPageForm.get('headShot').valueChanges.subscribe(val => {
        if (!val) {
          this.changeControlOnFormImages('headShot')
        } else if (val.length && val.length > 0) {
        }
      });
    }

    this.loading = false;
    this.loaderService.stopLoader();

    // TODO IF BULK UPLOAD
    if (this.bulkUpload) {
      this.bulkUploadNewPage();
      this.newPageForm.valueChanges.subscribe(() => {

        // Triger re-evaluation of bulk upload readiness
        this.bulkUploadNewPage();
      }, (err) => {
        console.error('There was an error listening to form changes! ', err);
      });
    }
  }

  changeControlOnFormImages(name) {
    this.debouncedChangeDetector({target: {checked: false}}, name);
  }

  savePage(form) {
    this.createPageLoading = true;
    this.createPageError = '';
    var data = form.getRawValue();

    this.pagesService.createPage(data).subscribe(page => {
      this.createPageLoading = false;
      this.router.navigate(['pages'], { queryParams: { fromCreate: true }, state: {pagesCreated: page.result}});
    }, (err) => {
      this.createPageLoading = false;
      this.createPageError = err;
    });
  }

  exitCreateNew() {
    this.router.navigate(['templates']);
  }

  openMediaModal(event, form, key) {
    event.preventDefault();
    event.stopPropagation();
    self.modalService.open(MediaModalComponent, self.modalOptions).result.then((result) => {
      self.activeTextData = {};
      if (result && result.url) {
        form.controls[key].setValue(result.url);
      }
    }, (reason) => {
      self.activeTextData = {};
      if (reason === 'Exit') {
      }
    });
  }
  
  resetImage(prop, form) {
    form.controls[prop].setValue('');
  }

  uploadMedia(files, form, property) {
    this.loadingImage[property] = true;
    this.mediaService.getSignedRequestHelper(files, form, property).
        then((data) => {
          var patch = {};
          patch[property] = data;
          setTimeout(() => {
            form.patchValue(patch);
            this.loadingImage[property] = false;
          }, 2000)
        }).
        catch((err) => {
          console.error('There was an error uploading media: ', err);
          this.toastService.show('There was an error uploading media: ' + err, { classname: 'bg-danger text-light', delay: 5000 });
          this.loadingImage[property] = false;
        });
  }

  updateDefaults(e, name) {

    // Listen for user un-checking make default box
    if (Object.keys(this.userDefaults).length > 0 && !e.target.checked) {
      this.userDefaults[name] = null;
      this.templatesService.updateTemplateDefaults(this.dynamicModal.pageType, this.userDefaults).subscribe((defaults) => {
        this.userDefaults = defaults.result;
        this.showHideDefaultUpdated(name, 'defaultRemoved');
      }, (err) => {
        console.error('There was an error saving template defaults: ', err);
        this.toastService.show('There was an error saving template defaults', { classname: 'bg-danger text-light', delay: 5000 });
      });

    // Listen for user checking make default box
    }  else if (Object.keys(this.userDefaults).length > 0 && e.target.checked) {
      this.userDefaults[name] = this.newPageForm.controls[name].value;
      this.templatesService.updateTemplateDefaults(this.dynamicModal.pageType, this.userDefaults).subscribe((defaults) => {
        this.userDefaults = defaults.result;
        this.showHideDefaultUpdated(name, 'defaultAdded');
      }, (err) => {
        console.error('There was an error saving template defaults: ', err);
        this.toastService.show('There was an error saving template defaults', { classname: 'bg-danger text-light', delay: 5000 });
      });
    }
  }

  checkSectionValidity(section) {
    if (section === 'organizationalSettings') {
      if (this.newPageForm.controls.pageName.valid || this.bulkUpload) {
        this.safelyAdjustClassName('#organizational-settings-header', false);
        return true;
      } else {
        this.safelyAdjustClassName('#organizational-settings-header', true);
        return false;
      }
    } else if (section === 'generalInfo') {
      if ( this.pageType === 'Master Nurture Template' &&
        this.newPageForm.controls.centerLogo.valid &&
        this.newPageForm.controls.centerImage.valid &&
        this.newPageForm.controls.callToActionLink.valid &&
        this.newPageForm.controls.callToActionText.valid &&
        this.newPageForm.controls.mainText.valid
      ) {
        this.safelyAdjustClassName('#general-info-header', false);
        return true;
      } else if ( this.pageType === 'Digital Business Card' &&
        this.newPageForm.controls.centerLogo.valid &&
        this.newPageForm.controls.centerImage.valid &&
        this.newPageForm.controls.headShot.valid &&
        this.newPageForm.controls.callToActionLink.valid &&
        this.newPageForm.controls.callToActionText.valid
      ) {
        this.safelyAdjustClassName('#general-info-header', false);
        return true;
      } else if ( this.pageType === 'Real Estate General Communication' &&
        this.newPageForm.controls.centerLogo.valid &&
        this.newPageForm.controls.centerImage.valid &&
        this.newPageForm.controls.headShot.valid &&
        this.newPageForm.controls.callToActionLink.valid &&
        this.newPageForm.controls.callToActionText.valid &&
        this.newPageForm.controls.mainText.valid
      ) {
        this.safelyAdjustClassName('#general-info-header', false);
        return true;
      } else {
        this.safelyAdjustClassName('#general-info-header', true);
        return false;
      }
    } else if (section == 'yourContactInfo') {

      // Commented out until we introduce dynamic fields
      // Get custom fields to check validity
      // for (var i = 0; i < this.dynamicModal.fields.length; i++) {
      //   if (this.dynamicModal.fields[i].required && !this.newPageForm.controls[this.dynamicModal.fields[i].key].valid) {
      //     valid = false;
      //     break;
      //   }
      // }

      if ( this.pageType === 'Master Nurture Template' && 
        this.newPageForm.controls.yourPhoneNumber.valid &&
        this.newPageForm.controls.yourEmailAddress.valid &&
        this.newPageForm.controls.yourName.valid
      ) {
        this.safelyAdjustClassName('#your-contact-info-header', false);
        return true;
      } else if ((this.pageType === 'Digital Business Card' || this.pageType === 'Real Estate General Communication') && 
        this.newPageForm.controls.yourPhoneNumber.valid &&
        this.newPageForm.controls.yourEmailAddress.valid &&
        this.newPageForm.controls.yourTitle.valid &&
        this.newPageForm.controls.yourName.valid
      ) {
        this.safelyAdjustClassName('#your-contact-info-header', false);
        return true;
      } else {
        this.safelyAdjustClassName('#your-contact-info-header', true);
        return false;
      }
    } else if (section === 'otherInfo') {
      this.safelyAdjustClassName('#other-info-header', false);
      return true;
    } else if (section == 'prospectInfo') {

      // Commented out until we introduce dynamic fields
      // Get custom fields to check validity
      // for (var i = 0; i < this.dynamicModal.fields.length; i++) {
      //   if (this.dynamicModal.fields[i].required && !this.newPageForm.controls[this.dynamicModal.fields[i].key].valid) {
      //     valid = false;
      //     break;
      //   }
      // }

      if (
        this.newPageForm.controls.clientFirstName.valid &&
        this.newPageForm.controls.clientPhoneNumber.valid
      ) {
        this.safelyAdjustClassName('#custom-fields-header', false);
        return true;
      } else {
        this.safelyAdjustClassName('#custom-fields-header', true);
        return false;
      }
    } else {
      return false;
    }
  }

  safelyAdjustClassName(querySelector, unset) {
    if (document.querySelector(querySelector) && unset) {
      document.querySelector(querySelector).className = 'card-header';
    } else if (document.querySelector(querySelector) && !unset) {
      document.querySelector(querySelector).className = 'card-header valid-section';
    }
  }

  bulkUploadNewPage() {
    this.activeTypeRequiredFields.length = 0;
    switch (this.pageType) {
      case 'Master Nurture Template':
        self.requiredDefaults = ['pageName', 'clientFirstName', 'clientPhoneNumber'];
        self.requiredFields = self.masterNurtureTemplateRequiredFields;
        self.optionalFields = [];
        break;
      case 'Real Estate General Communication':
        self.requiredDefaults = ['pageName', 'clientFirstName', 'clientPhoneNumber'];
        self.requiredFields = self.realEstateGeneralCommunicationRequiredFields;
        self.optionalFields = [];
        break;
    }

    // Check if the required fields to generate page have been populated in form.
    for (var i = 0; i < self.requiredFields.length; i++) {
      if (!this.newPageForm.getRawValue()[self.requiredFields[i]]) {

        // These are the ACTUAL remaining required fields needed to generate page.
        this.activeTypeRequiredFields.push(self.requiredFields[i])
      }
    }

    // If there are required fields needed still that ARE NOT expected on spreadsheet, disable upload.
    this.uploadDisabled = !_.isEqual(this.activeTypeRequiredFields.sort(), this.requiredDefaults.sort());
  }

  selectUploadMethod(e, method) {
    this.uploadSelection = method;
    if (e) {
      e.preventDefault();
    }
    if (method === 'nurtureBoss') {
      setTimeout(() => {
        this.setListenersForFileUpload();
      });
    }
    this.ngWizardService.next();
  }

  processUploadedFile(e) {
    e.preventDefault();
    this.papa.parse(this.fileUploaded, this.parserConfig);
  }

  goToNextStep(e, dedupe, isEmail) {
    if (e) {
      e.preventDefault();
    }
    if (dedupe) {
      var dedupeField;
      if (isEmail) {
        dedupeField = 'clientEmailAddress';
      } else {
        dedupeField = 'clientPhoneNumber';
      }
      var dedupedDataForUpload = this.dedupeArray(this.dataForUpload, dedupeField);
      this.duplicatesRemoved = this.dataForUpload.length - dedupedDataForUpload.length;
      this.dataForUpload = dedupedDataForUpload;
    }
    this.ngWizardService.next();
  }

  // Event listener to detect user upload of file.
  setListenersForFileUpload() {
    var file;
    var config;
    document.querySelector("#data-upload").addEventListener('change', () => {
      file = ( < HTMLInputElement > document.getElementById('data-upload')).files[0];
      config = {
        delimiter: "", // auto-detect
        newline: "", // auto-detect
        quoteChar: '"',
        escapeChar: '"',
        header: true,
        transformHeader: undefined,
        dynamicTyping: false,
        preview: 0,
        encoding: "",
        worker: false,
        comments: false,
        step: this.validateTemplateContent,
        complete: this.onFileUploaded,
        error: undefined,
        download: false,
        downloadRequestHeaders: undefined,
        skipEmptyLines: false,
        chunk: undefined,
        fastMode: undefined,
        beforeFirstChunk: undefined,
        withCredentials: undefined,
        transform: undefined,
        delimitersToGuess: [',', '\t', '|', ';', String.fromCharCode(30), String.fromCharCode(31)]
      }
      this.reader.readAsArrayBuffer(file);
    });
    this.reader.onload = () => {
      this.fileUploaded = file;
      this.parserConfig = config;
    };
  }

  // Validate file that user uploads.
  validateTemplateContent(results, parser) {
    parser.pause();
    self.bulkUploadError = false;
    var requiredFields = self.activeTypeRequiredFields;
    if (results.errors.length > 0 && !Array.isArray(results.data)) {
      console.error('CSV Parser Error: ', results.errors);
      self.bulkUploadError = 'Upload Error! Please check your spreadsheet and try again. Click START OVER below when ready.';
      for (var i = 0; i < results.errors.length; i++) {
        console.error('There was an error with your uploaded file! ' + results.errors[i]);
        self.toastService.show('There was an error with your uploaded file!', {
          classname: 'bg-danger text-light',
          delay: 7000
        });
      }
      parser.abort();
    }
    var count = 0;
    if (Array.isArray(results.data)) {

      // Empt line... no-op
    } else {
      for (let i in results.data) {

        // Check if field is empty
        if (!results.data[i] || results.data[i].length === 0) {
          if (self.userDefaults[i]) {
            delete results.data[i];
          }
        } else {
          if (requiredFields.indexOf(i) >= 0) {
            count = count + 1;
          }
        }
      }

      if (count < requiredFields.length) {
        console.error('Missing Fields! ', count, requiredFields.length);
        parser.abort();
        self.toastService.show('Your uploaded CSV is missing required fields.', {
          classname: 'bg-danger text-light',
          delay: 7000
        });
        self.bulkUploadError = 'Missing fields! Please check your spreadsheet and try again. Click START OVER below when ready.';
      } else {

        // Save to DB
        results.data.type = self.pageType;

        // Strip out all non-digits.
        results.data.clientPhoneNumber = results.data.clientPhoneNumber.replace(/\D/g,'');
        var finalData = {
          ...self.userDefaults,
          ...results.data
        };
        self.dataForUpload.push(finalData);
        self.sampleData = results.data;
      }
    }
    parser.resume();
  }

  // Handle bulk CSV file upload.
  onFileUploaded(results, file) {
    self.ngWizardService.next();
  }

  createPages(e) {
    e.preventDefault();
    self.isUploading = true;
    self.pageCreateError = '';
    for (var b = 0; b < self.dataForUpload.length; b++) {
      self.observableOperations$.push(self.pagesService.createPage(self.dataForUpload[b]));
    }
    forkJoin(...self.observableOperations$).subscribe((results) => {
      var dataToPass = [];
      var showModal = false;
      for (var i = 0; i < results.length; i++) {
        if (results[i].result) {
          dataToPass.push(results[i].result);
        } else {
          showModal = true;
          self.pageCreateError = results[i].error;
        }
      }
      self.isUploading = false;
      self.observableOperations$ = [];
      if (showModal) {
        this.modalService.open(this.modalContent, this.errorModalOptions).result.then(() => {
          this.router.navigate(['pages'], { queryParams: { fromCreate: true }, state: {pagesCreated: dataToPass}});
        });
      } else {
        this.router.navigate(['pages'], { queryParams: { fromCreate: true }, state: {pagesCreated: dataToPass}});
      }
    });
  }

  completeSpreadsheetSelection(data, sampleData, skippedPages) {
    self.dataForUpload = data;
    self.sampleData = sampleData
    self.ngWizardService.next();
    self.skippedPages = skippedPages;
  }

  refresh(): void {
    window.location.reload();
  }

  getContacts(e) {
    this.disableAll = true;
    this.loaderService.triggerLoader(true);
    if (e) {
      e.preventDefault();
    }
    this.contactsService.getContacts('').subscribe((contacts) => {
      var data = contacts.result;
      self.contactData = [];
      self.labelFilter = {};
      self.labelFilterOptions = [];

      // Prepare model for UI.
      for (var i = 0; i < data.length; i++) {
        var contact = data[i];
        for (var b = 0; b < contact.labels.length; b++) {
          self.labelFilter[contact.labels[b]] = true;
        }
        contact.clientFullName = contact.contactIdentifier;
        contact.clientFirstName = contact.firstName;
        contact.clientPhoneNumber = contact.phoneNumber;
        contact.clientEmailAddress = contact.emailAddress;
        self.contactData.push(contact);
      }

      // Set filter for label
      for (var t in self.labelFilter) {
        self.labelFilterOptions.push({
          label: t,
          value: t
        });
      }
      self.contactDataToShow = JSON.parse(JSON.stringify(self.contactData));
      self.totalRecords = self.contactDataToShow.length;
      self.disableAll = false;
      self.loaderService.stopLoader();
      self.selectUploadMethod(null, 'contacts');
    }, (err) => {
      this.loaderService.stopLoader();
      console.error('There was an error retrieving contacts: ', err);
    });
  }

  continueToDataVerification(e) {
    if (e) {
      e.preventDefault();
    }
    // Logic for p-table
    self.skippedPages = 0;
    if (this.selectedRows.length > 0) {
      for (var t = 0; t < this.selectedRows.length; t++) {
        try {
          var createObjectForPage:any = {
            type: this.pageType,
            clientFirstName: this.selectedRows[t].clientFirstName,
            pageName: this.selectedRows[t].clientFullName,
            clientPhoneNumber: this.selectedRows[t].clientPhoneNumber.replace(/\D/g,''),
            clientEmailAddress: this.selectedRows[t].clientEmailAddress
          };
          delete self.userDefaults.created;
          delete self.userDefaults.ownerId;
          delete self.userDefaults.updated;
          delete self.userDefaults.__v;
          delete self.userDefaults._id;
          var finalData = {
            ...self.userDefaults,
            ...createObjectForPage
          };
          self.dataForUpload.push(finalData);
          self.sampleData = finalData;
        } catch (e) {
          self.skippedPages++;
        }
      }
    }
    self.goToNextStep(false, true, false);
  }

  paginate(event) {
    this.pagination.first = event.first;
    this.pagination.last = event.first + event.rows;
  }

  onLabelFilterChange(event) {
    this.table.filter(event.value, 'labels', 'array-some');
  }

  onCreatedDateSelect(value) {
    if (this.rangeDates.length === 2) {
      this.rangeDates = [value];
      return;
    } else {
      this.rangeDates.push(value);
    }
    if (this.rangeDates.length === 2) {
      this.rangeDates.sort(function(a, b) {
        return +new Date(a) - +new Date(b);
      });
      this.table.filter(this.rangeDates, 'created', 'dateRangeFilter')
    }
  }

  onUpdatedDateSelect(value) {
    if (this.rangeDates.length === 2) {
      this.rangeDates = [value];
      return;
    } else {
      this.rangeDates.push(value);
    }
    if (this.rangeDates.length === 2) {
      this.rangeDates.sort(function(a, b) {
        return +new Date(a) - +new Date(b);
      });
      this.table.filter(this.rangeDates, 'updated', 'dateRangeFilter')
    }
  }

  dedupeArray(array, prop) {
    var obj = {};
    for (var i = 0; i < array.length; i++ ) {
      obj[array[i][prop]] = array[i];
    }
    var newArray = new Array();
    for (var key in obj) {
      newArray.push(obj[key]);
    }
    return newArray;
  }
}
  