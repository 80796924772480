import {
    Component, ViewChild
} from '@angular/core';
import { LoaderService, AnalyticsService, ToastService } from '@app/_services';
import * as _ from 'lodash';
import {NgbDate, NgbCalendar, NgbDateParserFormatter, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

var self;

@Component({
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.less']
})
export class DashboardComponent {

  @ViewChild('datepicker') datePicker: NgbInputDatepicker;

  prospectEventData: Array<any> = [];
  groupByProspectKeep: any = [];
  engagedProspects: number = 0;
  totalEvents: number = 0;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  constructor(
    private loaderService: LoaderService,
    private analyticsService: AnalyticsService,
    private toastService: ToastService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private router: Router
  ) {
    this.fromDate = this.calendar.getPrev(calendar.getToday(), 'd', 30);
    this.toDate = this.calendar.getToday();

    self = this;
    this.runQuery();
  }

  runQuery(startDate?, endDate?) {
    this.loaderService.triggerLoader();

    // Retrieve analytics
    this.analyticsService.runQuery(startDate, endDate).subscribe((data) => {
      
      // Group all events by day for "Total Events (30 Days)"
      for (var i = 0; i < data.result.length; i++) {

        // Check for errors.
        if (data.result[i].error) {
          this.toastService.show('There was an error loading analytics', { classname: 'bg-danger text-light', delay: 5000 });
        }
      }

      // Group all events by Page Name as unique key.
      self.groupByProspectKeep = _.groupBy(data.result, 'contactid');

      // Create custom object for table.
      self.groupByProspectKeep = Object.keys(self.groupByProspectKeep)
        .map(function(k) {
          return {
            pageName: self.groupByProspectKeep[k][0].pagename,
            engagementScore: self.groupByProspectKeep[k].length,
            pageId: self.groupByProspectKeep[k][0].pageid,
            rawData: self.groupByProspectKeep[k],
            contactId: self.groupByProspectKeep[k][0].contactid
          };
        })
        .sort(function(a, b) {
          return b.engagementScore - a.engagementScore;
        });

      // Sort interactions by date for most recent.
      for (var t = 0; t < self.groupByProspectKeep.length; t++) {
        self.groupByProspectKeep[t].rawData = self.groupByProspectKeep[t].rawData.sort(function(a:any, b:any) {
          return +new Date(b.time) - +new Date(a.time);
        });
      }

      // Build final model for UI.
      self.groupByProspectKeep = self.groupByProspectKeep.map(function(k) {
        if (k.engagementScore > 5) {
          self.engagedProspects++;
        }
        self.totalEvents = self.totalEvents + k.engagementScore;
        return {
          pageName: k.pageName,
          engagementScore: k.engagementScore,
          pageId: k.pageId,
          lastSeen: new Date(+k.rawData[0].time),
          contactId: k.contactId
        };
      });
      this.loaderService.stopLoader();
    }, (err) => {
      console.error('ERROR: ', err);
      this.loaderService.stopLoader();
      this.toastService.show('There was an error loading analytics', { classname: 'bg-danger text-light', delay: 5000 });
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  triggerUpdate(e) {
    e.preventDefault();
    if (this.fromDate && this.toDate) { 
      var startDate = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).getTime();
      var endDate = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day).getTime();
      this.runQuery(startDate, endDate);
      this.datePicker.close();
    }
  }

  close() {
    this.datePicker.close();
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  viewContact(id, e, engagementScore) {
    e.preventDefault();
    this.router.navigate(['/contact'], { queryParams: { id: id, engagementScore: engagementScore } });
  }
}
  