import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class PaymentMethodsService {

    constructor(
        private http: HttpClient,
        private apiHelper: APIHelperService
    ) {
        //no-op
    }

    getPaymentMethods() {
        return this.http.get<any>(this.apiHelper.fillUrl('paymentMethods', {}, {}))
            .pipe(map(paymentMethods => {
                return paymentMethods;
            }));
    }

    attachToCustomer(paymentMethodId) {
        return this.http.post<any>(this.apiHelper.fillUrl('paymentAttach', {}, {}), {paymentMethodId: paymentMethodId})
            .pipe(map(paymentMethods => {
                return paymentMethods;
            }));
    }
}