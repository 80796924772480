<div id="dashboard">
    <div class="page-title">
        <!-- <div class="actions left">
            <i class="far fa-bars"></i>
        </div> -->
        <h2>Dashboard</h2>
        <!-- <div class="actions right">
            <i class="far fa-search"></i>
        </div> -->
    </div>
    <div class="row widget-holder mt-4">
        <div class="col-sm-4">
            <div class="row widget">
                <div class="col-sm-8">
                    <p class="widget-title">Engaged Prospects</p>
                    <p class="widget-data">{{engagedProspects}}</p>
                </div>
                <div class="col-sm-4">
                    <div class="widget-icon blue">
                        <i class="far fa-chart-area"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="row widget">
                <div class="col-sm-8">
                    <p class="widget-title">Total Prospects</p>
                    <p class="widget-data">{{groupByProspectKeep.length}}</p>
                </div>
                <div class="col-sm-4">
                    <div class="widget-icon orange">
                        <i class="fal fa-user-clock"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="row widget">
                <div class="col-sm-8">
                    <p class="widget-title">Total Events</p>
                    <p class="widget-data">{{totalEvents}}</p>
                </div>
                <div class="col-sm-4">
                    <div class="widget-icon red">
                        <i class="far fa-chart-line"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mt-4">
        <div class="card-body dashboard-table">
            <form class="form-inline date-range-selection">
                <div class="form-group hide">
                  <div class="input-group">
                    <input name="datepicker"
                           class="form-control"
                           ngbDatepicker
                           #datepicker="ngbDatepicker"
                           [autoClose]="false"
                           (dateSelect)="onDateSelection($event)"
                           [displayMonths]="2"
                           [dayTemplate]="t"
                           [footerTemplate]="f"
                           outsideDays="hidden"
                           [startDate]="fromDate!">
                    <ng-template #t let-date let-focused="focused">
                      <span class="custom-day"
                            [class.focused]="focused"
                            [class.range]="isRange(date)"
                            [class.faded]="isHovered(date) || isInside(date)"
                            (mouseenter)="hoveredDate = date"
                            (mouseleave)="hoveredDate = null">
                        {{ date.day }}
                      </span>
                    </ng-template>
                    <ng-template #f>
                        <button [disabled]="!fromDate || !toDate" (click)="triggerUpdate($event, datepicker)" class="update-search button button-light">Update Search</button>
                        <button (click)="close()" class="cancel-search button button-white">Cancel</button>
                      </ng-template>
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group">
                    <input #dpFromDate
                           class="form-control" placeholder="mm-dd-yyyy"
                           name="dpFromDate"
                           [value]="formatter.format(fromDate)"
                           (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button"><i class="fal fa-calendar-alt"></i></button>
                    </div>
                  </div>
                </div>
                <div class="form-group ml-2">
                  <div class="input-group">
                    <input #dpToDate
                           class="form-control" placeholder="mm-dd-yyyy"
                           name="dpToDate"
                           [value]="formatter.format(toDate)"
                           (input)="toDate = validateInput(toDate, dpToDate.value)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button"><i class="fal fa-calendar-alt"></i></button>
                    </div>
                  </div>
                </div>
            </form>
            <div class="row table-header">
                <div class="col-sm-4">Full Name</div>
                <div class="col-sm-3">Contact Profile</div>
                <div class="col-sm-3">Last Seen</div>
                <div class="col-sm-2">Engagement Score</div>
            </div>
            <div class="row table-data" *ngFor="let page of groupByProspectKeep">
                <div class="col-sm-4 truncate">
                    <div *ngIf="page.engagementScore > 3" class="high-engagement"></div>
                    <div *ngIf="page.engagementScore > 1 && page.engagementScore <= 3" class="med-engagement"></div>
                    <div *ngIf="page.engagementScore <= 1" class="low-engagement"></div>
                    {{page.pageName}}
                </div>
                <div class="col-sm-3"><a href="" *ngIf="page.contactId" (click)="viewContact(page.contactId, $event, page.engagementScore)">View Contact</a></div>
                <div class="col-sm-3">{{page.lastSeen | date }}</div>
                <div class="col-sm-2">{{page.engagementScore}}</div>
            </div>
        </div>
    </div>
</div>