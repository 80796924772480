<div id="new-page">
  <div class="page-title">
    <!-- <div class="actions left">
      <i class="far fa-bars"></i>
      </div> -->
    <h2>Templates / {{pageType}}</h2>
    <p class="remaing-pages-info"><strong>Remaing Pages Available: {{pagesRemaining}}</strong></p>
    <!-- <div class="actions right">
      <i class="far fa-search"></i>
      </div> -->
  </div>
  <div class="card mt-4">
    <div class="card-body new-pages">
      <form [formGroup]="newPageForm" (ngSubmit)="savePage(newPageForm)" *ngIf="!loading">
      <ngb-accordion [closeOthers]="true" activeIds="custom-fields">
        <ngb-panel id="organizational-settings" [disabled]="uploadSelection">
          <ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('organizationalSettings')">
						<span>Prospect Full Name</span>
						<i class="fa fa-chevron-down pull-right"></i>
						<div *ngIf="checkSectionValidity('organizationalSettings')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row form-group" *ngIf="!bulkUpload">
              <label for="pageName">Full Name</label>
              <input placeholder="We recommend using prospects name or email" type="text" formControlName="pageName" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.pageName.errors }" />
              <div *ngIf="submitted && newPageForm.controls.pageName.errors" class="invalid-feedback">
                <div *ngIf="newPageForm.controls.pageName.errors.required">Full Name is required</div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="general-info" [disabled]="uploadSelection">
					<ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('generalInfo')">
						<span>General Info</span>
						<i class="fa fa-chevron-down pull-right"></i>
						<div *ngIf="checkSectionValidity('generalInfo')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="form-group">
              <label for="centerImage">Main Image</label>
              <div class="row">
                <div class="col-sm-4">
                  <img class="preview-image" *ngIf="newPageForm.controls.centerImage.value && !loadingImage.centerImage" [src]="newPageForm.controls.centerImage.value" />
                  <p *ngIf="newPageForm.controls.centerImage.value && !loadingImage.centerImage" (click)="resetImage('centerImage', newPageForm)" class="remove-default"><i class="fal fa-trash-alt"></i> Remove Image</p>
                </div>
                <div class="col-sm-4">
                </div>
                <div class="col-sm-4 default-actions">
                  <span class="default-label">Save as default</span>
                  <input type="checkbox" (change)="updateDefaults($event, 'centerImage')" [disabled]="!newPageForm.controls.centerImage.value" [checked]="userDefaults.centerImage" />
                </div>
              </div>
              <div *ngIf="loadingImage.centerImage" class="spinner-border spinner-border-sm"></div>
              <input *ngIf="!newPageForm.controls.centerImage.value && !loadingImage.centerImage" (change)="uploadMedia($event.target.files, newPageForm, 'centerImage')" type="file" />
              <p *ngIf="!newPageForm.controls.centerImage.value && !loadingImage.centerImage" class="media-manager-helper-text">or</p>
              <button *ngIf="!newPageForm.controls.centerImage.value && !loadingImage.centerImage" (click)="openMediaModal($event, newPageForm, 'centerImage')" class="button button-white"><i class="fal fa-image"></i> Select From Media Manager</button>
              <div *ngIf="submitted && newPageForm.controls.centerImage.errors" class="invalid-feedback">
                <div *ngIf="newPageForm.controls.centerImage.errors.required">Main Image is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="centerLogo">Logo</label>
              <div class="row">
                <div class="col-sm-4">
                  <img *ngIf="newPageForm.controls.centerLogo.value && !loadingImage.centerLogo" class="preview-image" [src]="newPageForm.controls.centerLogo.value" />
                  <p *ngIf="newPageForm.controls.centerLogo.value && !loadingImage.centerLogo" (click)="resetImage('centerLogo', newPageForm)" class="remove-default"><i class="fal fa-trash-alt"></i> Remove Image</p>
                </div>
                <div class="col-sm-4">
                </div>
                <div class="col-sm-4 default-actions">
                  <span class="default-label">Save as default</span>
                  <input type="checkbox" (change)="updateDefaults($event, 'centerLogo')" [disabled]="!newPageForm.controls.centerLogo.value" [checked]="userDefaults.centerLogo" />
                </div>
              </div>
              <div *ngIf="loadingImage.centerLogo" class="spinner-border spinner-border-sm"></div>
              <input *ngIf="!newPageForm.controls.centerLogo.value && !loadingImage.centerLogo" (change)="uploadMedia($event.target.files, newPageForm, 'centerLogo')" type="file" />
              <p *ngIf="!newPageForm.controls.centerLogo.value && !loadingImage.centerLogo" class="media-manager-helper-text">or</p>
              <button *ngIf="!newPageForm.controls.centerLogo.value && !loadingImage.centerLogo" (click)="openMediaModal($event, newPageForm, 'centerLogo')" class="button button-white"><i class="fal fa-image"></i> Select From Media Manager</button>
              <div *ngIf="submitted && newPageForm.controls.centerLogo.errors" class="invalid-feedback">
                <div *ngIf="newPageForm.controls.centerLogo.errors.required">Logo is required</div>
              </div>
            </div>
            <!-- Digital Business Card Fields -->
            <div class="form-group" *ngIf="pageType === 'Digital Business Card' || pageType === 'Real Estate General Communication'">
              <label for="headShot">Head Shot</label>
              <div class="row">
                <div class="col-sm-4">
                  <img *ngIf="newPageForm.controls.headShot.value && !loadingImage.headShot" class="preview-image" [src]="newPageForm.controls.headShot.value" />
                  <p *ngIf="newPageForm.controls.headShot.value && !loadingImage.headShot" (click)="resetImage('headShot', newPageForm)" class="remove-default"><i class="fal fa-trash-alt"></i> Remove Image</p>
                </div>
                <div class="col-sm-4">
                </div>
                <div class="col-sm-4 default-actions">
                  <span class="default-label">Save as default</span>
                  <input type="checkbox" (change)="updateDefaults($event, 'headShot')" [disabled]="!newPageForm.controls.headShot.value" [checked]="userDefaults.headShot" />
                </div>
              </div>
              <div *ngIf="loadingImage.headShot" class="spinner-border spinner-border-sm"></div>
              <input *ngIf="!newPageForm.controls.headShot.value && !loadingImage.headShot" (change)="uploadMedia($event.target.files, newPageForm, 'headShot')" type="file" />
              <p *ngIf="!newPageForm.controls.headShot.value && !loadingImage.headShot" class="media-manager-helper-text">or</p>
              <button *ngIf="!newPageForm.controls.headShot.value && !loadingImage.headShot" (click)="openMediaModal($event, newPageForm, 'headShot')" class="button button-white"><i class="fal fa-image"></i> Select From Media Manager</button>
              <div *ngIf="submitted && newPageForm.controls.headShot.errors" class="invalid-feedback">
                <div *ngIf="newPageForm.controls.headShot.errors.required">Logo is required</div>
              </div>
            </div>
            <!-- END Digital Business Card Fields -->
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Call To Action Link</label>
                <input (keyup)="changeDetection($event, 'callToActionLink')" placeholder="e.g. https://..." type="text" formControlName="callToActionLink" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.callToActionLink.errors }" />
                <span *ngIf="defaultUpdated.callToActionLink" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.callToActionLink" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.callToActionLink" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.callToActionLink.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.callToActionLink.errors.required">Call To Action Link is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'callToActionLink')" [disabled]="!newPageForm.controls.callToActionLink.value" [checked]="userDefaults.callToActionLink" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Call To Action Text</label>
                <input (keyup)="changeDetection($event, 'callToActionText')" placeholder="e.g. Up To 2 Weeks Free" type="text" formControlName="callToActionText" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.callToActionText.errors }" />
                <span *ngIf="defaultUpdated.callToActionText" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.callToActionText" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.callToActionText" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.callToActionText.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.callToActionText.errors.required">Call To Action Text is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'callToActionText')" [disabled]="!newPageForm.controls.callToActionText.value" [checked]="userDefaults.callToActionText" />
              </div>
            </div>
            <!-- Digital Business Card Fields -->
            <div class="row form-group" *ngIf="pageType === 'Digital Business Card' || pageType === 'Real Estate General Communication'">
              <div class="col-sm-8">
                <label for="facebookUrl">Facebook URL - <em>optional</em></label>
                <input (keyup)="changeDetection($event, 'facebookUrl')" placeholder="e.g. https://..." type="text" formControlName="facebookUrl" class="form-control" />
                <span *ngIf="defaultUpdated.facebookUrl" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.facebookUrl" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.facebookUrl" class="defaultAdded">Default Added!</span>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'facebookUrl')" [disabled]="!newPageForm.controls.facebookUrl.value" [checked]="userDefaults.facebookUrl" />
              </div>
            </div>
            <div class="row form-group" *ngIf="pageType === 'Digital Business Card' || pageType === 'Real Estate General Communication'">
              <div class="col-sm-8">
                <label for="twitterUrl">Twitter URL - <em>optional</em></label>
                <input (keyup)="changeDetection($event, 'twitterUrl')" placeholder="e.g. https://..." type="text" formControlName="twitterUrl" class="form-control" />
                <span *ngIf="defaultUpdated.twitterUrl" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.twitterUrl" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.twitterUrl" class="defaultAdded">Default Added!</span>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'twitterUrl')" [disabled]="!newPageForm.controls.twitterUrl.value" [checked]="userDefaults.twitterUrl" />
              </div>
            </div>
            <div class="row form-group" *ngIf="pageType === 'Digital Business Card' || pageType === 'Real Estate General Communication'">
              <div class="col-sm-8">
                <label for="instagramUrl">Instagram URL - <em>optional</em></label>
                <input (keyup)="changeDetection($event, 'instagramUrl')" placeholder="e.g. https://..." type="text" formControlName="instagramUrl" class="form-control" />
                <span *ngIf="defaultUpdated.instagramUrl" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.instagramUrl" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.instagramUrl" class="defaultAdded">Default Added!</span>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'instagramUrl')" [disabled]="!newPageForm.controls.instagramUrl.value" [checked]="userDefaults.instagramUrl" />
              </div>
            </div>
            <div class="row form-group" *ngIf="pageType === 'Digital Business Card' || pageType === 'Real Estate General Communication'">
              <div class="col-sm-8">
                <label for="youtubeUrl">YouTube URL - <em>optional</em></label>
                <input (keyup)="changeDetection($event, 'youtubeUrl')" placeholder="e.g. https://..." type="text" formControlName="youtubeUrl" class="form-control" />
                <span *ngIf="defaultUpdated.youtubeUrl" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.youtubeUrl" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.youtubeUrl" class="defaultAdded">Default Added!</span>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'youtubeUrl')" [disabled]="!newPageForm.controls.youtubeUrl.value" [checked]="userDefaults.youtubeUrl" />
              </div>
            </div>
            <div class="row form-group" *ngIf="pageType === 'Digital Business Card' || pageType === 'Real Estate General Communication'">
              <div class="col-sm-8">
                <label for="scheduleMeetingUrl">Schedule Meeting URL - <em>optional</em></label>
                <input (keyup)="changeDetection($event, 'scheduleMeetingUrl')" placeholder="e.g. https://..." type="text" formControlName="scheduleMeetingUrl" class="form-control" />
                <span *ngIf="defaultUpdated.scheduleMeetingUrl" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.scheduleMeetingUrl" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.scheduleMeetingUrl" class="defaultAdded">Default Added!</span>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'scheduleMeetingUrl')" [disabled]="!newPageForm.controls.scheduleMeetingUrl.value" [checked]="userDefaults.scheduleMeetingUrl" />
              </div>
            </div>
            <!-- END Digital Business Card Fields -->
            <!-- Master Nurture Template Fields-->
            <div class="row form-group" *ngIf="pageType === 'Master Nurture Template' || pageType === 'Real Estate General Communication'">
              <div class="col-sm-8">
                <label for="pageName">Main Message</label>
                <input (keyup)="changeDetection($event, 'mainText')" placeholder="e.g. Thanks for stopping by..." type="text" formControlName="mainText" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.mainText.errors }" />
                <span *ngIf="defaultUpdated.mainText" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.mainText" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.mainText" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.mainText.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.mainText.errors.required">Main Message is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'mainText')" [disabled]="!newPageForm.controls.mainText.value" [checked]="userDefaults.mainText" />
              </div>
            </div>
            <label *ngIf="pageType === 'Master Nurture Template' || pageType === 'Real Estate General Communication'">Available Tokens:</label>
            <div *ngIf="pageType === 'Master Nurture Template' || pageType === 'Real Estate General Communication'" class="token-wrap">
                <span *ngFor="let key of objectKeys(newPageForm.getRawValue())" class="single-token">[{{key}}], </span>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="your-contact-info" [disabled]="uploadSelection">
					<ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('yourContactInfo')">
						<span>Your Contact Info</span>
						<i class="fa fa-chevron-down pull-right"></i>
						<div *ngIf="checkSectionValidity('yourContactInfo')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Your Phone Number</label>
                <input (keyup)="changeDetection($event, 'yourPhoneNumber')" mask="(000) 000-0000" placeholder="e.g. 6025551234" type="text" formControlName="yourPhoneNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.yourPhoneNumber.errors }" />
                <span *ngIf="defaultUpdated.yourPhoneNumber" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.yourPhoneNumber" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.yourPhoneNumber" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.yourPhoneNumber.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.yourPhoneNumber.errors.required">Your Phone Number is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'yourPhoneNumber')" [disabled]="!newPageForm.controls.yourPhoneNumber.value" [checked]="userDefaults.yourPhoneNumber" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Your Name</label>
                <input (keyup)="changeDetection($event, 'yourName')" placeholder="e.g. Jane Doe" type="text" formControlName="yourName" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.yourName.errors }" />
                <span *ngIf="defaultUpdated.yourName" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.yourName" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.yourName" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.yourName.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.yourName.errors.required">Your Name is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'yourName')" [disabled]="!newPageForm.controls.yourName.value" [checked]="userDefaults.yourName" />
              </div>
            </div>
            <!-- Real Estate Fields -->
            <div class="row form-group" *ngIf="pageType === 'Digital Business Card' || pageType === 'Real Estate General Communication'">
              <div class="col-sm-8">
                <label for="yourTitle">Your Title</label>
                <input (keyup)="changeDetection($event, 'yourTitle')" placeholder="e.g. Real Estate Professional" type="text" formControlName="yourTitle" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.yourTitle.errors }" />
                <span *ngIf="defaultUpdated.yourTitle" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.yourTitle" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.yourTitle" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.yourTitle.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.yourTitle.errors.required">Your Name is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'yourTitle')" [disabled]="!newPageForm.controls.yourTitle.value" [checked]="userDefaults.yourTitle" />
              </div>
            </div>
            <!-- END Real Estate Fields -->
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Your Email</label>
                <input (keyup)="changeDetection($event, 'yourEmailAddress')" placeholder="e.g. agent@me.com" type="text" formControlName="yourEmailAddress" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.yourEmailAddress.errors }" />
                <span *ngIf="defaultUpdated.yourEmailAddress" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.yourEmailAddress" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.yourEmailAddress" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.yourEmailAddress.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.yourEmailAddress.errors.required">Your Email is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'yourEmailAddress')" [disabled]="!newPageForm.controls.yourEmailAddress.value" [checked]="userDefaults.yourEmailAddress" />
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="other-info" [disabled]="uploadSelection">
					<ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('otherInfo')">
						<span>Other Info</span>
            <i class="fa fa-chevron-down pull-right"></i>
            <div *ngIf="checkSectionValidity('otherInfo')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Footer Content - <em>optional</em></label>
                <input (keyup)="changeDetection($event, 'footerContent')" placeholder="e.g Copyright 2020 | All Rights Reserved" type="text" formControlName="footerContent" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.footerContent.errors }" />
                <span *ngIf="defaultUpdated.footerContent" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.footerContent" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.footerContent" class="defaultAdded">Default Added!</span>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'footerContent')" [disabled]="!newPageForm.controls.footerContent.value" [checked]="userDefaults.footerContent" />
              </div>
            </div>
          </ng-template>
				</ngb-panel>
				<ngb-panel *ngIf="bulkUpload" id="custom-fields">
					<ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('prospectInfo')">
						<span>Prospect Info (Bulk Upload)</span>
						<i class="fa fa-chevron-down pull-right"></i>
						<div *ngIf="checkSectionValidity('prospectInfo')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="alert alert-danger" *ngIf="uploadDisabled">
							Please ensure all of your template fields are set. All sections should be green!
            </div>
            <div class="alert alert-danger" *ngIf="bulkUploadError">
              {{bulkUploadError}}
            </div>
						<ng-wizard *ngIf="!uploadDisabled" [config]="config">
							<ng-wizard-step [title]="'Choose File Type'">
								<div class="row margin-top-24" *ngIf="!uploadSelection">
                  <div class="upload-options-column col-md-4">
										<p>
											<strong>Option 1:</strong>
										</p>
										<p>
											<span>Download Template</span>
										</p>
										<div class="template-option-wrapper">
											<div class="nurture-boss-template">
												<i class="fal fa-file-csv"></i>
											</div>
											<p><strong>template.csv</strong></p>
											<button [disabled]="disableAll" class="button button-white" (click)="selectUploadMethod($event, 'nurtureBoss')">
												<i class="fal fa-cloud-download-alt"></i> Upload Template
                      </button>
                      <br />
                      <a [href]="'https://app.customnurturepages.com/api/docs/' + pageTypeToTemplate[pageType]" target="_blank">Download Template</a>
										</div>
										<!-- <div (click)="selectUploadOption('nurtureBoss')" class="no-margin pointer alert alert-secondary"> -->
										<!-- <img src="assets/NB-logo-small.png" /> -->
									</div>
                  <div class="upload-options-column col-md-4">
										<p>
											<strong>Option 2:</strong>
										</p>
										<p>
											<span>Use Your Own Spreadsheet</span>
										</p>
										<div class="template-option-wrapper">
											<div class="custom-template">
												<i class="fal fa-file-excel"></i>
											</div>
											<p><strong>Choose spreadsheet</strong></p>
											<button [disabled]="disableAll" class="button button-white" (click)="selectUploadMethod($event, 'custom')">
												<i class="fal fa-cloud-upload-alt"></i> Upload File
											</button>
										</div>
										<!-- <div (click)="selectUploadOption('custom')" class="no-margin pointer alert alert-secondary"> -->
										<!-- <img src="assets/Yardi-logo-small.png" /><img class="margin-left-12" src="assets/RP-logo-small.png" /> -->
                  </div>
                  <div class="upload-options-column col-md-4">
										<p>
											<strong>Option 3:</strong>
										</p>
										<p>
											<span>Use Existing Contacts</span>
										</p>
										<div class="template-option-wrapper">
											<div class="use-contacts">
												<i class="far fa-id-card"></i>
											</div>
											<p><strong>Use Existing Contacts</strong></p>
											<button [disabled]="disableAll" class="button button-white" (click)="getContacts($event)">
												<i class="far fa-table"></i> Continue
											</button>
										</div>
										<!-- <div (click)="selectUploadOption('custom')" class="no-margin pointer alert alert-secondary"> -->
										<!-- <img src="assets/Yardi-logo-small.png" /><img class="margin-left-12" src="assets/RP-logo-small.png" /> -->
                  </div>
								</div>
							</ng-wizard-step>
							<ng-wizard-step [title]="'Select Your Data'">
								<div class="margin-top-24 step-instruction-wrap" *ngIf="uploadSelection === 'nurtureBoss'">
                  <p class="step-instructions">Upload your spreadsheet to get started!</p>
                  <br />
									<label class="button button-white" for="data-upload"><i class="fal fa-file-search"></i> Select file</label>
									<input type="file" class="bulk-upload-input" id="data-upload" value="" accept="text/csv" />
									<span *ngIf="fileUploaded"><i (click)="refresh()" class="fal fa-trash-alt"></i> {{fileUploaded.name}}</span><button class="button button-dark pull-right" *ngIf="stepSuccess" (click)="nextStep($event)"> Continue</button>
									<button class="button button-dark pull-right" (click)="processUploadedFile($event)" *ngIf="fileUploaded">Continue</button>
								</div>
								<div class="margin-top-24" *ngIf="uploadSelection === 'custom' && !bulkUploadSuccess">
										<sheetjs 
												[activePageType]="pageType"
												[userDefaults]="newPageForm.getRawValue()"
                        [requiredFields]="activeTypeRequiredFields"
                        [optionalFields]="optionalFields"
												[createPageHandler]="completeSpreadsheetSelection"
										></sheetjs>
										<!-- TODO HANDLE BULK UPLOAD ERROR -->
                </div>
                <div class="margin-top-24 crm-page-selection-wrap" *ngIf="uploadSelection === 'contacts'">
                  <div class="overflow-auto step-instruction-wrap">
                    <p class="step-instructions pull-left">Select Prospects</p>
                    <button class="button button-dark pull-right" [disabled]="selectedRows.length === 0" (click)="continueToDataVerification($event)">Continue</button>
                  </div>
                  <div class="overflow-auto table-actions">
                    <p class="table-row-indicator"><strong>Showing {{pagination.first}} to {{pagination.last}} of {{totalRecords}} records</strong></p>
                  </div>
                  <div class="crm-prospect-table-wrap">
                    <p-table #dt [value]="contactDataToShow" [(selection)]="selectedRows" dataKey="_id" styleClass="ui-table-customers" [rowHover]="true"
                      [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,100,250,500,1000]" [loading]="loading"
                      [paginator]="true" [filterDelay]="0" [globalFilterFields]="['clientFullName','created','updated','label']" totalRecords="{{totalRecords}}"
                      currentPageReportTemplate="Showing {first} to {last} of {{totalRecords}} entries"
                      (onPage)="paginate($event)">
                      <ng-template pTemplate="header">
                          <tr>
                              <th></th>
                              <th pSortableColumn="name">Name <p-sortIcon field="clientFullName"></p-sortIcon></th>
                              <th pSortableColumn="status">Label <p-sortIcon field="label"></p-sortIcon></th>
                              <th pSortableColumn="created">Created Date <p-sortIcon field="created"></p-sortIcon></th>
                              <th pSortableColumn="updated">Updated Date <p-sortIcon field="updated"></p-sortIcon></th>
                          </tr>
                          <!-- Table Filters -->
                          <tr>
                              <th>
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                              </th>
                              <th>
                                <input pInputText type="text" (input)="dt.filter($event.target.value, 'clientFullName', 'contains')" placeholder="Search by Name" class="ui-column-filter">
                              </th>
                              <th>
                                <p-multiSelect [options]="labelFilterOptions" placeholder="All" (onChange)="onLabelFilterChange($event)" styleClass="ui-column-filter">
                                </p-multiSelect>
                              </th>
                              <th>
                                <p-calendar selectionMode="range" (onSelect)="onCreatedDateSelect($event)" (onClearClick)="dt.filter('', 'created', 'equals')" [showButtonBar]="true" styleClass="ui-column-filter" placeholder="Created Date" [readonlyInput]="true" dateFormat="mm-dd-yy"></p-calendar>
                              </th>
                              <th>
                                <p-calendar selectionMode="range" (onSelect)="onUpdatedDateSelect($event)" (onClearClick)="dt.filter('', 'updated', 'equals')" [showButtonBar]="true" styleClass="ui-column-filter" placeholder="Updated Date" [readonlyInput]="true" dateFormat="mm-dd-yy"></p-calendar>
                              </th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-prospect let-rowData>
                        <tr class="ui-selectable-row">
                            <td>
                              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                            </td>
                            <td>
                              {{prospect.clientFullName}}
                            </td>
                            <td>
                              <span *ngFor="let label of prospect.labels" class="contact-label">{{label}}</span>
                            </td>
                            <td>
                              {{prospect.created | date}}
                            </td>
                            <td>
                              {{prospect.updated | date}}
                            </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                          <tr>
                            <td colspan="8" style="text-align:left">No contacts found.</td>
                          </tr>
                      </ng-template>
                    </p-table>
                  </div>
								</div>
							</ng-wizard-step>
							<ng-wizard-step [title]="'Verify Data'">
								<div *ngIf="dataForUpload.length > 0">
									<div class="step-instruction-wrap">
										<p class="step-instructions pull-left">
											<strong>Data Preview:</strong>
											Take a look at this sample row, does the data seem like it's in the right spot?
										</p>
										<button class="button button-dark pull-right" (click)="goToNextStep($event)">Continue</button>
									</div>
									<table class="table table-bordered preview-table">
										<thead>
											<tr>
												<th scope="col">
													Full Name
												</th>
												<th scope="col">
													Prospect First Name
												</th>
												<th scope="col">
													Prospect Phone Number
												</th>
												<th scope="col">
													Page Type
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													{{sampleData.pageName}}
												</td>
												<td>
													{{sampleData.clientFirstName}}
												</td>
												<td>
													{{sampleData.clientPhoneNumber}}
												</td>
												<td>
													{{sampleData.type}}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</ng-wizard-step>
							<ng-wizard-step [title]="'Create Pages'">
								<div class="step-instruction-wrap">
									<p class="step-instructions pull-left">
                    Great! Here are some details about the pages you are creating!
									</p>
									<button class="button button-dark pull-right" [disabled]="isUploading" (click)="createPages($event)">Create Pages</button>
								</div>
								<ul class="pages-overview">
									<li>Template: <strong>{{pageType}}</strong></li>
                  <li>Number of Pages: <strong>{{dataForUpload.length}}</strong></li>
                  <li class="error-state" *ngIf="duplicatesRemoved > 0">Duplicates Removed: <strong>{{duplicatesRemoved}}</strong></li>
                  <li class="error-state" *ngIf="skippedPages > 0">Pages Skipped for Missing Data: <strong>{{skippedPages}}</strong></li>
								</ul>
								<div *ngIf="isUploading" class="upload-progress">
									<p><span class="spinner-border spinner-border-sm mr-1"></span> Hang tight! We are creating your pages. You will be redirected when complete!</p>
								</div>
							</ng-wizard-step>
          </ng-wizard>
          </ng-template>
        </ngb-panel>
				<ngb-panel *ngIf="!bulkUpload" id="custom-fields" [disabled]="uploadSelection">
					<ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('prospectInfo')">
						<span>Prospect Info</span>
						<i class="fa fa-chevron-down pull-right"></i>
						<div *ngIf="checkSectionValidity('prospectInfo')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Prospect First Name</label>
                <input (keyup)="changeDetection($event, 'clientFirstName')" placeholder="e.g. John" type="text" formControlName="clientFirstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.clientFirstName.errors }" />
                <span *ngIf="defaultUpdated.clientFirstName" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.clientFirstName" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.clientFirstName" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.clientFirstName.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.clientFirstName.errors.required">Prospect First Name is required</div>
                </div>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Prospect Phone Number</label>
                <input (keyup)="changeDetection($event, 'clientPhoneNumber')" mask="(000) 000-0000" placeholder="e.g. 6025551234" type="text" formControlName="clientPhoneNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.clientPhoneNumber.errors }" />
                <span *ngIf="defaultUpdated.clientPhoneNumber" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.clientPhoneNumber" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.clientPhoneNumber" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.clientPhoneNumber.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.clientPhoneNumber.errors.required">Prospect Phone Number is required</div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <div class="form-actions">
        <button *ngIf="!bulkUpload" [disabled]="disableAll || createPageLoading || !newPageForm.valid" type="submit" class="button button-dark margin-right-12">
        <span *ngIf="createPageLoading" class="spinner-border spinner-border-sm mr-1"></span>
        Create Page
        </button>
        <!-- <button *ngIf="!bulkUpload" [disabled]="disableAll" (click)="exitCreateNew()" class="button button-light margin-right-12">
        Save Defaults
        </button> -->
				<button type="button" class="button button-white margin-right-12" (click)="exitCreateNew()">Cancel</button>
        <button *ngIf="bulkUpload" type="button" class="button button-red" (click)="refresh()">Start Over</button>
        <p class="contact-create-warning">By sending a page contacts will be created or updated. Contacts created this way have their "opt-in" property set to TRUE. Please make sure this is your intention.</p>
        <div *ngIf="createPageError && createPageError.length > 0" class="alert alert-danger mt-3 mb-0">{{createPageError}}</div>
      </div>
      </form>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-body page-creating-warning-modal">
      <p>Some of your pages were created, but some were not!</p>
      <div class="alert alert-danger">
        {{pageCreateError}}
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="button button-dark" (click)="modal.close()">OK</button>
    </div>
  </ng-template>
</div>