<div id="new-page">
  <div class="page-title">
    <!-- <div class="actions left">
      <i class="far fa-bars"></i>
      </div> -->
    <h2>Templates / {{pageType}}</h2>
    <p class="remaing-pages-info"><strong>Remaing Pages Available: {{pagesRemaining}}</strong></p>
    <!-- <div class="actions right">
      <i class="far fa-search"></i>
      </div> -->
  </div>
  <div class="card mt-4">
    <div class="card-body new-pages">
      <form [formGroup]="newPageForm" (ngSubmit)="savePage(newPageForm)" *ngIf="!loading">
      <ngb-accordion [closeOthers]="true" activeIds="custom-fields">
        <ngb-panel id="organizational-settings" [disabled]="uploadSelection">
          <ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('organizationalSettings')">
						<span>Prospect Full Name</span>
						<i class="fa fa-chevron-down pull-right"></i>
						<div *ngIf="checkSectionValidity('organizationalSettings')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <!-- <div class="row form-group">
              <label>Campaign Label - <em>optional</em></label>
              <div class="ng-autocomplete">
                <ng-autocomplete
                #auto
                [data]="labelData"
                [searchKeyword]="'name'"
                (selected)='setLabel($event)'
                (inputChanged)="updateCurrentLabel($event)"
                [itemTemplate]="itemTemplate"
                [(ngModel)]="currentLabelSearch"
                [ngModelOptions]="{standalone: true}"
                [placeHolder]="'Optional campaign name'"
                (inputCleared)="clearLabelNew()"
                (closed)="clearLabelNew()">
                </ng-autocomplete>
                <ng-template #itemTemplate let-item>
                  <a [innerHTML]="item.name"></a>
                </ng-template>
              </div>
            </div> -->
            <div class="row form-group" *ngIf="!bulkUpload">
              <label for="pageName">Full Name</label>
              <input placeholder="We recommend using prospects name or email" type="text" formControlName="pageName" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.pageName.errors }" />
              <div *ngIf="submitted && newPageForm.controls.pageName.errors" class="invalid-feedback">
                <div *ngIf="newPageForm.controls.pageName.errors.required">Full Name is required</div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="property-info" [disabled]="uploadSelection">
					<ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('propertyInfo')">
						<span>Property Info</span>
						<i class="fa fa-chevron-down pull-right"></i>
						<div *ngIf="checkSectionValidity('propertyInfo')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="form-group">
              <label for="bannerImage">Banner Image</label>
              <div class="row">
                <div class="col-sm-4">
                  <img class="preview-image" *ngIf="newPageForm.controls.bannerImage.value && !loadingImage.bannerImage" [src]="newPageForm.controls.bannerImage.value" />
                  <p *ngIf="newPageForm.controls.bannerImage.value && !loadingImage.bannerImage" (click)="resetImage('bannerImage', newPageForm)" class="remove-default"><i class="fal fa-trash-alt"></i> Remove Image</p>
                </div>
                <div class="col-sm-4">
                </div>
                <div class="col-sm-4 default-actions">
                  <span class="default-label">Save as default</span>
                  <input type="checkbox" (change)="updateDefaults($event, 'bannerImage')" [disabled]="!newPageForm.controls.bannerImage.value" [checked]="userDefaults.bannerImage" />
                </div>
              </div>
              <div *ngIf="loadingImage.bannerImage" class="spinner-border spinner-border-sm"></div>
              <input *ngIf="!newPageForm.controls.bannerImage.value && !loadingImage.bannerImage" (change)="uploadMedia($event.target.files, newPageForm, 'bannerImage')" type="file" />
              <p *ngIf="!newPageForm.controls.bannerImage.value && !loadingImage.bannerImage" class="media-manager-helper-text">or</p>
              <button *ngIf="!newPageForm.controls.bannerImage.value && !loadingImage.bannerImage" (click)="openMediaModal($event, newPageForm, 'bannerImage')" class="button button-white"><i class="fal fa-image"></i> Select From Media Manager</button>
              <div *ngIf="submitted && newPageForm.controls.bannerImage.errors" class="invalid-feedback">
                <div *ngIf="newPageForm.controls.bannerImage.errors.required">Banner Image is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="leftLogo">Logo</label>
              <div class="row">
                <div class="col-sm-4">
                  <img *ngIf="newPageForm.controls.leftLogo.value && !loadingImage.leftLogo" class="preview-image" [src]="newPageForm.controls.leftLogo.value" />
                  <p *ngIf="newPageForm.controls.leftLogo.value && !loadingImage.leftLogo" (click)="resetImage('leftLogo', newPageForm)" class="remove-default"><i class="fal fa-trash-alt"></i> Remove Image</p>
                </div>
                <div class="col-sm-4">
                </div>
                <div class="col-sm-4 default-actions">
                  <span class="default-label">Save as default</span>
                  <input type="checkbox" (change)="updateDefaults($event, 'leftLogo')" [disabled]="!newPageForm.controls.leftLogo.value" [checked]="userDefaults.leftLogo" />
                </div>
              </div>
              <div *ngIf="loadingImage.leftLogo" class="spinner-border spinner-border-sm"></div>
              <input *ngIf="!newPageForm.controls.leftLogo.value && !loadingImage.leftLogo" (change)="uploadMedia($event.target.files, newPageForm, 'leftLogo')" type="file" />
              <p *ngIf="!newPageForm.controls.leftLogo.value && !loadingImage.leftLogo" class="media-manager-helper-text">or</p>
              <button *ngIf="!newPageForm.controls.leftLogo.value && !loadingImage.leftLogo" (click)="openMediaModal($event, newPageForm, 'leftLogo')" class="button button-white"><i class="fal fa-image"></i> Select From Media Manager</button>
              <div *ngIf="submitted && newPageForm.controls.leftLogo.errors" class="invalid-feedback">
                <div *ngIf="newPageForm.controls.leftLogo.errors.required">Logo is required</div>
              </div>
            </div>
            <div class="row form-group">
              <label for="pageName">Property Name</label>
              <input type="text" formControlName="apartmentName" class="form-control" readonly />
            </div>
            <div class="row form-group" *ngIf="pageType === 'Apartment Concession'">
              <div class="col-sm-8">
                <label for="pageName">Concession Offering</label>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon3">Lease today and receive</span>
                  </div>
                  <input (keyup)="changeDetection($event, 'concession')" placeholder="e.g. Up To 2 Weeks Free" type="text" formControlName="concession" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.concession.errors }" />
                  <div class="input-group-append">
                    <span class="input-group-text">!</span>
                  </div>
                </div>
                <span *ngIf="defaultUpdated.concession" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.concession" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.concession" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.concession.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.concession.errors.required">Concession Offering is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'concession')" [disabled]="!newPageForm.controls.concession.value" [checked]="userDefaults.concession" />
              </div>
            </div>
            <div class="row form-group" *ngIf="pageType === 'Rent Reminder' || pageType === 'Paseo Renewal' || pageType === 'Revere Rent Reminder'">
              <div class="col-sm-8">
                <label for="pageName">Resident Portal URL</label>
                <input (keyup)="changeDetection($event, 'residentPortalUrl')" placeholder="e.g. https://..." type="text" formControlName="residentPortalUrl" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.residentPortalUrl.errors }" />
                <span *ngIf="defaultUpdated.residentPortalUrl" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.residentPortalUrl" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.residentPortalUrl" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.residentPortalUrl.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.residentPortalUrl.errors.required">Resident Portal URL is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'residentPortalUrl')" [disabled]="!newPageForm.controls.residentPortalUrl.value" [checked]="userDefaults.residentPortalUrl" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Property Website</label>
                <input (keyup)="changeDetection($event, 'yourWebsite')" placeholder="e.g. https://..." type="text" formControlName="yourWebsite" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.yourWebsite.errors }" />
                <span *ngIf="defaultUpdated.yourWebsite" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.yourWebsite" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.yourWebsite" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.yourWebsite.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.yourWebsite.errors.required">Property Website URL is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'yourWebsite')" [disabled]="!newPageForm.controls.yourWebsite.value" [checked]="userDefaults.yourWebsite" />
              </div>
            </div>
            <div class="row form-group" *ngIf="
              pageType !== 'Apartment Renewal' &&
              pageType !== 'Apartment Maintenance Followup' &&
              pageType !== 'Move In Checklist' &&
              pageType !== 'Apartment Event Reminder' &&
              pageType !== 'The Royce Rent Reminder' &&
              pageType !== 'Bella Posta Remodel Relocation' &&
              pageType !== 'Rent Reminder' &&
              pageType !== 'Revere Rent Reminder' &&
              pageType !== 'Apartment Appointment Reminder' &&
              pageType !== 'Paseo Renewal' &&
              pageType !== 'Apartment Covid Screening' &&
              pageType !== 'Harper Self Guided Tour' &&
              pageType !== 'Harper Move In Checklist'">
              <div class="col-sm-8">
                <label for="pageName">Online Application<span *ngIf="pageType === 'Apartment Interest List'"> - <em>optional</em></span></label>
                <input (keyup)="changeDetection($event, 'onlineApplicationUrl')" placeholder="e.g. https://..." type="text" formControlName="onlineApplicationUrl" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.onlineApplicationUrl.errors }" />
                <span *ngIf="defaultUpdated.onlineApplicationUrl" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.onlineApplicationUrl" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.onlineApplicationUrl" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.onlineApplicationUrl.errors && pageType !== 'Apartment Interest List'" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.onlineApplicationUrl.errors.required">Online Application URL is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'onlineApplicationUrl')" [disabled]="!newPageForm.controls.onlineApplicationUrl.value" [checked]="userDefaults.onlineApplicationUrl" />
              </div>
            </div>
            <div class="row form-group" *ngIf="pageType === 'Apartment Interest List'">
              <div class="col-sm-8">
                <label for="pageName">Main Message</label>
                <input (keyup)="changeDetection($event, 'mainText')" placeholder="e.g. Thanks for stopping by..." type="text" formControlName="mainText" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.mainText.errors }" />
                <span *ngIf="defaultUpdated.mainText" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.mainText" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.mainText" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.mainText.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.mainText.errors.required">Main Message is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'mainText')" [disabled]="!newPageForm.controls.mainText.value" [checked]="userDefaults.mainText" />
              </div>
            </div>
            <label *ngIf="pageType === 'Apartment Interest List'">Available Tokens for Main Message:</label>
            <div *ngIf="pageType === 'Apartment Interest List'" class="token-wrap">
                <span *ngFor="let key of objectKeys(newPageForm.getRawValue())"><span class="single-token" *ngIf="showToken(key)">[{{key}}], </span></span>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="property-contact-info" [disabled]="uploadSelection">
					<ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('propertyInfo')">
						<span>Property Contact Info</span>
						<i class="fa fa-chevron-down pull-right"></i>
						<div *ngIf="checkSectionValidity('propertyContactInfo')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Leasing Agent Name</label>
                <input (keyup)="changeDetection($event, 'yourName')" placeholder="e.g. Jane Doe" type="text" formControlName="yourName" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.yourName.errors }" />
                <span *ngIf="defaultUpdated.yourName" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.yourName" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.yourName" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.yourName.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.yourName.errors.required">Leasing Agent is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'yourName')" [disabled]="!newPageForm.controls.yourName.value" [checked]="userDefaults.yourName" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Property Email</label>
                <input (keyup)="changeDetection($event, 'yourEmailAddress')" placeholder="e.g. agent@me.com" type="text" formControlName="yourEmailAddress" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.yourEmailAddress.errors }" />
                <span *ngIf="defaultUpdated.yourEmailAddress" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.yourEmailAddress" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.yourEmailAddress" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.yourEmailAddress.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.yourEmailAddress.errors.required">Property Email is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'yourEmailAddress')" [disabled]="!newPageForm.controls.yourEmailAddress.value" [checked]="userDefaults.yourEmailAddress" />
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Property Phone Number</label>
                <input (keyup)="changeDetection($event, 'yourPhoneNumber')" placeholder="e.g. 6025551234" type="text" formControlName="yourPhoneNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.yourPhoneNumber.errors }" />
                <span *ngIf="defaultUpdated.yourPhoneNumber" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.yourPhoneNumber" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.yourPhoneNumber" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.yourPhoneNumber.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.yourPhoneNumber.errors.required">Property Phone Number is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'yourPhoneNumber')" [disabled]="!newPageForm.controls.yourPhoneNumber.value" [checked]="userDefaults.yourPhoneNumber" />
              </div>
            </div>
          </ng-template>
        </ngb-panel>
        <ngb-panel id="other-info" [disabled]="uploadSelection">
					<ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('otherInfo')">
						<span>Other Info</span>
            <i class="fa fa-chevron-down pull-right"></i>
            <div *ngIf="checkSectionValidity('otherInfo')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row form-group" *ngIf="pageType === 'Apartment Event Reminder'">
              <div class="col-sm-8">
                <label for="eventName">Event Name</label>
                <input (keyup)="changeDetection($event, 'eventName')" placeholder="e.g. Taco Tuesday" type="text" formControlName="eventName" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.eventName.errors }" />
                <span *ngIf="defaultUpdated.eventName" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.eventName" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.eventName" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.eventName.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.eventName.errors.required">Event Name is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'eventName')" [disabled]="!newPageForm.controls.eventName.value" [checked]="userDefaults.eventName" />
              </div>
            </div>
            <div class="row form-group" *ngIf="pageType === 'Apartment Event Reminder'">
              <div class="col-sm-8">
                <label for="eventDate">Event Date</label>
                <input (keyup)="changeDetection($event, 'eventDate')" placeholder="e.g. 08/01/2022" type="text" formControlName="eventDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.eventDate.errors }" />
                <span *ngIf="defaultUpdated.eventDate" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.eventDate" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.eventDate" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.eventDate.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.eventDate.errors.required">Event Date is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'eventDate')" [disabled]="!newPageForm.controls.eventDate.value" [checked]="userDefaults.eventDate" />
              </div>
            </div>
            <div class="row form-group" *ngIf="pageType === 'Apartment Event Reminder'">
              <div class="col-sm-8">
                <label for="eventTime">Event Time</label>
                <input (keyup)="changeDetection($event, 'eventTime')" placeholder="e.g. 6-8PM" type="text" formControlName="eventTime" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.eventTime.errors }" />
                <span *ngIf="defaultUpdated.eventTime" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.eventTime" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.eventTime" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.eventTime.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.eventTime.errors.required">Event Time is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'eventTime')" [disabled]="!newPageForm.controls.eventTime.value" [checked]="userDefaults.eventTime" />
              </div>
            </div>
            <div class="row form-group" *ngIf="pageType === 'Apartment Event Reminder'">
              <div class="col-sm-8">
                <label for="eventLocation">Event Location</label>
                <input (keyup)="changeDetection($event, 'eventLocation')" placeholder="e.g. Outside the Leasing Office" type="text" formControlName="eventLocation" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.eventLocation.errors }" />
                <span *ngIf="defaultUpdated.eventLocation" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.eventLocation" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.eventLocation" class="defaultAdded">Default Added!</span>
                <div *ngIf="submitted && newPageForm.controls.eventLocation.errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls.eventLocation.errors.required">Event Location is required</div>
                </div>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'eventLocation')" [disabled]="!newPageForm.controls.eventLocation.value" [checked]="userDefaults.eventLocation" />
              </div>
            </div>
            <div class="row form-group" *ngIf="pageType === 'Apartment Event Reminder'">
              <div class="col-sm-8">
                <label for="eventText">Event Description - <em>optional</em></label>
                <input (keyup)="changeDetection($event, 'eventText')" placeholder="e.g. Outside the Leasing Office" type="text" formControlName="eventText" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.eventText.errors }" />
                <span *ngIf="defaultUpdated.eventText" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.eventText" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.eventText" class="defaultAdded">Default Added!</span>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'eventText')" [disabled]="!newPageForm.controls.eventText.value" [checked]="userDefaults.eventText" />
              </div>
            </div>
            <div class="form-group" *ngIf="pageType === 'Apartment Event Reminder'">
              <label for="eventImage">Event Image - <em>optional</em></label>
              <div class="row">
                <div class="col-sm-4">
                  <img class="preview-image" *ngIf="newPageForm.controls.eventImage.value && !loadingImage.eventImage" [src]="newPageForm.controls.eventImage.value" />
                  <p *ngIf="newPageForm.controls.eventImage.value && !loadingImage.eventImage" (click)="resetImage('eventImage', newPageForm)" class="remove-default"><i class="fal fa-trash-alt"></i> Remove Image</p>
                </div>
                <div class="col-sm-4">
                </div>
                <div class="col-sm-4 default-actions">
                  <span class="default-label">Save as default</span>
                  <input type="checkbox" (change)="updateDefaults($event, 'eventImage')" [disabled]="!newPageForm.controls.eventImage.value" [checked]="userDefaults.eventImage" />
                </div>
              </div>
              <div *ngIf="loadingImage.eventImage" class="spinner-border spinner-border-sm"></div>
              <input *ngIf="!newPageForm.controls.eventImage.value && !loadingImage.eventImage" (change)="uploadMedia($event.target.files, newPageForm, 'eventImage')" type="file" />
              <p *ngIf="!newPageForm.controls.eventImage.value && !loadingImage.eventImage" class="media-manager-helper-text">or</p>
              <button *ngIf="!newPageForm.controls.eventImage.value && !loadingImage.eventImage" (click)="openMediaModal($event, newPageForm, 'eventImage')" class="button button-white"><i class="fal fa-image"></i> Select From Media Manager</button>
            </div>

            <div class="row form-group">
              <div class="col-sm-8">
                <label for="pageName">Footer Content - <em>optional</em></label>
                <input (keyup)="changeDetection($event, 'footerContent')" placeholder="e.g Copyright 2020 | All Rights Reserved" type="text" formControlName="footerContent" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls.footerContent.errors }" />
                <span *ngIf="defaultUpdated.footerContent" class="defaultUpdated">Default Updated!</span>
                <span *ngIf="defaultRemoved.footerContent" class="defaultRemoved">Default Removed!</span>
                <span *ngIf="defaultAdded.footerContent" class="defaultAdded">Default Added!</span>
              </div>
              <div class="col-sm-4 default-actions">
                <span class="default-label">Save as default</span>
                <input type="checkbox" (change)="updateDefaults($event, 'footerContent')" [disabled]="!newPageForm.controls.footerContent.value" [checked]="userDefaults.footerContent" />
              </div>
            </div>
          </ng-template>
				</ngb-panel>
				<ngb-panel *ngIf="bulkUpload" id="custom-fields">
					<ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('prospectInfo')">
						<span>Prospect Info (Bulk Upload)</span>
						<i class="fa fa-chevron-down pull-right"></i>
						<div *ngIf="checkSectionValidity('prospectInfo')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="alert alert-danger" *ngIf="uploadDisabled">
							Please ensure all of your template fields are set. All sections should be green!
            </div>
            <div class="alert alert-danger" *ngIf="bulkUploadError">
              {{bulkUploadError}}
            </div>
						<ng-wizard *ngIf="!uploadDisabled" [config]="config">
							<ng-wizard-step [title]="'Choose File Type'">
								<div class="row margin-top-24" *ngIf="!uploadSelection">
                  <div class="upload-options-column col-md-4" *ngIf="!realPageActive && !yardiActive && !ilmActive">
										<p>
											<strong>Option 1:</strong>
										</p>
										<p>
											<span>Download Template</span>
										</p>
										<div class="template-option-wrapper">
											<div class="nurture-boss-template">
												<i class="fal fa-file-csv"></i>
											</div>
											<p><strong>nurtureboss_template.csv</strong></p>
											<button [disabled]="disableAll" class="button button-white" (click)="selectUploadMethod($event, 'nurtureBoss')">
												<i class="fal fa-cloud-download-alt"></i> Upload Template
                      </button>
                      <br />
                      <a [href]="'https://app.customnurturepages.com/api/docs/' + pageTypeToTemplate[pageType]" target="_blank">Download Template</a>
										</div>
										<!-- <div (click)="selectUploadOption('nurtureBoss')" class="no-margin pointer alert alert-secondary"> -->
										<!-- <img src="assets/NB-logo-small.png" /> -->
									</div>
                  <div class="upload-options-column col-md-4" *ngIf="(!realPageActive && !yardiActive && !ilmActive) || isAdmin">
										<p>
											<strong>Option 2:</strong>
										</p>
										<p>
											<span>Use Your Own Spreadsheet</span>
										</p>
										<div class="template-option-wrapper">
											<div class="custom-template">
												<i class="fal fa-file-excel"></i>
											</div>
											<p><strong>Use your own spreadsheet from Yardi or RealPage</strong></p>
											<button [disabled]="disableAll" class="button button-white" (click)="selectUploadMethod($event, 'custom')">
												<i class="fal fa-cloud-upload-alt"></i> Upload File
											</button>
										</div>
										<!-- <div (click)="selectUploadOption('custom')" class="no-margin pointer alert alert-secondary"> -->
										<!-- <img src="assets/Yardi-logo-small.png" /><img class="margin-left-12" src="assets/RP-logo-small.png" /> -->
                  </div>
                  <div class="upload-options-column col-md-4" *ngIf="realPageActive">
										<p>
											<strong>Option 1:</strong>
										</p>
										<p>
											<span>Use Your Own Spreadsheet</span>
										</p>
										<div class="template-option-wrapper">
											<div class="custom-template">
												<i class="fal fa-file-excel"></i>
											</div>
											<p><strong>Use your own spreadsheet from Yardi or RealPage</strong></p>
											<button [disabled]="disableAll" class="button button-white" (click)="selectUploadMethod($event, 'custom')">
												<i class="fal fa-cloud-upload-alt"></i> Upload File
											</button>
										</div>
										<!-- <div (click)="selectUploadOption('custom')" class="no-margin pointer alert alert-secondary"> -->
										<!-- <img src="assets/Yardi-logo-small.png" /><img class="margin-left-12" src="assets/RP-logo-small.png" /> -->
                  </div>
                  <div class="upload-options-column col-md-4" *ngIf="ilmActive || (!realPageActive && !yardiActive && !ilmActive)">
										<p *ngIf="ilmActive">
											<strong>Option 1:</strong>
										</p>
                    <p *ngIf="(!realPageActive && !yardiActive && !ilmActive)">
											<strong>Option 3:</strong>
										</p>
										<p>
											<span>Use Existing Contacts</span>
										</p>
										<div class="template-option-wrapper">
											<div class="use-contacts">
												<i class="far fa-id-card"></i>
											</div>
											<p><strong>Use Existing Contacts</strong></p>
											<button [disabled]="disableAll" class="button button-white" (click)="getContacts($event)">
												<i class="far fa-table"></i> Continue
											</button>
										</div>
										<!-- <div (click)="selectUploadOption('custom')" class="no-margin pointer alert alert-secondary"> -->
										<!-- <img src="assets/Yardi-logo-small.png" /><img class="margin-left-12" src="assets/RP-logo-small.png" /> -->
                  </div>
                  <div class="col-md-4 upload-options-column" *ngIf="realPageActive">
										<p>
											<strong>Option 2:</strong>
										</p>
										<p>
											<span>RealPage Integration</span>
										</p>
										<div class="template-option-wrapper">
											<div class="realpage-integration">
												<img src="/assets/realpage-icon.png" />
											</div>
											<p><strong>Connect directly to RealPage to retrieve prospects</strong></p>
											<button [disabled]="disableAll" class="button button-white" (click)="getRealPageProspects($event)">
												<i class="fad fa-link"></i> Continue
											</button>
										</div>
										<!-- <div (click)="selectUploadOption('custom')" class="no-margin pointer alert alert-secondary"> -->
										<!-- <img src="assets/Yardi-logo-small.png" /><img class="margin-left-12" src="assets/RP-logo-small.png" /> -->
                  </div>
                  <div class="col-md-4 upload-options-column" *ngIf="yardiActive">
										<p>
											<strong>Option 1:</strong>
										</p>
										<p>
											<span>Yardi Integration</span>
										</p>
										<div class="template-option-wrapper">
											<div class="yardi-integration">
												<img src="/assets/yardi-icon.png" />
											</div>
											<p><strong>Connect directly to Yardi to retrieve prospects</strong></p>
											<button [disabled]="disableAll" class="button button-white" (click)="getYardiProspects($event)">
												<i class="fad fa-link"></i> Continue
											</button>
										</div>
										<!-- <div (click)="selectUploadOption('custom')" class="no-margin pointer alert alert-secondary"> -->
										<!-- <img src="assets/Yardi-logo-small.png" /><img class="margin-left-12" src="assets/RP-logo-small.png" /> -->
									</div>
								</div>
							</ng-wizard-step>
							<ng-wizard-step [title]="'Select Your Data'">
								<div class="margin-top-24 step-instruction-wrap" *ngIf="uploadSelection === 'nurtureBoss'">
                  <p class="step-instructions">Upload your spreadsheet to get started!</p>
                  <br />
									<label class="button button-white" for="data-upload"><i class="fal fa-file-search"></i> Select file</label>
									<input type="file" class="bulk-upload-input" id="data-upload" value="" accept="text/csv" />
									<span *ngIf="fileUploaded"><i (click)="refresh()" class="fal fa-trash-alt"></i> {{fileUploaded.name}}</span><button class="button button-dark pull-right" *ngIf="stepSuccess" (click)="nextStep($event)"> Continue</button>
									<button class="button button-dark pull-right" (click)="processUploadedFile($event)" *ngIf="fileUploaded">Continue</button>
								</div>
								<div class="margin-top-24" *ngIf="uploadSelection === 'custom' && !bulkUploadSuccess">
										<sheetjs 
												[activePageType]="pageType"
												[userDefaults]="newPageForm.getRawValue()"
                        [requiredFields]="activeTypeRequiredFields"
                        [optionalFields]="optionalFields"
												[createPageHandler]="completeSpreadsheetSelection"
										></sheetjs>
										<!-- TODO HANDLE BULK UPLOAD ERROR -->
                </div>
                <div class="margin-top-24 crm-page-selection-wrap" *ngIf="uploadSelection === 'realPage' || uploadSelection === 'yardi' || uploadSelection === 'contacts'">
                  <div class="overflow-auto step-instruction-wrap">
                    <p class="step-instructions pull-left">Select Prospects</p>
                    <button class="button button-dark pull-right" [disabled]="selectedRows.length === 0" (click)="continueToDataVerification($event)">Continue</button>
                  </div>
                  <div class="overflow-auto table-actions">
                    <p class="table-row-indicator"><strong>Showing {{pagination.first}} to {{pagination.last}} of {{totalRecords}} records</strong></p>
                  </div>
                  <div class="crm-prospect-table-wrap">
                    <p-table #dt [value]="pmsIntegrationDataToShow" [(selection)]="selectedRows" dataKey="_id" styleClass="ui-table-customers" [rowHover]="true"
                      [rows]="25" [showCurrentPageReport]="true" [rowsPerPageOptions]="[25,50,100,250,500,1000]" [loading]="loading"
                      [paginator]="true" [filterDelay]="0" [globalFilterFields]="['clientFullName','status','source','stage']" totalRecords="{{totalRecords}}"
                      currentPageReportTemplate="Showing {first} to {last} of {{totalRecords}} entries"
                      (onPage)="paginate($event)" [(selection)]="selectedProspects">
                      <ng-template pTemplate="header">
                          <tr>
                              <th></th>
                              <th pSortableColumn="name">Name <p-sortIcon field="clientFullName"></p-sortIcon></th>
                              <th *ngIf="(!ilmActive && !realPageActive && !yardiActive)" pSortableColumn="created">Created <p-sortIcon field="created"></p-sortIcon></th>
                              <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
                              <th pSortableColumn="recentActivityDate">Activity Date <p-sortIcon field="recentActivityDate"></p-sortIcon></th>
                              <th pSortableColumn="recentActivityType">Activity Type <p-sortIcon field="recentActivityType"></p-sortIcon></th>
                              <th pSortableColumn="recentActivityComments">Comments <p-sortIcon field="recentActivityComments"></p-sortIcon></th>
                              <th pSortableColumn="stage">Stage <p-sortIcon field="stage"></p-sortIcon></th>
                              <th pSortableColumn="source">Source <p-sortIcon field="source"></p-sortIcon></th>
                              <th pSortableColumn="source">SMS Opt In <p-sortIcon field="optIn"></p-sortIcon></th>
                          </tr>
                          <!-- Table Filters -->
                          <tr>
                              <th>
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                              </th>
                              <th>
                                <input pInputText type="text" (input)="dt.filter($event.target.value, 'clientFullName', 'contains')" placeholder="Search by Name" class="ui-column-filter">
                              </th>
                              <th *ngIf="(!ilmActive && !realPageActive && !yardiActive)">
                                <p-calendar selectionMode="range" (onSelect)="onCreateDateSelect($event)" (onClearClick)="dt.filter('', 'recentActivityDate', 'equals')" [showButtonBar]="true" styleClass="ui-column-filter" placeholder="Activity Date" [readonlyInput]="true" dateFormat="mm-dd-yy"></p-calendar>
                              </th>
                              <th>
                                <p-multiSelect [options]="statusFilterOptions" placeholder="All" (onChange)="onStatusFilterChange($event)" styleClass="ui-column-filter">
                                </p-multiSelect>
                              </th>
                              <th>
                                <p-calendar selectionMode="range" (onSelect)="onDateSelect($event)" (onClearClick)="dt.filter('', 'recentActivityDate', 'equals')" [showButtonBar]="true" styleClass="ui-column-filter" placeholder="Activity Date" [readonlyInput]="true" dateFormat="mm-dd-yy"></p-calendar>
                              </th>
                              <th>
                              </th>
                              <th>
                              </th>
                              <th>
                                <p-multiSelect [options]="stageFilterOptions" placeholder="All" (onChange)="onStageFilterChange($event)" styleClass="ui-column-filter">
                                </p-multiSelect>
                              </th>
                              <th>
                                <p-multiSelect [options]="sourceFilterOptions" placeholder="All" (onChange)="onSourceFilterChange($event)" styleClass="ui-column-filter">
                                </p-multiSelect>
                              </th>
                              <th>
                                <p-multiSelect [options]="optInFilterOptions" placeholder="All" (onChange)="onOptInFilterChange($event)" styleClass="ui-column-filter">
                                </p-multiSelect>
                              </th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-prospect let-rowData>
                        <tr class="ui-selectable-row">
                            <td>
                              <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                            </td>
                            <td>
                              {{prospect.clientFullName}}
                            </td>
                            <td *ngIf="(!ilmActive && !realPageActive && !yardiActive)">
                              {{prospect.created | date}}
                            </td>
                            <td>
                              <span [class]="'flag flag-us'"></span>
                              <span style="vertical-align: middle; margin-left: .5em">{{prospect.status}}</span>
                            </td>
                            <td>
                              {{prospect.recentActivityDate | date}}
                            </td>
                            <td>
                              <span [class]="'prospect-badge status-qualified'">{{prospect.recentActivityType}}</span>
                            </td>
                            <td>
                              <span ngbTooltip="{{prospect.recentActivityComments}}">Hover to see comments</span>
                            </td>
                            <td>
                              <span *ngIf="prospect.stage === 'Pre Tour'" [class]="'customer-badge status-tour'">{{prospect.stage}}</span>
                              <span *ngIf="prospect.stage === 'Pre Application'" [class]="'customer-badge status-application'">{{prospect.stage}}</span>
                              <span *ngIf="prospect.stage === 'Pre Move In'" [class]="'customer-badge status-lease'">{{prospect.stage}}</span>
                              <span *ngIf="prospect.stage === 'Leasing/Leased'" [class]="'customer-badge status-leased'">{{prospect.stage}}</span>
                            </td>
                            <td>
                              {{prospect.source}}
                            </td>
                            <td>
                              {{prospect.optIn}}
                            </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                          <tr>
                            <td colspan="8" style="text-align:left">No prospects found.</td>
                          </tr>
                      </ng-template>
                    </p-table>
                  </div>
								</div>
							</ng-wizard-step>
							<ng-wizard-step [title]="'Verify Data'">
								<div *ngIf="dataForUpload.length > 0">
									<div class="step-instruction-wrap">
										<p class="step-instructions pull-left">
											<strong>Data Preview:</strong>
											Take a look at this sample row, does the data seem like it's in the right spot?
										</p>
										<button class="button button-dark pull-right" (click)="goToNextStep($event, true, isEmailTemplate)">Continue</button>
									</div>
									<table class="table table-bordered preview-table">
										<thead>
											<tr>
												<th scope="col">
													Full Name
												</th>
												<th scope="col">
													Prospect First Name
												</th>
												<th scope="col">
                          <span *ngIf="!isEmailTemplate">Prospect Phone Number</span>
                          <span *ngIf="isEmailTemplate">Prospect Email Address</span>
												</th>
												<th scope="col">
													Page Type
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													{{sampleData.pageName}}
												</td>
												<td>
                          {{sampleData.clientFirstName}}
												</td>
												<td>
                          <span *ngIf="!isEmailTemplate">{{sampleData.clientPhoneNumber}}</span>
                          <span *ngIf="isEmailTemplate">{{sampleData.clientEmailAddress}}</span>
												</td>
												<td>
													{{sampleData.type}}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</ng-wizard-step>
							<ng-wizard-step [title]="'Create Pages'">
								<div class="step-instruction-wrap">
									<p class="step-instructions pull-left">
                    Great! Here are some details about the pages you are creating!
									</p>
									<button *ngIf="!isEmailTemplate" class="button button-dark pull-right" [disabled]="isUploading" (click)="createPages($event)">Create Pages</button>
                  <button *ngIf="isEmailTemplate" class="button button-dark pull-right" [disabled]="isUploading" (click)="createEmails($event)">Create Emails</button>
								</div>
								<ul class="pages-overview">
									<li>Template: <strong>{{pageType}}</strong></li>
                  <li>Number of Pages: <strong>{{dataForUpload.length}}</strong></li>
                  <li class="error-state" *ngIf="duplicatesRemoved > 0">Duplicates Removed: <strong>{{duplicatesRemoved}}</strong></li>
                  <li class="error-state" *ngIf="uploadSelection === 'custom'">Pages Skipped for Missing Data: <strong>{{skippedPages}}</strong></li>
								</ul>
								<div *ngIf="isUploading" class="upload-progress">
									<p><span class="spinner-border spinner-border-sm mr-1"></span> Hang tight! We are creating your pages. You will be redirected when complete!</p>
								</div>
							</ng-wizard-step>
          </ng-wizard>
          </ng-template>
        </ngb-panel>
				<ngb-panel *ngIf="!bulkUpload" id="custom-fields" [disabled]="uploadSelection">
					<ng-template ngbPanelTitle [class.validSection]="checkSectionValidity('prospectInfo')">
						<span>Prospect Info</span>
						<i class="fa fa-chevron-down pull-right"></i>
						<div *ngIf="checkSectionValidity('prospectInfo')" class="pull-right valid-icon">
							<i class="far fa-check"></i>
						</div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="row form-group" *ngFor="let field of dynamicModal.fields">
              <div class="col-sm-12">
                <label [for]="field.key">{{field.label}} <span *ngIf="!field.required">- <em>optional</em></span></label>
                <input [placeholder]="field.placeholder" [mask]="field.mask" [type]="field.type" [formControlName]="field.key" class="form-control" [ngClass]="{ 'is-invalid': submitted && newPageForm.controls[field.key].errors }" />
                <div *ngIf="submitted && newPageForm.controls[field.key].errors" class="invalid-feedback">
                  <div *ngIf="newPageForm.controls[field.key].errors.required">{{field.errorMessage}}</div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <div class="form-actions">
        <button *ngIf="!bulkUpload" [disabled]="disableAll || createPageLoading || !newPageForm.valid" type="submit" class="button button-dark margin-right-12">
          <span *ngIf="createPageLoading" class="spinner-border spinner-border-sm mr-1"></span>
          <span *ngIf="!isEmailTemplate">Create Page</span>
          <span *ngIf="isEmailTemplate">Create Email</span>
        </button>
        <!-- <button *ngIf="!bulkUpload" [disabled]="disableAll" (click)="exitCreateNew()" class="button button-light margin-right-12">
          Save Defaults
        </button> -->
				<button type="button" class="button button-white margin-right-12" (click)="exitCreateNew()">Cancel</button>
        <button *ngIf="bulkUpload" type="button" class="button button-red" (click)="refresh()">Start Over</button>
        <p class="contact-create-warning">By sending a page contacts will be created or updated. Contacts created this way have their "opt-in" property set to TRUE. Please make sure this is your intention.</p>
        <div *ngIf="createPageError && createPageError.length > 0" class="alert alert-danger mt-3 mb-0">{{createPageError}}</div>
      </div>
      </form>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-body page-creating-warning-modal">
      <p>Some of your pages were created, but some were not!</p>
      <div class="alert alert-danger">
        {{pageCreateError}}
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="button button-dark" (click)="modal.close()">OK</button>
    </div>
  </ng-template>
</div>

<!-- Send Email Modal -->
<ng-template #sendEmailModal let-modal>
  <div class="modal-header">
      <h5 class="modal-title" id="sendTextModalLabel">Send Email</h5>
      <div class="float-right">
          <button type="button" class="button button-white button-small" (click)="exitModal($event, modal)"><i class="far fa-times" aria-hidden="true"></i></button>
      </div>
  </div>
  <div class="send-email-modal modal-body">
      <div class="row">
          <div class="col-md-7">
              <div class="card">
                  <form [formGroup]="sendEmailForm" (ngSubmit)="deliverEmail(modal, bulkEmail)">
                      <div class="form-group" *ngIf="!bulkEmail">
                          <label for="recipientEmailAddress">Recipients Email Address</label>
                          <input type="email" readonly formControlName="recipientEmailAddress" class="form-control" [ngClass]="{ 'is-invalid': submitted && sendTextMessageForm.controls.recipientEmailAddress.errors }" />
                          <div *ngIf="submitted && sendEmailForm.controls.recipientEmailAddress.errors" class="invalid-feedback">
                              <div *ngIf="sendEmailForm.controls.recipientEmailAddress.errors.required">Recipient Email Address is required</div>
                          </div>
                      </div>
                      <div class="alert alert-danger mt-0" *ngIf="bulkEmail && pagesRemoved">
                        Some pages were removed for missing emails.
                      </div>
                      <div class="alert alert-danger mt-0" *ngIf="bulkEmail">
                        You are sending this email to {{bulkEmailsToSend.length}} addresses! Please make sure this is your intention!
                      </div>
                      <div class="form-group">
                        <label for="emailSubjectLine">Email Subject Line</label>
                        <input 
                            formControlName="emailSubjectLine"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && sendTextMessageForm.controls.emailSubjectLine.errors }"
                            maxlength="60"
                        />
                        <span class="helper-text">{{remainingCharacterCount()}} characters remaining</span>
                        <div *ngIf="submitted && sendTextMessageForm.controls.emailSubjectLine.errors" class="invalid-feedback">
                            <div *ngIf="sendTextMessageForm.controls.emailSubjectLine.errors.required">Email Subject Line is required</div>
                        </div>
                      </div>
                      <strong>Available Tokens:</strong>
                      <div class="token-wrap">
                          <span *ngFor="let key of objectKeys(tokenSamples)" class="single-token">[{{key}}], </span>
                      </div>
                      <button [disabled]="sendingEmail" class="margin-right-12 button button-dark">
                          <span *ngIf="sendingEmail" class="spinner-border spinner-border-sm mr-1"></span>
                          Send Now
                      </button>
                      <button [disabled]="sendingEmail" class="margin-right-12 button button-light" (click)="openScheduleModal(scheduleEmailModal, $event, modal)">
                          <span *ngIf="sendingEmail" class="spinner-border spinner-border-sm mr-1"></span>
                          Schedule Send
                      </button>
                      <button [disabled]="sendingEmail" type="button" class="button button-white" (click)="modal.dismiss('Cancel')">
                          <span *ngIf="sendingEmail" class="spinner-border spinner-border-sm mr-1"></span>
                          Cancel
                      </button>
                      <span class="consent-alert">*By clicking send you are verifying you have permission to email the individuals uploaded.</span>
                      <div *ngIf="errorMessage" class="alert alert-danger mt-3 mb-0">{{errorMessage}}</div>
                  </form>
              </div>
          </div>
          <div class="col-md-5 preview-holder">
              <div class="relative">
                  <guestcardemailpreview *ngIf="pageType === 'Guest Card'" [emailData]="sampleEmailData"></guestcardemailpreview>
                  <concessionemailpreview *ngIf="pageType === 'Apartment Concession'" [emailData]="sampleEmailData"></concessionemailpreview>
                  <interestlistemailpreview *ngIf="pageType === 'Apartment Interest List'" [emailData]="sampleEmailData"></interestlistemailpreview>
              </div>
          </div>
      </div>
  </div>
</ng-template>

<!-- Schedule Email Modal -->
<ng-template #scheduleEmailModal let-scheduleEmailModal>
  <div class="modal-header">
      <h5 class="modal-title">Schedule Email</h5>
      <div class="float-right">
          <button type="button" class="button button-white button-small" (click)="exitModal($event, scheduleEmailModal)"><i class="far fa-times" aria-hidden="true"></i></button>
      </div>
  </div>
  <div class="schedule-email-modal modal-body">
      <p>Select the date and time to send the email.</p>
      <div class="row">
          <div class="col-sm-6">
              <form class="single-date-selection form-inline calendar-padding">
                  <div class="form-group">
                    <div class="input-group">
                      <input class="form-control"
                          placeholder="yyyy-mm-dd"
                          name="dp"
                          [dayTemplate]="t"
                          [(ngModel)]="sendDate"
                          ngbDatepicker
                          #d="ngbDatepicker">
                      <ng-template #t let-date let-focused="focused">
                          <span class="custom-day"
                                  [class.focused]="focused"
                                  [class.range]="isToday(date)"
                                  [class.faded]="isHovered(date) || isInside(date)"
                                  (mouseenter)="hoveredDate = date"
                                  (mouseleave)="hoveredDate = null">
                              {{ date.day }}
                          </span>
                      </ng-template>
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i class="fal fa-calendar-alt"></i></button>
                      </div>
                    </div>
                  </div>
              </form>
          </div>
          <div class="col-sm-6">
              <ngb-timepicker [(ngModel)]="sendTime" [meridian]="true"></ngb-timepicker>
          </div>
      </div>
      <div class="alert alert-success mt-0" *ngIf="sendDate">
          <span>
              Your email<!--
              --><span *ngIf="true">s</span> 
              will send on {{sendDate.month}}/{{sendDate.day}}/{{sendDate.year}} 
              <span *ngIf="sendTime && sendTime.hour%12 === 0">at 12:{{("0" + sendTime.minute).slice(-2)}}</span>
              <span *ngIf="sendTime && sendTime.hour%12 !== 0">at {{sendTime.hour%12}}:{{("0" + sendTime.minute).slice(-2)}}</span>
              <span *ngIf="sendTime && (sendTime.hour === 0 || (sendTime.hour && sendTime.hour <= 11))"> AM</span>
              <span *ngIf="sendTime && sendTime.hour && sendTime.hour > 11"> PM</span>
          </span>
      </div>
      <button [disabled]="!sendTime || !sendDate || schedSaveInProgress" (click)="saveSchedule(scheduleEmailModal)" class="button button-dark">
          <span *ngIf="schedSaveInProgress" class="spinner-border spinner-border-sm mr-1"></span>
          Save Schedule
      </button>
  </div>
</ng-template>