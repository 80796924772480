import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class ContactsService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  getContacts(type) {
    return this.http.get<any>(this.apiHelper.fillUrl('contacts', {}, {type: type}))
      .pipe(map(data => {
        return data;
      }));
  }

  getContactsWorker(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('contactsWorker', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getContact(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('contact', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getContactsPages(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('contactsPages', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  getContactsTexts(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('contactsTexts', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  optOutContact(id, payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('optout', {id: id}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }

  checkOptInStatus(id) {
    return this.http.get<any>(this.apiHelper.fillUrl('optout', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }

  addLabelToContacts(payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('contactLabels', {}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }

  createContacts(payload) {
    return this.http.post<any>(this.apiHelper.fillUrl('contacts', {}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }

  updateContact(id, payload) {
    return this.http.put<any>(this.apiHelper.fillUrl('contact', {id: id}, {}), payload)
      .pipe(map(data => {
        return data;
      }));
  }

  deleteContact(id) {
    return this.http.delete<any>(this.apiHelper.fillUrl('contact', {id: id}, {}))
      .pipe(map(data => {
        return data;
      }));
  }
}
