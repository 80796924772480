import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class UsersService {

	constructor(
		private http: HttpClient,
		private apiHelper: APIHelperService,
		private authService: AuthenticationService
	) {
		// no-op
	}

	getUserData(id) {
		return this.http.get<any>(this.apiHelper.fillUrl('user', {id: id}, {}))
			.pipe(map(data => {
				return data;
			}));
	}

	updateUser(id, data, admin?) {
			return this.http.put<any>(this.apiHelper.fillUrl('user', {id: id}, {}), data)
					.pipe(map(data => {
							if (!admin) {
								var storage = JSON.parse(localStorage.getItem('currentUser'));
								storage.user = data.result;
								if (data.result.token) {
									storage.token = data.result.token;
									delete data.result.token;
								}
								localStorage.setItem('currentUser', JSON.stringify(storage));
								this.authService.currentUserSubject.next(storage);
							}
							return data;
					}));
	}

	createNewAccount(data) {
			return this.http.post<any>(this.apiHelper.fillUrl('users', {}, {}), data)
					.pipe(map(data => {
							return data;
					}));
	}

	setGlobalDefaults(data) {
			return this.http.post<any>(this.apiHelper.fillUrl('defaults', {}, {}), data)
					.pipe(map(data => {
							return data;
					}));
	}

	updateUsage(data) {
		return this.http.post<any>(this.apiHelper.fillUrl('usages', {}, {}), data)
				.pipe(map(data => {
						return data;
				}));
	}
}
